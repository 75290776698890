import RolesEnum from "common/enums/RolesEnum";
import {
  AllNursesIcon,
  AllProvidersIcon,
  CalendarIcon,
  PlusIcon,
  RegisterMemberIcon,
  RequestConsentsIcon
} from "../../assets/images/icons";
import {
  ALL_MEMBERS,
  ALL_RECENT_ORDERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  NPN_LIST,
  NPN_DETAILS,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  STAFF_MEMBERS,
  REPORT_LIST,
  REPORT_DETAILS,
  CANCELED_MEMBERS,
  RETENTION,
  REPORT_DETAILS_BY_MEMBER,
  ALL_TEAMS,
  TEAM_DETAILS,
  CREATE_TEAM,
  REGISTER_MEMBER,
  REQUEST_CONSENTS,
  MEMBER_CHART,
  PROVIDER_LIST,
  MEMBER_CHART_CALENDARING,
  STAFF_DETAILS,
  NURSE_SCHEDULE,
  MY_TODOS,
  COMPLETED_TODOS,
  VISITS,
  NURSES_SCHEDULES,
  ARC_OF_DAY_DASHBOARD,
  ARC_OF_DAY_DETAILS,
  PROVIDER_SCHEDULES,
  COMMON_ROUTES,
  START_INTAKE,
  PROVIDER_ENROLLMENT
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_CLINICAL_TEAMS,
  SIDEBAR_INACTIVE_CANCELED_MEMBERS,
  SIDEBAR_RETENTION,
  SIDEBAR_CREATE_TEAM,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_NPNS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SUPPORT,
  SIDEBAR_SECTIONS,
  PROVIDER_SCHEDULES_SIDEBAR
} from "../RouteSidebar";
import StaffDetailsRedirect from "../../pages/AllUserList/StaffDetails/StaffDetailsRedirect";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";
import CreateUserForm from "../../pages/CreateUserForm/CreateUserForm";
import Routes from "../Routes";
import FeatureFlags from "common/config/FeatureFlags";

const providerSidebar = () => {
  const sidebarItems = [
    {
      itemName: "All Providers",
      itemLink: Routes.PROVIDERS,
      ItemIcon: AllProvidersIcon
    }
  ];

  sidebarItems.push(PROVIDER_SCHEDULES_SIDEBAR);

  return sidebarItems;
};

const ADMIN = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: Routes.ROOT,
      components: STAFF_MEMBERS
    },
    {
      path: Routes.CREATE_USER,
      components: <CreateUserForm />
    },
    CREATE_TEAM,
    ALL_TEAMS({}),
    TEAM_DETAILS,
    STAFF_DETAILS,
    {
      path: Routes.NURSES,
      components: NURSE_LIST({})
    },
    {
      path: Routes.NURSE_REDIRECT,
      components: <StaffDetailsRedirect />
    },
    NURSE_SCHEDULE,
    ...NURSES_SCHEDULES,

    {
      path: Routes.ALL_MEMBERS,
      components: ALL_MEMBERS({})
    },
    {
      path: Routes.AWAITING_PROVIDER,
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" }
        ]
      })
    },
    { path: Routes.INACTIVE_MEMBERS, components: INACTIVE_MEMBERS(true) },

    ...MEMBER_CHART(RolesEnum.ADMIN),
    ...MEMBER_CHART_CALENDARING,
    {
      path: Routes.ASSIGNED_MEMBERS(":nurseId"),
      components: [MEMBERS_WITH_NO_ACTIVITY({}), ASSIGNED_MEMBERS({})]
    },
    {
      path: Routes.ORDERS,
      components: [<OrderDevices key="OrderDevices" />, ALL_RECENT_ORDERS()]
    },
    {
      path: Routes.DEVICES_BY_MEMBER,
      components: <OrderDevices />
    },
    START_INTAKE,
    ORDER_DETAILS(),
    { path: Routes.REGISTER_MEMBER, components: REGISTER_MEMBER },
    { path: Routes.REQUEST_CONSENTS, components: REQUEST_CONSENTS },

    {
      path: Routes.PROVIDERS,
      components: PROVIDER_LIST({
        filterByAuthenticatedUser: false
      })
    },
    {
      path: Routes.PROVIDER_SCHEDULES,
      components: PROVIDER_SCHEDULES({})
    },
    NPN_LIST({}),
    NPN_DETAILS,
    RETENTION({}),
    REPORT_LIST,
    REPORT_DETAILS,
    CANCELED_MEMBERS,
    REPORT_DETAILS_BY_MEMBER,
    CREATE_TEAM,
    MY_TODOS,
    COMPLETED_TODOS,
    VISITS,
    ARC_OF_DAY_DASHBOARD,
    ARC_OF_DAY_DETAILS,
    {
      path: Routes.STAFF_MEMBERS,
      components: STAFF_MEMBERS
    },
    PROVIDER_ENROLLMENT
  ],
  sidebar: [
    SIDEBAR_DASHBOARD(RolesEnum.ADMIN),
    {
      sectionName: SIDEBAR_SECTIONS.users,
      items: [
        {
          itemName: "Create User",
          itemLink: Routes.CREATE_USER,
          ItemIcon: PlusIcon
        },
        SIDEBAR_CREATE_TEAM,
        {
          itemName: "Register Member",
          itemLink: Routes.REGISTER_MEMBER,
          ItemIcon: RegisterMemberIcon
        },
        {
          itemName: "Request Consents",
          itemLink: Routes.REQUEST_CONSENTS,
          ItemIcon: RequestConsentsIcon
        },
        SIDEBAR_CLINICAL_TEAMS,
        {
          itemName: "Staff Members",
          itemLink: Routes.STAFF_MEMBERS,
          ItemIcon: AllNursesIcon
        }
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "All Nurses",
          itemLink: Routes.NURSES,
          ItemIcon: AllNursesIcon
        },
        {
          itemName: "Nurses Schedules",
          itemLink: Routes.NURSE_SCHEDULE_DETAILS("schedules"),
          ItemIcon: CalendarIcon
        },
        SIDEBAR_NPNS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.providers,
      items: providerSidebar()
    },
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_INACTIVE_MEMBERS, SIDEBAR_ORDERS]
    },
    {
      sectionName: SIDEBAR_SECTIONS.retention,
      items: [SIDEBAR_RETENTION, SIDEBAR_INACTIVE_CANCELED_MEMBERS]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default ADMIN;
