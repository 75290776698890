import EnvVars from "./EnvVars";
import { RootState } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import { store } from "../redux";
import RolesEnum, { canSeeCareFlow } from "../enums/RolesEnum";

export interface FeatureFlagsType {
  ARC_OF_DAY: boolean;
  CARE_FLOWS_REQUIRED_FIELD_CHECK: boolean;
  STRIPS_ON_HAND: boolean;
  MEMBER_HEADER_NEW: boolean;
  USER_PRESENCE_MANAGEMENT: boolean;
}

const isDev = () => {
  return (
    EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "prod" &&
    EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "staging"
  );
};

const FeatureFlags = (user_id_param?: string) => {
  const currentRole = store.getState().auth?.currentRole;
  return {
    ARC_OF_DAY: isDev(),
    MEMBER_HEADER_NEW: canSeeCareFlow(currentRole), // Just people who see care flows for now. We will bring INs and Providers to arcs soon
    CARE_FLOWS_REQUIRED_FIELD_CHECK: false, // Turning off until later based on Product guidance
    STRIPS_ON_HAND: isDev() ? true : false,
    USER_PRESENCE_MANAGEMENT: isDev(),
    VOIP: isDev() && currentRole == RolesEnum.ADMIN
  };
};

export const useFeatureFlags = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  return FeatureFlags(user?.user_id);
};

export default FeatureFlags;
