import {
  Box,
  Button,
  Checkbox,
  ListItemText,
  MenuItem,
  styled,
  TextField,
  Typography
} from "@mui/material";
import {
  ComponentHeader,
  TableComponentContainer
} from "../../styling/StyleComponents";
import ProviderEnrollmentTable from "./ProviderEnrollmentTable";
import { useGetStaffEnrollmentsQuery } from "common/services/StaffLicensingService";
import ErrorComponent from "../../components/ErrorComponent";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { Cached, FilterAltOutlined } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import isEqual from "lodash.isequal";
import uniqWith from "lodash.uniqwith";
import { blue } from "common/styling/colors";
import CreateProviderEnrollmentModal from "./CreateProviderEnrollmentModal";
import DeleteProviderEnrollmentModal from "./DeleteProviderEnrollmentModal";
import EditProviderEnrollmentModal from "./EditProviderEnrollmentModal";

const Row = styled(Box)`
  display: flex;
  align-items: center;
`;

const ALL_ITEM = { label: "All", value: "All" };

const ProviderEnrollment = () => {
  const { data, isFetching, error } = useGetStaffEnrollmentsQuery({});

  const [selectedProviders, setSelectedProviders] = useState<string[]>(["All"]);
  const [providersList, setProvidersList] = useState([]);
  const [selectedHealthPlans, setSelectedHealthPlans] = useState<string[]>([
    "All"
  ]);
  const [healthPlanList, setHealthPlanList] = useState([]);
  const [selectedStates, setSelectedStates] = useState<string[]>(["All"]);
  const [statesList, setStatesList] = useState([]);

  // This is used to store the selected insurance id for edit/delete
  const [selectedInsuranceId, setSelectedInsuranceId] = useState<string>("");

  const selectedInsuranceInfo = useMemo(() => {
    if (!selectedInsuranceId) return null;
    return data?.find((item) => item.id === selectedInsuranceId);
  }, [selectedInsuranceId]);

  const [
    isCreateProviderEnrollmentModalOpen,
    setIsCreateProviderEnrollmentModalOpen
  ] = useState<boolean>(false);

  const [
    isEditProviderEnrollmentModalOpen,
    setIsEditProviderEnrollmentModalOpen
  ] = useState<boolean>(false);

  const [
    isDeleteProviderEnrollmentModalOpen,
    setIsDeleteProviderEnrollmentModalOpen
  ] = useState<boolean>(false);

  const toggleEditProviderModal = useCallback(
    (selectedId) => {
      if (isEditProviderEnrollmentModalOpen) {
        setSelectedInsuranceId("");
        setIsEditProviderEnrollmentModalOpen(false);
      } else {
        setSelectedInsuranceId(selectedId);
        setIsEditProviderEnrollmentModalOpen(true);
      }
    },
    [isEditProviderEnrollmentModalOpen]
  );

  const toggleDeleteProviderModal = useCallback(
    (selectedId) => {
      if (isDeleteProviderEnrollmentModalOpen) {
        setSelectedInsuranceId("");
        setIsDeleteProviderEnrollmentModalOpen(false);
      } else {
        setSelectedInsuranceId(selectedId);
        setIsDeleteProviderEnrollmentModalOpen(true);
      }
    },
    [isDeleteProviderEnrollmentModalOpen]
  );

  useEffect(() => {
    const providers = data?.map((item) => {
      return { label: item?.staff?.name, value: item?.staff?.id };
    });
    const dedupedProviders = uniqWith(providers, isEqual);
    setProvidersList([ALL_ITEM, ...dedupedProviders]);

    const healthPlans = data?.map((item) => {
      return {
        label: item?.insurance?.name,
        value: item?.insurance?.insurance_licensing_id
      };
    });

    const dedupedHealthPlans = uniqWith(healthPlans, isEqual)?.sort((a, b) =>
      a?.label.localeCompare(b?.label)
    );
    setHealthPlanList([ALL_ITEM, ...dedupedHealthPlans]);

    const states = data
      ?.map((item) => {
        return { label: item?.state, value: item?.state };
      })
      ?.sort((a, b) => a?.label?.localeCompare(b?.label));

    const dedupedStates = uniqWith(states, isEqual);
    setStatesList([ALL_ITEM, ...dedupedStates]);
  }, [data]);

  const handleChange = useCallback(
    (event, callback) => {
      const value = event.target.value;
      const lastClickedSelectAll =
        value.includes("SelectAll") &&
        // "SelectAll" is the most recently selected item
        value.indexOf("SelectAll") === value?.length - 1;

      const lastClickedClearAll =
        value.includes("ClearAll") &&
        // "ClearAll" is the most recently selected item
        value.indexOf("ClearAll") === value?.length - 1;
      if (lastClickedSelectAll) {
        callback(["All"]);
      } else if (
        lastClickedClearAll ||
        // this case can happen when last clicking "Clear All"
        (value?.length === 1 && value[0] === "All")
      ) {
        callback([]);

        // if we want clear all to reset to "all" use this instead
        // callback(["All", "ClearAll"]);
      } else {
        callback(
          typeof value === "string"
            ? value
                ?.split(",")
                ?.filter((item) => item !== "All" && item !== "ClearAll")
            : value?.filter((item) => item !== "All" && item !== "ClearAll")
        );
      }
    },
    [setSelectedProviders, setSelectedHealthPlans, setSelectedStates]
  );

  const renderValue = useCallback(
    (selected, dataList) => {
      const selectedLabels = (selected as string[])
        // we want the "ClearAll" item to not show in the rendered value because
        // it will add a comma at the end of the string
        .filter((item) => item !== "ClearAll")
        .map((value) => dataList.find((item) => item.value === value)?.label);
      return selectedLabels?.join(", ");
    },
    [selectedProviders, selectedHealthPlans, selectedStates]
  );

  const filteredData = useMemo(() => {
    const finalData = data?.filter((item) => {
      const isProviderMatch =
        selectedProviders.includes("All") ||
        selectedProviders.includes(item?.staff?.id);
      const isHealthPlanMatch =
        selectedHealthPlans.includes("All") ||
        selectedHealthPlans.includes(item?.insurance?.insurance_licensing_id);
      const isStateMatch =
        selectedStates.includes("All") || selectedStates.includes(item?.state);
      return isProviderMatch && isHealthPlanMatch && isStateMatch;
    });
    return finalData;
  }, [data, selectedProviders, selectedHealthPlans, selectedStates]);

  return (
    <TableComponentContainer>
      <ComponentHeader sx={{ marginBottom: "24px" }}>
        Provider Enrollment
      </ComponentHeader>
      <Button
        variant="outlined"
        key="create-new-provider-enrollment-record"
        sx={{
          mb: "24px",
          height: "50px",
          backgroundColor: blue[700],
          color: "white",
          "&:hover": {
            backgroundColor: blue[800],
            color: "white"
          }
        }}
        onClick={() => {
          setIsCreateProviderEnrollmentModalOpen(true);
        }}
      >
        Create New Record
      </Button>
      <Row gap={"24px"} flexWrap={"nowrap"}>
        <Row gap="8px">
          <FilterAltOutlined color="primary" />
          <Typography variant="h6" color="primary" width="max-content">
            Filter by
          </Typography>
        </Row>
        <TextField
          select
          label={"Provider"}
          sx={{ width: 200 }}
          id={"ProviderFilter"}
          defaultValue={"All"}
          slotProps={{
            select: {
              variant: "outlined",
              multiple: true,
              value: selectedProviders,
              renderValue: (selected) => renderValue(selected, providersList),
              onChange: (e) => handleChange(e, setSelectedProviders)
            }
          }}
        >
          <MenuItem key={"selectAll"} value={"SelectAll"}>
            <Typography>Select All</Typography>
          </MenuItem>
          <MenuItem key={"clearSelection"} value={"ClearAll"}>
            <Typography>Clear Selection</Typography>
          </MenuItem>
          {providersList.map((provider) => {
            if (provider?.value === "All") {
              return null;
            }
            return (
              <MenuItem key={provider?.value} value={provider?.value}>
                <Checkbox
                  checked={
                    selectedProviders.includes(provider?.value) ||
                    (selectedProviders.includes("All") &&
                      // if the user last selected "ClearAll", we want to not check the boxes
                      !selectedProviders.includes("ClearAll"))
                  }
                />
                <ListItemText primary={provider?.label} />
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          select
          label={"Health Plan"}
          sx={{ width: 200 }}
          id={"HealthPlanFilter"}
          defaultValue={"All"}
          slotProps={{
            select: {
              variant: "outlined",
              multiple: true,
              value: selectedHealthPlans,
              renderValue: (selected) => renderValue(selected, healthPlanList),
              onChange: (e) => handleChange(e, setSelectedHealthPlans)
            }
          }}
        >
          <MenuItem key={"selectAll"} value={"SelectAll"}>
            <Typography>Select All</Typography>
          </MenuItem>
          <MenuItem key={"clearSelection"} value={"ClearAll"}>
            <Typography>Clear Selection</Typography>
          </MenuItem>
          {healthPlanList.map((plan) => {
            if (plan?.value === "All") {
              return null;
            }
            return (
              <MenuItem key={plan?.value} value={plan?.value}>
                <Checkbox
                  checked={
                    selectedHealthPlans.includes(plan?.value) ||
                    (selectedHealthPlans.includes("All") &&
                      // if the user last selected "ClearAll", we want to not check the boxes
                      !selectedHealthPlans.includes("ClearAll"))
                  }
                />
                <ListItemText primary={plan?.label} />
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          select
          label={"State"}
          sx={{ width: 200 }}
          id={"StateFilter"}
          defaultValue={"All"}
          slotProps={{
            select: {
              variant: "outlined",
              multiple: true,
              value: selectedStates,
              renderValue: (selected) => renderValue(selected, statesList),
              onChange: (e) => handleChange(e, setSelectedStates)
            }
          }}
        >
          <MenuItem key={"selectAll"} value={"SelectAll"}>
            <Typography>Select All</Typography>
          </MenuItem>
          <MenuItem key={"clearSelection"} value={"ClearAll"}>
            <Typography>Clear Selection</Typography>
          </MenuItem>
          {statesList.map((state) => {
            if (state?.value === "All") {
              return null;
            }
            return (
              <MenuItem key={state?.value} value={state?.value}>
                <Checkbox
                  checked={
                    selectedStates.includes(state?.value) ||
                    (selectedStates.includes("All") &&
                      // if the user last selected "ClearAll", we want to not check the boxes
                      !selectedStates.includes("ClearAll"))
                  }
                />
                <ListItemText primary={state?.label} />
              </MenuItem>
            );
          })}
        </TextField>
        <Box
          display="flex"
          gap={"12px"}
          sx={{ cursor: "pointer" }}
          alignItems={"center"}
          onClick={() => {
            setSelectedProviders(["All"]);
            setSelectedHealthPlans(["All"]);
            setSelectedStates(["All"]);
          }}
        >
          <Cached color="primary" />
          <Typography color="primary" fontWeight={600}>
            Reset
          </Typography>
        </Box>
      </Row>
      {filteredData?.length > 0 && (
        <Box sx={{ width: "100%" }}>
          <ProviderEnrollmentTable
            providerEnrollmentData={filteredData}
            toggleEditProviderModal={toggleEditProviderModal}
            toggleDeleteProviderModal={toggleDeleteProviderModal}
          />
        </Box>
      )}
      {filteredData?.length === 0 && (
        <Box sx={{ width: "100%", mt: "24px" }}>
          <Typography>
            No record matches. Please try adjusting filters.
          </Typography>
        </Box>
      )}
      {error && <ErrorComponent error={error} />}
      {isFetching && <LoadingFallback delay={500} count={10} />}
      <CreateProviderEnrollmentModal
        isVisible={isCreateProviderEnrollmentModalOpen}
        onRequestClose={() => setIsCreateProviderEnrollmentModalOpen(false)}
      />
      <EditProviderEnrollmentModal
        isVisible={isEditProviderEnrollmentModalOpen}
        onRequestClose={() => toggleEditProviderModal(null)}
        selectedInsuranceId={selectedInsuranceId}
        selectedInsuranceInfo={selectedInsuranceInfo}
      />
      <DeleteProviderEnrollmentModal
        isVisible={isDeleteProviderEnrollmentModalOpen}
        onRequestClose={() => toggleDeleteProviderModal(null)}
        selectedInsuranceId={selectedInsuranceId}
      />
    </TableComponentContainer>
  );
};

export default ProviderEnrollment;
