import { LoadingButton } from "@mui/lab";
import { Card, Checkbox, Typography, styled } from "@mui/material";
import { StyledTextField } from "../../../helpers/components/Forms/FormHelpers";
import { useFormik } from "formik";

import {
  useGetMemberWithUsernameQuery,
  useUpdateMemberExternalMutation,
  useUpdateMemberMetadataMutation
} from "common/services/MemberService";
import ErrorComponent from "../../../components/ErrorComponent";
import { Column } from "../../../styling/StyleComponents";
import Skeleton from "react-loading-skeleton";
import { Flexbox } from "../../../styling/NewStyleComponents";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";

const Row = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 10px;
`;

const ValuesContainer = styled("div")`
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px;
`;

const defaultFormStyles = { flex: "1" };

interface IProps {
  memberId: string;
}

const TechSupportTab = ({ memberId }: IProps) => {
  const {
    data: member,
    isLoading: isMemberLoading,
    error: memberError
  } = useGetMemberWithUsernameQuery({
    username: memberId,
    linked_entities: [MemberLinkedEntitiesEnum.METADATA]
  });

  const [
    updateMemberMutation,
    {
      error: memberMutationError,
      isSuccess: isSuccessMemberMutation,
      isLoading: memberMutationLoading
    }
  ] = useUpdateMemberExternalMutation();

  const [
    updateMemberMetadataMutation,
    {
      error: memberMetadataMutationError,
      isSuccess: isSuccessMemberMetadataMutation,
      isLoading: memberMetadataMutationLoading
    }
  ] = useUpdateMemberMetadataMutation();

  const validate = () => {
    const errors = {};

    return errors;
  };

  const { setFieldValue, values, dirty, errors, handleSubmit } = useFormik<{
    athena: string;
    athena_global_id: string;
    zoom_number: string;
    salesforce_lead_id: string;
    salesforce_account_id: string;
    biotel: string;
    is_enrolled_diabetes: boolean;
    is_enrolled_hypertension: boolean;
  }>({
    validate,
    initialValues: {
      athena: member?.patient?.external_accounts.athena ?? "",
      athena_global_id:
        member?.patient?.external_accounts.athena_global_id ?? "",
      zoom_number: member?.patient?.mobile ?? "",
      salesforce_lead_id: member?.patient?.external_accounts.salesforce ?? "",
      salesforce_account_id:
        member?.patient?.external_accounts.salesforce_acc_id ?? "",
      biotel: member?.patient?.external_accounts.biotel ?? "",
      is_enrolled_diabetes:
        member?.metadata?.philips_dm_programs?.is_enrolled_diabetes ?? false,
      is_enrolled_hypertension:
        member?.metadata?.philips_dm_programs?.is_enrolled_hypertension ?? false
    },
    onSubmit: async (values) => {
      updateMemberMutation({
        patient_id: member?.patient.patient_id,
        external: {
          athena: values.athena,
          athena_global_id: values.athena_global_id,
          salesforce: values.salesforce_lead_id,
          salesforce_acc_id: values.salesforce_account_id,
          biotel: values.biotel
        }
      });

      updateMemberMetadataMutation({
        memberId: member?.patient.patient_id,
        data: {
          philips_dm_programs: {
            is_enrolled_diabetes: values.is_enrolled_diabetes,
            is_enrolled_hypertension: values.is_enrolled_hypertension
          }
        }
      });
    },
    enableReinitialize: true
  });

  return (
    <>
      {isMemberLoading && <Skeleton count={20} />}
      {member && (
        <>
          <Row>
            <Typography flex={1} variant={"h3"} color="black">
              Internal
            </Typography>
          </Row>

          <Card>
            <ValuesContainer>
              <StyledTextField
                fieldName={"email"}
                label={"Email"}
                value={member.patient.username}
                disabled={true}
                placeholder={"Enter email here"}
                fullWidth={true}
                setFieldValue={setFieldValue}
                disableHelperText
              />
            </ValuesContainer>
          </Card>

          <Row>
            <Typography flex={1} variant={"h3"} color="black">
              Athena Linkage
            </Typography>
          </Row>

          <Card>
            <ValuesContainer>
              <StyledTextField
                sx={defaultFormStyles}
                value={values?.athena || ""}
                onChange={(e) => {
                  setFieldValue("athena", e.target.value);
                }}
                label="Athena"
                placeholder={"Athena"}
                error={errors["athena"] !== undefined}
                helperText={errors["athena"]}
              />
              <StyledTextField
                sx={defaultFormStyles}
                value={values?.athena_global_id || ""}
                onChange={(e) => {
                  setFieldValue("athena_global_id", e.target.value);
                }}
                label="Athena Global ID"
                placeholder={"Athena Global ID"}
                error={errors["athena_global_id"] !== undefined}
                helperText={errors["athena_global_id"]}
              />
            </ValuesContainer>
          </Card>

          {/*<Row>
            <Typography flex={1} variant={"h3"} color="black">
              Telephony
            </Typography>
          </Row>

          <Card>
            <ValuesContainer>
              <PhoneInputField
                sx={defaultFormStyles}
                value={values?.zoom_number || ""}
                onChange={(e) => {
                  setFieldValue("zoom_number", e.target.value);
                }}
                label="Zoom Number"
                placeholder={"Zoom Number"}
                error={errors["zoom_number"] !== undefined}
                helperText={errors["zoom_number"]}
              />
            </ValuesContainer>
              </Card>*/}
          <Row>
            <Typography flex={1} variant={"h3"} color="black">
              Salesforce
            </Typography>
          </Row>

          <Card>
            <ValuesContainer>
              <StyledTextField
                sx={defaultFormStyles}
                value={values?.salesforce_lead_id || ""}
                onChange={(e) => {
                  setFieldValue("salesforce_lead_id", e.target.value);
                }}
                label="Salesforce Lead ID"
                placeholder={"Salesforce Lead ID"}
                error={errors["salesforce_lead_id"] !== undefined}
                helperText={errors["salesforce_lead_id"]}
              />

              <StyledTextField
                sx={defaultFormStyles}
                value={values?.salesforce_account_id || ""}
                onChange={(e) => {
                  setFieldValue("salesforce_account_id", e.target.value);
                }}
                label="Salesforce Account ID"
                placeholder={"Salesforce Account ID"}
                error={errors["salesforce_account_id"] !== undefined}
                helperText={errors["salesforce_account_id"]}
              />
            </ValuesContainer>
          </Card>

          <Row>
            <Typography flex={1} variant={"h3"} color="black">
              Biotel
            </Typography>
          </Row>

          <Card>
            <ValuesContainer>
              <StyledTextField
                sx={defaultFormStyles}
                value={values?.biotel || ""}
                onChange={(e) => {
                  setFieldValue("biotel", e.target.value);
                }}
                label="Biotel"
                placeholder={"Biotel"}
                error={errors["biotel"] !== undefined}
                helperText={errors["biotel"]}
              />
            </ValuesContainer>
          </Card>

          <Row>
            <Typography flex={1} variant={"h3"} color="black">
              Philips DM Programs
            </Typography>
          </Row>

          <Card>
            <ValuesContainer>
              <Flexbox alignItems="center">
                <Checkbox
                  checked={values?.is_enrolled_diabetes}
                  onChange={(event) => {
                    setFieldValue("is_enrolled_diabetes", event.target.checked);
                  }}
                />
                <Typography variant="body1">Enrolled Diabetes</Typography>
              </Flexbox>

              <Flexbox alignItems="center">
                <Checkbox
                  checked={values?.is_enrolled_hypertension}
                  onChange={(event) => {
                    setFieldValue(
                      "is_enrolled_hypertension",
                      event.target.checked
                    );
                  }}
                />
                <Typography variant="body1">Enrolled Hypertension</Typography>
              </Flexbox>
            </ValuesContainer>
          </Card>

          <br />
          <Row>
            <Column flex={1}>
              {isSuccessMemberMutation && isSuccessMemberMetadataMutation && (
                <Typography variant="body1" color="green" margin={"10px"}>
                  Data info has been updated successfully
                </Typography>
              )}

              <ErrorComponent
                error={memberMutationError || memberMetadataMutationError}
              />
            </Column>

            <LoadingButton
              variant="contained"
              disabled={!dirty}
              loading={memberMutationLoading || memberMetadataMutationLoading}
              onClick={() => handleSubmit()}
            >
              Save
            </LoadingButton>
          </Row>
        </>
      )}

      <ErrorComponent error={memberError} />
    </>
  );
};

export default TechSupportTab;
