import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import MemberType from "common/types/MemberType";
import { blue, eligibilityColors, gray } from "common/styling/colors";
import { useFetchPatientInsurance } from "common/services/MemberInsuranceService";
import InsuranceInformationChipStatus from "../../../components/InsuranceInformationChipStatus";
import { DateTime } from "luxon";
import { isFalsy } from "common/helpers/helpers";

interface IProps {
  member: MemberType;
}

interface IField {
  value: string;
  order: number;
}

const keyToFieldRecord: Record<string, IField> = {
  athena_irc_name: {value: "Plan", order: 0},
  eligibility_status: {value: "Status", order: 1},
  policy_holder: {value: "Policy holder",  order: 2},
  insurance_patient_relation: {value: "Patient's relation",  order: 3},
  dob: {value: "DOB",  order: 4},
  group: {value: "Group#",  order: 5},
  cert_id: {value: "ID/Cert#", order: 6},
  issued_at: {value: "Issued", order: 7},
  inquiry_date: {value: "Inquiry Date",  order: 8},
  eligibility_status_message: {value: "Message", order: 9},
  plan_description: {value: "Plan Description", order: 10},
};

function sortRecords(records: [string, any][]): [string, any][] {
  return records
    .map(([key, value]) => ({
      key,
      value,
      order: keyToFieldRecord[key]?.order ?? Infinity, // Default to Infinity if not found
    }))
    .sort((a, b) => a.order - b.order)
    .map(({ key, value }) => [key, value]);
}

const formatToDate = (date: string) => DateTime.fromISO(date).toFormat("MM/dd/yyyy")

const tableTitle = (records: [string, any][]) => {
  const m = new Map<string, any>(records);
  const sequenceNumber = m.get("sequence_number");
  return `${sequenceNumber === 1 ? "Primary": "Secondary"} ${formatToDate(m.get("created_at"))}`;
}

const formatFields = (field: string, value: any) => {
  if (["created_at", "issued_at", "dob"].includes(field)) {
    return formatToDate(value);
  }
  return value;
}

const VisitFieldMemberInsuranceInfo = ({ member }: IProps) => {
  const patientId = member?.patient?.patient_id;

  const { 
    patientInsuranceMap,
    isFetchingPull,
    lastPulledData,
    pullInsurance,
  } = useFetchPatientInsurance(patientId)

  const tableRowColor = (index: number) => index % 2 === 0 ? gray[50] : "#ffffff";

  return (
    <Box sx={{
        border: 1, 
        borderColor: gray[300], 
        backgroundColor: "#ffffff", 
        padding: "18px", 
        borderRadius: "8px",
        marginTop: "18px"
    }}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box gap={2} sx={{ display: "flex", alignItems: "center" }} >
          <Button onClick={() => pullInsurance()} sx={{ background: blue[700], color: "white", paddingX: "16px" }}>Pull Insurance Information</Button>
          <InsuranceInformationChipStatus isFetchingPull={isFetchingPull} lastPulledData={lastPulledData} />
        </Box>
        {isFalsy(patientInsuranceMap) && <Box sx={{ fontWeight: "bold", color: gray[400] }}>No Patient insurance available.</Box>}
        {patientInsuranceMap && patientInsuranceMap.map((records, i) => (
            <TableContainer key={records[i][1]} component={Paper} elevation={0} sx={{ border: 1, borderColor: gray[300], borderRadius: "8px" }}>
              <Table>
                <TableHead sx={{ padding: "20px", borderBottom: `1px solid ${gray[300]}`}}>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ padding: "24px 16px", fontWeight: "bold", fontSize: "16px" }} >
                      {tableTitle(records)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortRecords(records).map(([key, value], i) => {
                    if(!keyToFieldRecord[key]) return null;
                    return (
                      <TableRow key={`${key}_${value}`} sx={{ backgroundColor: tableRowColor(i) }}>
                        <TableCell>
                          <Typography color={gray[900]}>{keyToFieldRecord[key]?.value}</Typography>
                        </TableCell>
                        <TableCell style={{ width: "80%"}}>
                          <Typography sx={{
                            fontWeight: "bold",
                            color: eligibilityColors[value] ?? gray[900]
                          }}>
                            {formatFields(key, value)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          ))}
      </Box>
    </Box>
  );
};

export default VisitFieldMemberInsuranceInfo;
