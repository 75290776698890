import { Box, LinearProgress, Typography } from "@mui/material";
import { useGetVisitNotesQuery } from "common/services/VisitsService";
import VisitType from "common/types/Visits/VisitType";
import { gray } from "common/styling/colors";
import { useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { Column } from "../../../styling/StyleComponents";
import { RootState, useAppDispatch } from "common/redux";
import { setGeneratedNotes } from "common/redux/VisitsSlice";
import { isFalsy } from "common/helpers/helpers";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import { isDisabled } from "../VisitHelper";

const GeneratedNotes = styled(Typography)`
  h1 {
    margin: 25px 0px;
  }
`;

interface IProps {
  visit: VisitType;
}

const DELAY_GENERATE_NOTES = 3000;

const VisitFieldGenerateNotes = ({ visit }: IProps) => {
  const hasGeneratedNotes =
    visit?.status === VisitStatusEnum.COMPLETED &&
    visit?.care_flow?.generated_notes !== undefined;

  // Adding a pause before loading the notes to resolve issues where sometimes the answers
  // are out of sync
  const [pause, setPause] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(10);

  const dispatch = useAppDispatch();
  const { data, isLoading, refetch } = useGetVisitNotesQuery(
    {
      visit_id: visit?.visit_id
    },
    { skip: hasGeneratedNotes || pause }
  );

  setTimeout(() => {
    setPause(false);
  }, DELAY_GENERATE_NOTES);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 15
      );
    }, 600);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const { dirty } = useSelector((state: RootState) => state.visits);

  const [dateTimeGenerated, setDateTimeGenerated] = useState<DateTime>();

  useEffect(() => {
    if (data) {
      setDateTimeGenerated(DateTime.now());
      dispatch(setGeneratedNotes(data.notes));
    }
  }, [data]);

  useEffect(() => {
    if (pause) return;
    if (hasGeneratedNotes) return;
    refetch();
  }, [dirty, hasGeneratedNotes, pause]);

  const style = useMemo(() => {
    if (hasGeneratedNotes)
      return {
        dangerouslySetInnerHTML: {
          __html: visit?.care_flow.generated_notes
        }
      };
    if (isFalsy(data)) return {};

    if (data.format === "html") {
      return {
        dangerouslySetInnerHTML: {
          __html: data.notes
        }
      };
    } else {
      return { whiteSpace: "pre-wrap" };
    }
  }, [data, hasGeneratedNotes, visit]);

  const disabled = isDisabled(visit);

  return (
    <Column>
      {(isLoading || pause) && !hasGeneratedNotes && (
        <Box margin={"30px 0px"}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="caption" textAlign={"center"}>
            Generating Notes...
          </Typography>
        </Box>
      )}
      {(data || hasGeneratedNotes) && (
        <GeneratedNotes
          variant="body1"
          color={gray[900]}
          {...style}
        ></GeneratedNotes>
      )}
      {dateTimeGenerated && !disabled && (
        <Typography alignSelf={"flex-end"} variant="body1" color={gray[900]}>
          Generated {dateTimeGenerated.toRelative()}
        </Typography>
      )}
    </Column>
  );
};

export default VisitFieldGenerateNotes;
