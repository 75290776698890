import { useCallback, useEffect, useMemo, useState } from "react";
import { FormikProps, useFormik } from "formik";
import styled from "@emotion/styled";
import { DateTime } from "luxon";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import get from "lodash.get";
import set from "lodash.set";

import RolesEnum, {
  canUpdateMemberStatus,
  canUpdateMemberTimePreferences,
  canUpdatePatientStatus
} from "common/enums/RolesEnum";

import {
  EMAIL_VALIDATION_REGEXP,
  areAddressesEqual,
  getStates,
  unmaskPhoneNumber,
  PHONE_VALIDATION_REGEXP,
  isStateAbbreviation,
  isValidUSPostalCode,
  validateFieldLength,
  isFalsy,
  isTruthy,
  checkIdValid,
  formatName
} from "common/helpers/helpers";
import timezones from "common/config/timezones.json";
import {
  useUpdateMemberDataMutation,
  useUpdateMemberDetailsMutation,
  useUpdateMemberStatusMutation
} from "common/services/MemberRegistrationService";
import { RootState, useAppDispatch } from "common/redux";

import { Alert_close, Alert_show } from "common/helpers/AlertHelper";

import isEqual from "lodash.isequal";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { useValidateAddressMutation } from "common/services/DevicesService";
import AddressValidationStatusEnum from "common/enums/AddressValidationStatusEnum";
import { PatientDataFormValidateAddressModal } from "../../PatientData/PatientDataFormValidateAddressModal";
import ErrorComponent from "../../../components/ErrorComponent";
import PhoneInputField from "../../../components/Input/PhoneInputField";
import DatePicker from "../../../components/DatePicker";
import { TurqoiseButton } from "../../../styling";
import {
  StyledTextField,
  TextFieldComponent
} from "../../../helpers/components/Forms/FormHelpers";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import MemberStatusEnum, {
  MemberStatusEnum_toString
} from "common/enums/MemberStatusEnum";
import { ArrowDropDown, ArrowDropUp, CheckCircle } from "@mui/icons-material";
import { blue, gray } from "common/styling/colors";
import { Flexbox } from "../../../styling/NewStyleComponents";
import RecurrenceFrequencyEnum from "common/enums/Calendaring/Appointments/RecurrenceFrequencyEnum";
import {
  useGetMemberStatusHistoryQuery,
  useUpdateMemberMetadataMutation
} from "common/services/MemberService";
import {
  WEEKDAYS,
  defaultPrefObject,
  transformTimeOfDayPrefArrayToObject
} from "common/helpers/CalendarHelper";
import StyledIconButton from "../../../components/Button/StyledIconButton";

const DATE_FORMAT = "MM/dd/yyyy";
const minDate = DateTime.fromFormat("01/01/1900", DATE_FORMAT);
const maxDate = DateTime.now();

const stateDropdownOptions = getStates();
const defaultFormStyles = { mb: "20px" };

const STREET1 = "address.street1";
const STREET2 = "address.street2";
const CITY = "address.city";
const STATE = "address.state";
const POSTAL_CODE = "address.postal_code";
const COUNTRY = "address.country";

const EditMemberDetailsFormContainer = styled.div``;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: 20px;
`;

const Row = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

const SubmitButton = styled(TurqoiseButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  position: relative;
`;

const FormOuterContainer = styled.div`
  margin: 12px 0px;
  padding: 26px 36px;
  background: #ffffff;
  border: 1px solid #dadcdf;
  borderradius: 9px;
`;

const FirstColumn = styled("div")`
  padding: 0 12px;
`;

const SecondColumn = styled(Box)`
  width: 116px;
  text-align: center;
`;

const RowContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;

// type FormType = Partial<MemberTypeInner>;
type FormType = any;

function getChangedFields(initialValues, values) {
  // const changes: Partial<MemberTypeInner> = {};
  const changes: any = {};
  let sameAddress = false;
  let hasChanges = false;

  const processValue = (key) => {
    const initialValue = initialValues;

    if (key === "address") {
      if (!areAddressesEqual(initialValue[key], values[key])) {
        changes[key] = values[key];
        hasChanges = true;
      } else {
        sameAddress = true;
      }
      return;
    }
    if (key === "contact_info") {
      if (JSON.stringify(initialValue[key]) !== JSON.stringify(values[key])) {
        changes[key] = values[key];

        if (changes[key]) {
          changes[key]["landline"] = {
            ...values[key]?.landline,
            value: unmaskPhoneNumber(changes[key]?.landline?.value)
          };
          changes[key]["mobile"] = {
            ...values[key]?.mobile,
            value: unmaskPhoneNumber(changes[key]?.mobile?.value)
          };
        }
        hasChanges = true;
      } else {
        sameAddress = true;
      }
      return;
    }

    if (key === "timePreferences") {
      values[key]?.forEach((day, index) => {
        if (
          day["MORNING"] !== initialValues?.[key][index]["MORNING"] ||
          day["MIDDAY"] !== initialValues?.[key][index]["MIDDAY"] ||
          day["AFTERNOON"] !== initialValues?.[key][index]["AFTERNOON"]
        ) {
          changes[key] = values[key];
          hasChanges = true;
        }
      });
      return;
    }

    if (
      (initialValue === undefined && values !== undefined) ||
      !isEqual(initialValue[key], values[key])
    ) {
      hasChanges = true;
      let value = values[key];
      if (key === "phone" || key === "mobile" || key === "landline") {
        value = unmaskPhoneNumber(value).trim();
      }
      if (key === "first" || key === "last" || key === "middle") {
        value = value.trim();
      }
      changes[key] = value;
    }
  };

  Object.keys(values).forEach((key) => {
    processValue(key);
  });

  if (Object.keys(changes)?.length > 0) {
    if (changes.first) {
      changes.first = changes.first?.trim();
    }
    if (changes.last) {
      changes.last = changes.last?.trim();
    }
    if (changes.middle) {
      changes.middle = changes.middle?.trim();
    }
    if (changes.phone) {
      changes.phone = unmaskPhoneNumber(changes.phone);
    }
    if (changes.mobile) {
      changes.mobile = unmaskPhoneNumber(changes.mobile);
    }
    if (changes.address) {
      changes.address.street1 = changes.address?.street1?.trim();
      if (isTruthy(changes.address?.street2)) {
        changes.address.street2 = changes.address?.street2?.trim();
      }
      changes.address.city = changes.address?.city?.trim();
    }
  }

  return { changes, sameAddress, hasChanges };
}

const ContactInfo = ({ formik }: { formik: FormikProps<FormType> }) => {
  const { values, setFieldValue } = formik;

  const email = values?.contact_info?.email ?? {
    value: "",
    is_preferred: false,
    is_verified: false
  };
  const mobile = values?.contact_info?.mobile ?? {
    value: "",
    is_preferred: false,
    is_verified: false
  };
  const landline = values?.contact_info?.landline ?? {
    value: "",
    is_preferred: false,
    is_verified: false
  };

  return (
    <FormOuterContainer>
      <Row>
        <Typography flex={1} variant="h3" color="text.primary">
          Contact Info
        </Typography>
      </Row>

      <Row>
        <TextField
          value={email.value}
          label={"Email"}
          fullWidth
          data-testid="email"
          onChange={(event) =>
            setFieldValue(`contact_info.email.value`, event.target.value)
          }
          error={isTruthy(formik.errors["email"])}
          // @ts-ignore
          helperText={formik.errors["email"]}
          placeholder={"Enter email"}
        />

        <CheckCircle
          sx={{ opacity: email.is_verified ? 1 : 0 }}
          color={"success"}
        />

        {/* <FormControlLabel
          control={
            <Checkbox
              id="rememberDevice"
              checked={email.is_preferred}
              onChange={(event) => {
                setFieldValue(
                  `contact_info.email.is_preferred`,
                  !email.is_preferred
                );
              }}
            />
          }
          label="Preferred"
        /> */}
      </Row>

      <Row>
        <PhoneInputField
          value={landline.value}
          label={"Landline"}
          fullWidth
          onChange={(event) =>
            setFieldValue(`contact_info.landline.value`, event.target.value)
          }
          error={isTruthy(formik.errors["landline"])}
          // @ts-ignore
          helperText={formik.errors["landline"]}
          placeholder={"Enter landline number"}
        />

        <CheckCircle
          sx={{ opacity: landline.is_verified ? 1 : 0 }}
          color={"success"}
        />

        {/* <FormControlLabel
          control={
            <Checkbox
              id="rememberDevice"
              checked={landline.is_preferred}
              onChange={(event) => {
                setFieldValue(
                  `contact_info.landline.is_preferred`,
                  !landline.is_preferred
                );
              }}
            />
          }
          label="Preferred"
        /> */}
      </Row>

      <Row>
        <PhoneInputField
          value={mobile.value}
          label={"Mobile Number"}
          fullWidth
          onChange={(event) =>
            setFieldValue(`contact_info.mobile.value`, event.target.value)
          }
          error={isTruthy(formik.errors["mobile"])}
          // @ts-ignore
          helperText={formik.errors["mobile"]}
          placeholder={"Enter mobile number"}
        />

        <CheckCircle
          sx={{ opacity: mobile.is_verified ? 1 : 0 }}
          color={"success"}
        />

        {/* <FormControlLabel
          control={
            <Checkbox
              id="rememberDevice"
              checked={mobile.is_preferred}
              onChange={(event) => {
                setFieldValue(
                  `contact_info.mobile.is_preferred`,
                  !mobile.is_preferred
                );
              }}
            />
          }
          label="Preferred"
        /> */}
      </Row>

      {/* <ErrorComponent error={formik.errors["is_preferred_multiple"]} /> */}
    </FormOuterContainer>
  );
};

const EditMemberDetailsForm = ({
  patient,
  patientError,
  appointmentPreferences
}) => {
  const preferencesFormDefaults = {
    frequency: appointmentPreferences?.data?.frequency ?? "",
    MONDAY:
      transformTimeOfDayPrefArrayToObject(
        appointmentPreferences?.data?.times_of_day?.MONDAY
      ) ?? defaultPrefObject,
    TUESDAY:
      transformTimeOfDayPrefArrayToObject(
        appointmentPreferences?.data?.times_of_day?.TUESDAY
      ) ?? defaultPrefObject,
    WEDNESDAY:
      transformTimeOfDayPrefArrayToObject(
        appointmentPreferences?.data?.times_of_day?.WEDNESDAY
      ) ?? defaultPrefObject,
    THURSDAY:
      transformTimeOfDayPrefArrayToObject(
        appointmentPreferences?.data?.times_of_day?.THURSDAY
      ) ?? defaultPrefObject,
    FRIDAY:
      transformTimeOfDayPrefArrayToObject(
        appointmentPreferences?.data?.times_of_day?.FRIDAY
      ) ?? defaultPrefObject
  };

  const [validateAddressModalOpen, setValidateAddressModalOpen] =
    useState<boolean>(false);
  const [isMemberStatusOpen, setMemberStatusOpen] = useState<boolean>(false);

  const [
    validateAddressMutation,
    {
      data: validateAddressData,
      isSuccess: validateAddressIsSuccess,
      error: validateAddressError,
      isLoading: validateAddressIsLoading
    }
  ] = useValidateAddressMutation();

  const [
    updateMemberStatusMutation,
    {
      error: updateMemberStatusError,
      isSuccess: updateMemberStatusSuccess,
      isLoading: updateMemberStatusLoading
    }
  ] = useUpdateMemberStatusMutation();

  const [
    updateMemberDataMutation,
    {
      error: updateMemberDataError,
      isSuccess: updateMemberDataSuccess,
      isLoading: updateMemberDataLoading
    }
  ] = useUpdateMemberDataMutation();

  const [
    updateMemberMetadata,
    {
      error: memberMetadataError,
      isSuccess: isSuccessMemberMetadata,
      isLoading: memberMetadataLoading
    }
  ] = useUpdateMemberMetadataMutation();

  const params = useSanitizedParams();
  const { memberId } = params;
  const isValidId = checkIdValid(memberId);

  const {
    data: memberStatusHistory,
    isLoading: memberStatusHistoryLoading,
    error: memberStatusHistoryError
  } = useGetMemberStatusHistoryQuery(
    { memberId },
    { skip: !isValidId || !isMemberStatusOpen }
  );

  const [formChanges, setFormChanges] = useState<object>({});

  const dispatch = useAppDispatch();
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [
    updatePatientMutation,
    {
      error: updateUserError,
      isLoading: updateUserLoading,
      isSuccess: updateUserSuccess
    }
  ] = useUpdateMemberDetailsMutation();

  const [sameAddressError, setSameAddressError] = useState<string>("");

  useEffect(() => {
    if (
      updateUserSuccess ||
      updateMemberStatusSuccess ||
      updateMemberDataSuccess ||
      isSuccessMemberMetadata
    ) {
      const id = "updateUserSuccess";
      Alert_close({ dispatch, id });
      Alert_show({
        dispatch,
        id,
        title: "Success",
        content: "User details have been successfully updated.",
        type: "success",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [
    updateUserSuccess,
    updateMemberStatusSuccess,
    updateMemberDataSuccess,
    isSuccessMemberMetadata
  ]);

  function isPreferredMultipleValues(...args) {
    return args.filter((v) => v).length > 1;
  }

  const validate = (values) => {
    const errors = {};

    if (
      isPreferredMultipleValues(
        values.contact_info?.landline?.is_preferred,
        values.contact_info?.mobile?.is_preferred,
        values.contact_info?.email?.is_preferred
      )
    ) {
      errors["is_preferred_multiple"] =
        "You cannot have multiple contact information with preferred checked. Please select only one.";
    }
    if (
      values.phone &&
      !PHONE_VALIDATION_REGEXP.test(unmaskPhoneNumber(values.phone)) &&
      !validateFieldLength(10, 10, unmaskPhoneNumber(values.phone))
    ) {
      errors["phone"] = "Please enter a valid 10-digit phone number";
    }

    if (
      values.contact_info?.landline?.value &&
      !PHONE_VALIDATION_REGEXP.test(
        unmaskPhoneNumber(values.contact_info?.landline?.value)
      ) &&
      !validateFieldLength(
        10,
        10,
        unmaskPhoneNumber(values.contact_info?.landline?.value)
      )
    ) {
      errors["landline"] = "Please enter a valid 10-digit phone number";
    }

    if (
      values.contact_info?.mobile?.value &&
      !PHONE_VALIDATION_REGEXP.test(
        unmaskPhoneNumber(values.contact_info?.mobile?.value)
      ) &&
      !validateFieldLength(
        10,
        10,
        unmaskPhoneNumber(values.contact_info?.mobile?.value)
      )
    ) {
      errors["mobile"] = "Please enter a valid 10-digit mobile number";
    }

    if (
      values?.contact_info?.email?.value &&
      !EMAIL_VALIDATION_REGEXP.test(values?.contact_info?.email?.value)
    ) {
      errors["email"] = "Invalid email address";
    }

    if (!validateFieldLength(2, 50, values["first"])) {
      errors["first"] = "Please enter a value between 2 and 50 characters.";
    }

    if (!validateFieldLength(2, 50, values["last"])) {
      errors["last"] = "Please enter a value between 2 and 50 characters.";
    }

    if (
      isTruthy(values["timezone"]) &&
      timezones.find((timezone) => {
        return timezone.value == values["timezone"];
      }) === undefined
    ) {
      errors["timezone"] = "Please select a valid timezone.";
    }

    if (isFalsy(values["birthdate"])) {
      errors["birthdate"] = "Please select a valid birthdate.";
    }

    if (values["birthdate"]) {
      const birthdate = DateTime.fromFormat(values?.birthdate, DATE_FORMAT);

      if (!birthdate?.isValid) {
        errors["birthdate"] = "Please select a valid birthdate.";
      }

      if (
        birthdate.diff(minDate, "days").days < 0 ||
        birthdate.diff(maxDate, "days").days > 0
      ) {
        errors["birthdate"] = "Please select a valid birthdate.";
      }
    }

    if (values["address"]) {
      const street1 = get(values, STREET1, null);
      const street2 = get(values, STREET2, null);
      const city = get(values, CITY, null);
      const state = get(values, STATE, null);
      const postal_code = get(values, POSTAL_CODE, null);
      const country = get(values, COUNTRY, null);

      if (!street1) {
        set(errors, STREET1, "Required");
      }
      if (!validateFieldLength(2, 50, street1)) {
        set(
          errors,
          STREET1,
          "Please enter a value between 2 and 50 characters."
        );
      }

      if (street2 && !validateFieldLength(1, 50, street2)) {
        set(errors, STREET2, "Please enter a value up to 50 characters.");
      }

      if (!city) {
        set(errors, CITY, "Required");
      }
      if (!validateFieldLength(2, 50, city)) {
        set(errors, CITY, "Please enter a value between 2 and 50 characters.");
      }

      if (!state) {
        set(errors, STATE, "Required");
      } else if (!isStateAbbreviation(state)) {
        set(errors, STATE, "Please enter a valid US state abbreviation.");
      }

      if (!postal_code) {
        set(errors, POSTAL_CODE, "Required");
      }

      if (!isValidUSPostalCode(postal_code)) {
        set(errors, POSTAL_CODE, "Please enter a valid US postal code.");
      }

      if (!country) {
        set(errors, COUNTRY, "Required");
      }

      if (
        !values?.frequency &&
        values.timePreferences.some(
          (item) => item.MORNING || item.MIDDAY || item.AFTERNOON
        )
      ) {
        errors["frequency"] = "Please select a cadence";
      }

      if (
        // if no boxes are checked
        !values.timePreferences.some(
          (item) => item.MORNING || item.MIDDAY || item.AFTERNOON
        ) &&
        values?.frequency
      ) {
        errors["time"] = "Please select at least one time preference";
      }
    }

    return errors;
  };

  const onSubmit = async (values, initialValues) => {
    setSameAddressError("");
    const { changes, hasChanges, sameAddress } = getChangedFields(
      initialValues,
      values
    );
    if (hasChanges) {
      setFormChanges(changes);
      if (changes.address) {
        validateAddressMutation({
          address: values.address
        });
      }
      if (changes.status) {
        await updateMemberStatusMutation({
          patient_id: patient?.patient?.patient_id,
          status: changes.status,
          status_effective_date: DateTime.now()
        });
      }

      if (changes.contact_info) {
        await updateMemberDataMutation({
          member_id: patient?.patient?.patient_id,
          contact_info: changes.contact_info
        });
      }
      delete changes.contact_info;
      delete changes.status;

      if (changes.frequency || changes.timePreferences) {
        const timesOfDay = {};
        values.timePreferences.forEach((item) => {
          const arr = [];
          Object.keys(item).forEach((i) => {
            if (item[i] === true) {
              arr.push(i);
            }
          });
          if (arr.length > 0) {
            timesOfDay[item.day] = arr;
          }
        });

        await updateMemberMetadata({
          memberId: memberId,
          data: {
            preferences: {
              appointment: {
                ...(changes.timePreferences && { times_of_day: timesOfDay }),
                ...(changes.frequency && { frequency: values.frequency })
              }
            }
          }
        });
        delete changes.frequency;
        delete changes.timePreferences;
      }

      if (Object.keys(changes).length > 0) {
        await updatePatientMutation({
          patient_id: patient?.patient?.patient_id,
          patient: changes
        });
      }

      // break if we send an update request
      return;
    }

    // otherwise display "same address error" if address is the same as before
    if (sameAddress) {
      setSameAddressError("Error: Address is the same as before.");
    }
  };

  const defaultTimePreferences = useMemo(() => {
    return [
      {
        day: "MONDAY",
        ...preferencesFormDefaults.MONDAY
      },
      {
        day: "TUESDAY",
        ...preferencesFormDefaults.TUESDAY
      },
      {
        day: "WEDNESDAY",
        ...preferencesFormDefaults.WEDNESDAY
      },
      {
        day: "THURSDAY",
        ...preferencesFormDefaults.THURSDAY
      },
      {
        day: "FRIDAY",
        ...preferencesFormDefaults.FRIDAY
      }
    ];
  }, [appointmentPreferences.isSuccess]);

  const [initialValues, setInitialValues] = useState<FormType>({
    ...patient?.patient,
    frequency: preferencesFormDefaults.frequency,
    timePreferences: defaultTimePreferences
  });

  useEffect(() => {
    async function updatePatientDetails(changes) {
      await updatePatientMutation({
        patient_id: patient?.patient?.patient_id,
        patient: changes
      });
    }
    if (validateAddressIsSuccess && validateAddressData) {
      if (
        validateAddressData?.addressesEqual &&
        validateAddressData?.status === AddressValidationStatusEnum.VALID
      ) {
        // if address is already validated, update patient details
        updatePatientDetails(formChanges);
      } else {
        setValidateAddressModalOpen(true);
      }
    }
  }, [validateAddressData, validateAddressIsSuccess]);

  useEffect(() => {
    const error =
      validateAddressError ||
      updateMemberStatusError ||
      updateMemberDataError ||
      memberMetadataError;
    if (error) {
      Alert_show({
        dispatch,
        id: "validateAddressError",
        title: "Error",
        content: <ErrorComponent error={error} />,
        size: "small",
        type: "warning"
      });
    }
  }, [
    validateAddressError,
    updateMemberStatusError,
    updateMemberDataError,
    memberMetadataError
  ]);

  useEffect(() => {
    if (patient && appointmentPreferences) {
      setInitialValues({
        ...patient?.patient,
        frequency: preferencesFormDefaults.frequency,
        timePreferences: defaultTimePreferences
      });
    }
  }, [patient, appointmentPreferences, defaultTimePreferences]);

  const formik = useFormik<FormType>({
    validate,
    initialValues: {
      ...initialValues,
      frequency: preferencesFormDefaults.frequency,
      timePreferences: defaultTimePreferences
    },
    onSubmit: (values) => {
      onSubmit(values, initialValues).catch((error) => {});
    },
    enableReinitialize: true
  });

  const setFieldValue = (key, value) => {
    formik.setFieldValue(key, value).catch((error) => {});
  };

  const executeOnSubmit = useCallback(
    async (address) => {
      // update changes object with selected validated address
      const finalChanges = { ...formChanges, address };

      await updatePatientMutation({
        patient_id: patient?.patient?.patient_id,
        patient: finalChanges
      });
    },
    [formChanges, patient]
  );

  const [checkedArray, setCheckedArray] = useState(defaultTimePreferences);

  useEffect(() => {
    formik.setFieldValue("timePreferences", checkedArray);
  }, [checkedArray]);

  const resetStateValues = useCallback(() => {
    setCheckedArray(defaultTimePreferences);
    setInitialValues({
      ...patient?.patient,
      frequency: preferencesFormDefaults.frequency,
      timePreferences: defaultTimePreferences
    });
  }, [preferencesFormDefaults]);

  const isAllChecked = (arr) => {
    return arr.every((item) => {
      return item.MORNING && item.MIDDAY && item.AFTERNOON;
    });
  };

  const isAnyChecked = (arr) => {
    return arr.some((item) => {
      return item.MORNING || item.MIDDAY || item.AFTERNOON;
    });
  };

  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedArray = [...checkedArray].map((item) => {
      return {
        day: item.day,
        MORNING: event.target.checked,
        MIDDAY: event.target.checked,
        AFTERNOON: event.target.checked
      };
    });
    setCheckedArray(newCheckedArray);
  };

  const isAllRowChecked = (arr, timeOfDay) => {
    return arr.every((item) => {
      return item[timeOfDay];
    });
  };

  const isAnyRowChecked = (arr, timeOfDay) => {
    return arr.some((item) => {
      return item[timeOfDay];
    });
  };

  const handleBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    day,
    timeOfDay
  ) => {
    const newCheckedArray = [...checkedArray].map((item) => {
      if (item.day === day) {
        return {
          ...item,
          [timeOfDay]: event.target.checked
        };
      }

      return item;
    });

    setCheckedArray(newCheckedArray);
  };

  const isBoxChecked = (arr, day, timeOfDay) => {
    const item = arr.find((item) => item.day === day);

    return item?.[timeOfDay];
  };

  const handleAllRowChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    timeOfDay
  ) => {
    const newCheckedArray = [...checkedArray].map((item) => {
      return {
        ...item,
        [timeOfDay]: event.target.checked
      };
    });

    setCheckedArray(newCheckedArray);
  };

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return (
    <EditMemberDetailsFormContainer>
      {patient ? (
        <>
          <form onSubmit={formik.handleSubmit}>
            <FormOuterContainer>
              <Typography variant="h3" color="text.primary" mb="45px">
                Profile Info
              </Typography>
              <Grid>
                <StyledTextField
                  value={formik.values?.first || ""}
                  onChange={(e) => {
                    setFieldValue("first", e.target.value);
                  }}
                  label="First Name"
                  fullWidth
                  placeholder={patient?.first ? "" : "Enter first name"}
                  error={isTruthy(formik.errors["first"])}
                  helperText={formik.errors["first"]}
                  autoComplete="given-name"
                />

                <StyledTextField
                  value={formik.values?.middle || ""}
                  onChange={(e) => {
                    setFieldValue("middle", e.target.value);
                  }}
                  label="Middle Name"
                  fullWidth
                  placeholder={"Enter middle name"}
                  error={isTruthy(formik.errors["middle"])}
                  helperText={formik.errors["middle"]}
                  autoComplete="additional-name"
                />

                <StyledTextField
                  value={formik.values?.last || ""}
                  onChange={(e) => {
                    setFieldValue("last", e.target.value);
                  }}
                  label="Last Name"
                  fullWidth
                  placeholder={"Enter last name"}
                  error={isTruthy(formik.errors["last"])}
                  helperText={formik.errors["last"]}
                  autoComplete="family-name"
                />

                <DatePicker
                  format={DATE_FORMAT}
                  label={"Date of Birth"}
                  value={
                    formik.values?.birthdate
                      ? DateTime.fromFormat(
                          formik.values?.birthdate,
                          DATE_FORMAT
                        )
                      : DateTime.now().minus({ years: 20 })
                  }
                  slotProps={{
                    textField: {
                      // @ts-ignore
                      helperText: formik.errors["birthdate"],
                      error: isTruthy(formik.errors["birthdate"]),
                      // prevent user from typing in the date as this can lead to bugs
                      // see ENG-3757
                      // the below code needs to be here instead of in DateTimePicker.tsx
                      // until this PR is merged https://github.com/mui/material-ui/pull/35088
                      onKeyDown: (e) => {
                        e.preventDefault();
                      }
                    }
                  }}
                  onChange={(date) => {
                    if (date?.isValid) {
                      setFieldValue("birthdate", date.toFormat(DATE_FORMAT));
                    }
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                />

                <TextFieldComponent
                  formik={formik}
                  fieldName={"timezone"}
                  label={"Timezone"}
                  placeholder={"Select Timezone"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                  select={true}
                >
                  {timezones.map(({ label, value }) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </Grid>
            </FormOuterContainer>

            <FormOuterContainer>
              <Typography variant="h3" color="text.primary" mb="45px">
                Address Info
              </Typography>

              <Grid>
                <TextFieldComponent
                  formik={formik}
                  fieldName={STREET1}
                  label={"Street 1"}
                  placeholder={"Enter Street 1"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                />

                <TextFieldComponent
                  formik={formik}
                  fieldName={STREET2}
                  label={"Street 2"}
                  placeholder={"Enter Street 2"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                />
                <TextFieldComponent
                  formik={formik}
                  fieldName={CITY}
                  label={"City"}
                  placeholder={"Enter City"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                />
                <TextFieldComponent
                  formik={formik}
                  fieldName={STATE}
                  label={"State"}
                  placeholder={"Enter State"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                  select={true}
                  slotProps={{
                    select: {
                      variant: "outlined",
                      MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
                    }
                  }}
                >
                  {stateDropdownOptions.map((state) => (
                    <MenuItem key={state.value} value={state.value}>
                      {state.label}
                    </MenuItem>
                  ))}
                </TextFieldComponent>
                <TextFieldComponent
                  formik={formik}
                  fieldName={POSTAL_CODE}
                  label={"Postal Code"}
                  placeholder={"Enter Postal Code"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                />
                <TextFieldComponent
                  formik={formik}
                  fieldName={COUNTRY}
                  select={true}
                  label={"Country"}
                  placeholder={"Select Country"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                >
                  <MenuItem key="US" value="US">
                    US
                  </MenuItem>
                </TextFieldComponent>
              </Grid>
            </FormOuterContainer>

            {patient && <ContactInfo formik={formik} />}

            <FormOuterContainer>
              <Typography variant="h3" color="text.primary" mb="45px">
                Member Status Details
              </Typography>

              {canUpdateMemberStatus(patient?.patient?.status, currentRole) ? (
                <TextFieldComponent
                  sx={defaultFormStyles}
                  formik={formik}
                  fieldName={"status"}
                  select={true}
                  label={"Status"}
                  placeholder={"Select Status"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                >
                  <MenuItem
                    key={patient?.patient?.status}
                    value={patient?.patient?.status}
                  >
                    {MemberStatusEnum_toString(patient?.patient?.status)}
                  </MenuItem>

                  <MenuItem
                    key={MemberStatusEnum.REEVALUATING_PATIENT}
                    value={MemberStatusEnum.REEVALUATING_PATIENT}
                  >
                    {MemberStatusEnum_toString(
                      MemberStatusEnum.REEVALUATING_PATIENT
                    )}
                  </MenuItem>

                  {patient?.patient?.status !== MemberStatusEnum.CANCELED &&
                    currentRole === RolesEnum.ADMIN && (
                      <MenuItem
                        key={MemberStatusEnum.CANCELED}
                        value={MemberStatusEnum.CANCELED}
                      >
                        {MemberStatusEnum_toString(MemberStatusEnum.CANCELED)}
                      </MenuItem>
                    )}
                </TextFieldComponent>
              ) : (
                <Typography variant="body1" color="text.primary">
                  {`${MemberStatusEnum_toString(
                    patient?.patient?.status
                  )} since ${DateTime.fromISO(
                    patient?.patient?.status_effective_date
                  ).toFormat(DATE_FORMAT)}`}
                </Typography>
              )}
              <br />
              <Row>
                <Typography variant="h5" color="text.primary">
                  Member Status History
                </Typography>
                <StyledIconButton
                  Icon={isMemberStatusOpen ? ArrowDropUp : ArrowDropDown}
                  color={blue[700]}
                  border="circle"
                  size="small"
                  onClick={() => setMemberStatusOpen(!isMemberStatusOpen)}
                />
              </Row>

              {isMemberStatusOpen && (
                <>
                  {memberStatusHistory?.map((item) => {
                    const hasStatusReason =
                      item.status_reason &&
                      item.status_reason.length > 0 &&
                      item.status_reason.toLowerCase() !== "none";

                    const statusReasonString = hasStatusReason
                      ? ` (${item.status_reason.split("_").join(" ")})`
                      : "";
                    return (
                      <Typography key={item.patient_audit_event_id}>
                        • Status updated to{" "}
                        <b>
                          {item.status}
                          {statusReasonString}
                        </b>{" "}
                        on{" "}
                        <b>
                          {DateTime.fromSQL(item.updated_at).toFormat(
                            "MM/dd/yyyy"
                          )}
                        </b>
                      </Typography>
                    );
                  })}

                  {memberStatusHistoryLoading && <CircularProgress />}
                  <ErrorComponent error={memberStatusHistoryError} />
                </>
              )}
            </FormOuterContainer>

            {(appointmentPreferences.isSuccess ||
              appointmentPreferences.isError) && (
              <FormOuterContainer>
                {(appointmentPreferences.isSuccess ||
                  appointmentPreferences.isError) && (
                  <>
                    <Flexbox justifyContent="space-between" marginBottom="12px">
                      <Flexbox alignItems="center">
                        <Typography variant="body1" fontWeight={500}>
                          Set Time & Cadence Preferences
                        </Typography>
                      </Flexbox>

                      <Flexbox gap="12px" alignItems="center">
                        <Typography variant="body1" fontWeight={600}>
                          Cadence
                        </Typography>
                        <TextField
                          value={formik.values.frequency}
                          onChange={(event) =>
                            formik.setFieldValue(
                              "frequency",
                              event.target.value
                            )
                          }
                          disabled={
                            !canUpdateMemberTimePreferences(currentRole)
                          }
                          select
                          sx={{ width: "356px", backgroundColor: "white" }}
                          label="Select an option"
                          placeholder="Select an option"
                        >
                          <MenuItem
                            key={"weekly"}
                            value={RecurrenceFrequencyEnum.WEEKLY}
                          >
                            Weekly
                          </MenuItem>
                          <MenuItem
                            key={"biweekly"}
                            value={RecurrenceFrequencyEnum.BIWEEKLY}
                          >
                            Biweekly
                          </MenuItem>
                        </TextField>
                      </Flexbox>
                    </Flexbox>
                    <Box textAlign="right" marginBottom="12px" height="20px">
                      {formik.errors?.frequency && formik.touched && (
                        <ErrorComponent
                          error={formik.errors?.frequency.toString()}
                        />
                      )}
                    </Box>
                    <Box
                      bgcolor="white"
                      sx={{
                        display: "grid",
                        justifyItems: "center",
                        rowGap: "16px"
                      }}
                    >
                      <RowContainer>
                        <Flexbox marginRight={"24px"}>
                          <FirstColumn>
                            <Box>
                              <Checkbox
                                checked={isAllChecked(checkedArray)}
                                indeterminate={
                                  isAnyChecked(checkedArray) &&
                                  !isAllChecked(checkedArray)
                                }
                                onChange={handleAllChange}
                                disabled={
                                  !canUpdateMemberTimePreferences(currentRole)
                                }
                              />
                            </Box>
                          </FirstColumn>
                          <SecondColumn>
                            <div>&nbsp;</div>
                          </SecondColumn>
                        </Flexbox>
                        <Flexbox
                          justifyContent="space-between"
                          width="100%"
                          marginRight={"24px"}
                        >
                          {WEEKDAYS.map((day) => (
                            <Typography
                              fontSize="16px"
                              fontWeight={600}
                              lineHeight="24px"
                            >
                              {formatName(day)}
                            </Typography>
                          ))}
                        </Flexbox>
                      </RowContainer>

                      <RowContainer
                        border={`1px solid ${gray[300]}`}
                        bgcolor={gray[50]}
                        borderRadius="12px"
                        padding="16px 0"
                      >
                        <Flexbox marginRight={"24px"}>
                          <FirstColumn>
                            <Box>
                              <Checkbox
                                checked={isAllRowChecked(
                                  checkedArray,
                                  "MORNING"
                                )}
                                disabled={
                                  !canUpdateMemberTimePreferences(currentRole)
                                }
                                indeterminate={
                                  isAnyRowChecked(checkedArray, "MORNING") &&
                                  !isAllRowChecked(checkedArray, "MORNING")
                                }
                                onChange={(e) => {
                                  handleAllRowChange(e, "MORNING");
                                }}
                              />
                            </Box>
                          </FirstColumn>
                          <SecondColumn
                            borderLeft={`1px solid ${gray[300]}`}
                            borderRight={`1px solid ${gray[300]}`}
                          >
                            <Typography variant="body1">Morning</Typography>
                            <Typography variant="body1">8 - 11 a.m.</Typography>
                          </SecondColumn>
                        </Flexbox>
                        <Flexbox
                          justifyContent="space-between"
                          width="100%"
                          marginRight={"24px"}
                        >
                          {WEEKDAYS.map((day) => (
                            <Box>
                              <Checkbox
                                checked={isBoxChecked(
                                  checkedArray,
                                  day,
                                  "MORNING"
                                )}
                                disabled={
                                  !canUpdateMemberTimePreferences(currentRole)
                                }
                                onChange={(e) =>
                                  handleBoxChange(e, day, "MORNING")
                                }
                              />
                            </Box>
                          ))}
                        </Flexbox>
                      </RowContainer>

                      <RowContainer
                        border={`1px solid ${gray[300]}`}
                        bgcolor={gray[50]}
                        borderRadius="12px"
                        padding="16px 0"
                      >
                        <Flexbox marginRight={"24px"}>
                          <FirstColumn>
                            <Box>
                              <Checkbox
                                checked={isAllRowChecked(
                                  checkedArray,
                                  "MIDDAY"
                                )}
                                disabled={
                                  !canUpdateMemberTimePreferences(currentRole)
                                }
                                indeterminate={
                                  isAnyRowChecked(checkedArray, "MIDDAY") &&
                                  !isAllRowChecked(checkedArray, "MIDDAY")
                                }
                                onChange={(e) => {
                                  handleAllRowChange(e, "MIDDAY");
                                }}
                              />
                            </Box>
                          </FirstColumn>
                          <SecondColumn
                            borderLeft={`1px solid ${gray[300]}`}
                            borderRight={`1px solid ${gray[300]}`}
                          >
                            <Typography variant="body1">Mid-day</Typography>
                            <Typography variant="body1">11 - 2 p.m.</Typography>
                          </SecondColumn>
                        </Flexbox>
                        <Flexbox
                          justifyContent="space-between"
                          width="100%"
                          marginRight={"24px"}
                        >
                          {WEEKDAYS.map((day) => (
                            <Box>
                              <Checkbox
                                checked={isBoxChecked(
                                  checkedArray,
                                  day,
                                  "MIDDAY"
                                )}
                                disabled={
                                  !canUpdateMemberTimePreferences(currentRole)
                                }
                                onChange={(e) =>
                                  handleBoxChange(e, day, "MIDDAY")
                                }
                              />
                            </Box>
                          ))}
                        </Flexbox>
                      </RowContainer>

                      <RowContainer
                        border={`1px solid ${gray[300]}`}
                        bgcolor={gray[50]}
                        borderRadius="12px"
                        padding="16px 0"
                      >
                        <Flexbox marginRight={"24px"}>
                          <FirstColumn>
                            <Box>
                              <Checkbox
                                checked={isAllRowChecked(
                                  checkedArray,
                                  "AFTERNOON"
                                )}
                                disabled={
                                  !canUpdateMemberTimePreferences(currentRole)
                                }
                                indeterminate={
                                  isAnyRowChecked(checkedArray, "AFTERNOON") &&
                                  !isAllRowChecked(checkedArray, "AFTERNOON")
                                }
                                onChange={(e) => {
                                  handleAllRowChange(e, "AFTERNOON");
                                }}
                              />
                            </Box>
                          </FirstColumn>
                          <SecondColumn
                            borderLeft={`1px solid ${gray[300]}`}
                            borderRight={`1px solid ${gray[300]}`}
                          >
                            <Typography variant="body1">Afternoon</Typography>
                            <Typography variant="body1">2 - 5 p.m.</Typography>
                          </SecondColumn>
                        </Flexbox>
                        <Flexbox
                          justifyContent="space-between"
                          width="100%"
                          marginRight={"24px"}
                        >
                          {WEEKDAYS.map((day) => (
                            <Box>
                              <Checkbox
                                checked={isBoxChecked(
                                  checkedArray,
                                  day,
                                  "AFTERNOON"
                                )}
                                disabled={
                                  !canUpdateMemberTimePreferences(currentRole)
                                }
                                onChange={(e) =>
                                  handleBoxChange(e, day, "AFTERNOON")
                                }
                              />
                            </Box>
                          ))}
                        </Flexbox>
                      </RowContainer>
                    </Box>
                    <Flexbox marginTop="12px">
                      {formik.errors?.time && (
                        <ErrorComponent
                          error={formik.errors?.time.toString()}
                        />
                      )}
                    </Flexbox>
                  </>
                )}
                {appointmentPreferences.isFetching && (
                  <LoadingFallback delay={50} />
                )}
              </FormOuterContainer>
            )}

            <SubmitButtonContainer>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  resetStateValues();
                  formik.resetForm();
                }}
              >
                Discard
              </Button>
              <SubmitButton
                type="submit"
                loading={
                  updateUserLoading ||
                  validateAddressIsLoading ||
                  updateMemberStatusLoading ||
                  updateMemberDataLoading ||
                  memberMetadataLoading
                }
                disabled={!formik.isValid || !formik.dirty || isFalsy(patient)}
              >
                Save Changes
              </SubmitButton>
            </SubmitButtonContainer>
          </form>

          <br />
          {(updateUserError || sameAddressError) && (
            <ErrorComponent error={updateUserError || sameAddressError} />
          )}
        </>
      ) : (
        <LoadingFallback delay={500} count={5} />
      )}
      {patientError && <ErrorComponent error={patientError} />}
      <PatientDataFormValidateAddressModal
        key={`edit-patient-details-validate-address-modal${memberId}`}
        modalOpen={validateAddressModalOpen}
        setModalOpen={setValidateAddressModalOpen}
        addresses={validateAddressData}
        executeOnSubmit={executeOnSubmit}
      />
    </EditMemberDetailsFormContainer>
  );
};

export default EditMemberDetailsForm;
