import { useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";

import { RootState, useAppDispatch } from "common/redux";
import WebSocketMessageType from "common/types/WebSocketMessageType";
import { fetchSocketUrl } from "common/redux/AuthSlice";

const useRIQWebSocket = () => {
  const dispatch = useAppDispatch();
  const { socketUrl } = useSelector((state: RootState) => state.auth);

  const { sendMessage, sendJsonMessage, lastMessage } =
    useWebSocket<WebSocketMessageType>(socketUrl, {
      shouldReconnect: (e) => {
        // @ts-ignore
        const url = e.currentTarget.url;
        if (url === socketUrl) {
          dispatch(fetchSocketUrl());
        }
        return true;
      }
    });

  return {
    lastMessage: lastMessage?.data && JSON.parse(lastMessage?.data),
    sendMessage,
    sendJsonMessage
  };
};

export default useRIQWebSocket;
