import { Platform } from "react-native";
import DeviceInfo from "react-native-device-info";

import { DateTime } from "luxon";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import RolesEnum from "../enums/RolesEnum";
import {
  SentryHelper_captureException,
  SentryHelper_setUser
} from "../helpers/SentryHelper";

import {
  AuthService_LoginWithPassword,
  AuthService_LoginWithSMSCode,
  LoginWithSMSProps
} from "../services/AuthService";
import UserTypeInner from "../types/UserTypeInner";
import { alertClose } from "./AlertSlice";
import { APIS, axiosMedusaAuth } from "../services/AxiosService";
import StorageHelper from "../helpers/StorageHelper";
import StorageEnum from "../enums/StorageEnum";
import EnvVars from "../config/EnvVars";
import {
  AnalyticsHelper_identify,
  AnalyticsHelper_logSegmentEvent,
  AnalyticsHelper_reset
} from "../helpers/firebase/AnalyticsHelper";
import { getNameOrUsername, isTruthy } from "../helpers/helpers";
import LocalizedStrings from "../localizations/LocalizedStrings";
import { Alert_show } from "../helpers/AlertHelper";
import { store } from ".";
import { resetStateVideoCall } from "./VideoCallSlice";
import { setInitialState } from "./VisitsSlice";

import FeatureFlags from "../config/FeatureFlags";
import ProductEnum from "../enums/ProductEnum";
import APIConstants from "../config/APIConstants";

export enum LoginMethodEnum {
  USERNAME_PASSWORD = "USERNAME_PASSWORD",
  SMS_CODE = "SMS_CODE"
}

interface StateType {
  isLoggedIn: boolean;
  hasCredentials: boolean;
  socketUrl: string;
  isLoading: boolean;
  isRefreshingToken: boolean;
  loginMethod: LoginMethodEnum;
  user: Partial<UserTypeInner> | null;
  roles: RolesEnum[] | null;
  currentRole: RolesEnum | null;
  currentBuildEnv: string;
  error?: any;
}

const initialState: StateType = {
  isLoggedIn: false,
  hasCredentials: false,
  isLoading: false,
  isRefreshingToken: false,
  loginMethod: null,
  user: null,
  roles: null,
  currentRole: null,
  error: undefined,
  currentBuildEnv: EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV,
  socketUrl: null
};

interface LoginParams {
  email: string;
  password: string;
}

export const logInWithPassword = createAsyncThunk(
  "auth/login_password",
  async ({ email, password }: LoginParams, thunkAPI) => {
    thunkAPI.dispatch(startLoading());

    try {
      const response = await AuthService_LoginWithPassword(email, password);
      const { data } = response;

      await AnalyticsHelper_identify(data?.user_id);

      thunkAPI.dispatch(finishLoading());

      return { ...data, loginMethod: LoginMethodEnum.USERNAME_PASSWORD };
    } catch (error: any) {
      thunkAPI.dispatch(finishLoading());
      if (!error.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logInWithSMS = createAsyncThunk(
  "auth/login_sms",
  async ({ code, pre_login_id }: LoginWithSMSProps, thunkAPI) => {
    thunkAPI.dispatch(startLoading());

    try {
      const response = await AuthService_LoginWithSMSCode({
        pre_login_id,
        code
      });
      const { data } = response;
      await AnalyticsHelper_identify(data?.user_id);

      if (data?.user_id) {
        const promise =
          Platform.OS === "web"
            ? DeviceInfo.getUserAgent()
            : DeviceInfo.getDeviceName();

        promise.then((device_name) => {
          AnalyticsHelper_logSegmentEvent("member_logged_in", {
            timestamp: DateTime.now().toISO(),
            device_os: Platform.OS,
            device_name,
            app_version:
              Platform.OS === "web" ? undefined : DeviceInfo.getVersion()
          });
        });
      }

      thunkAPI.dispatch(finishLoading());

      return {
        ...data,
        loginMethod: LoginMethodEnum.SMS_CODE
      };
    } catch (error: any) {
      Alert_show({
        dispatch: store.dispatch,
        title: LocalizedStrings.error.title,
        content: LocalizedStrings.error.sign_in_code,
        type: "error"
      });

      thunkAPI.dispatch(finishLoading());
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const onAppStart = createAsyncThunk(
  "auth/onAppStart",
  async (any, thunkAPI) => {
    // Check refresh token JWT, if expired logout
    try {
      const { auth, settings } = thunkAPI.getState();
      const { user, isLoggedIn } = auth;
      const { notificationsEnabled } = settings;

      const user_id = user?.user_id;
      const { isTokenExpired, credentials } = await getCredentials();
      const token = await StorageHelper.getItem(StorageEnum.FCM_TOKEN);
      if (user_id && token && notificationsEnabled && !isTokenExpired) {
        const messagingService = require("../services/MessagingService");
        await thunkAPI.dispatch(
          messagingService.default.endpoints.registerPushNotificationToken.initiate(
            { deviceToken: token, member_id: user_id }
          )
        );
      }

      if (!isLoggedIn && credentials !== null) {
        thunkAPI.dispatch(logOut(true));
      }

      AnalyticsHelper_identify(user?.user_id);

      thunkAPI.dispatch(finishRefreshLoading());
      thunkAPI.dispatch(finishLoading());
    } catch (error) {
      thunkAPI.dispatch(finishLoading());
    }
  }
);

export const fetchSocketUrl = createAsyncThunk(
  "auth/fetchSocketUrl",
  async (any, thunkAPI) => {
    const { auth } = thunkAPI.getState();
    const { isLoggedIn } = auth;

    if (
      isLoggedIn &&
      FeatureFlags().USER_PRESENCE_MANAGEMENT &&
      EnvVars.REACT_APP_PRODUCT === ProductEnum.RemoteIQ
    ) {
      const response = await axiosMedusaAuth.post("/ticket");
      const ticket = response?.data?.ticket;
      if (isTruthy(ticket)) return APIConstants.MEDUSA_WEBSOCKET + ticket;
    }
    return null;
  }
);

const logoutFunction = createAsyncThunk(
  "auth/logoutFunction",
  async (clearMobileStorage: boolean = false, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      thunkAPI.dispatch(
        alertClose({ dispatch: thunkAPI.dispatch, id: "logOut" })
      );
      SentryHelper_setUser(null);
      AnalyticsHelper_reset();

      if (clearMobileStorage) {
        // removes the saved user and phone number if the user manually logs out
        await StorageHelper.removeItem(StorageEnum.AUTHENTICATION_USER_NAME);
      }
      thunkAPI.dispatch(finishLoading());
      // return something from the promise to resolve it
      return { isLoggedIn: false, hasCredentials: false };
    } catch (error) {
      console.log(error);
      SentryHelper_captureException(error);
      thunkAPI.dispatch(finishLoading());
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const getCredentials = async () => {
  const credentialsJson = await StorageHelper.getItem(
    StorageEnum.LOGIN_CREDENTIALS
  );
  if (credentialsJson === undefined) return {};

  const credentials = JSON.parse(credentialsJson);
  if (credentials?.expires === undefined) return {};

  const tokenExpiresAt = DateTime.fromSQL(credentials?.expires);
  const isTokenExpired = tokenExpiresAt < DateTime.now();

  return { isTokenExpired, credentials };
};

const logoutAPICall = async (thunkAPI) => {
  const { isTokenExpired, credentials } = await getCredentials();

  if (
    store.getState().auth.isLoggedIn &&
    credentials?.access_token === undefined
  ) {
    return;
  }
  if (credentials === null || credentials.expires === undefined) return;

  const state = thunkAPI.getState().auth;

  if (
    FeatureFlags().USER_PRESENCE_MANAGEMENT &&
    EnvVars.REACT_APP_PRODUCT === ProductEnum.RemoteIQ &&
    state.isLoggedIn &&
    state.hasCredentials &&
    !isTokenExpired
  ) {
    const userPresenceService = require("../services/UserPresenceService");
    await thunkAPI.dispatch(
      userPresenceService.default.endpoints.postLogout.initiate({})
    );
  }
};

export const logOut = createAsyncThunk(
  "auth/logout",
  async (clearMobileStorage: boolean = false, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const { user } = auth;

      const { isTokenExpired } = await getCredentials();

      if (!isTokenExpired) {
        const token = await StorageHelper.getItem(StorageEnum.FCM_TOKEN);

        try {
          const messagingService = require("../services/MessagingService");
          await thunkAPI.dispatch(
            messagingService.default.endpoints.unregisterPushNotificationToken.initiate(
              { deviceToken: token, member_id: user?.user_id }
            )
          );
          await logoutAPICall(thunkAPI);
        } catch (e) {
          console.log(e);
        }
      }

      thunkAPI.dispatch(resetStateVideoCall());
      thunkAPI.dispatch(setInitialState());
      await thunkAPI.dispatch(logoutFunction(clearMobileStorage));
      APIS.forEach((api) => thunkAPI.dispatch(api.util.resetApiState()));
      // return something from the promise to resolve it
      return { isLoggedIn: false, hasCredentials: false };
    } catch (error) {
      SentryHelper_captureException(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const setLoginCredentials = (state, action) => {
  if (action.payload != null) {
    const {
      access_token,
      refresh_token,
      expires,
      roles,
      user_id,
      username,
      loginMethod,
      first,
      last
    } = action.payload;

    const fullname = getNameOrUsername({ username, first, last });

    state.user = {
      user_id,
      first,
      last,
      email: username,
      fullname
    };

    // RemoteIQ: We want to switch to Auth flow as soon as possible.
    // CopilotIQ: We want to continue with the flow, so the user can manually finish it.
    if (loginMethod === LoginMethodEnum.USERNAME_PASSWORD) {
      state.isLoggedIn = true;
    } else state.isLoggedIn = false;

    state.roles = roles;
    // use the first role in the roles array. If we need to support multiple roles, we need to change this
    // use the first rolePermission in the permissions array. If we need to support a user having
    // access to multiple permissions groups, we need to change this
    state.currentRole = roles[0];

    StorageHelper.setItem(
      StorageEnum.LOGIN_CREDENTIALS,
      JSON.stringify({ access_token, refresh_token, expires })
    );
    state.hasCredentials = true;

    state.error = undefined;

    // set current build env when logging in
    state.currentBuildEnv = EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV;

    SentryHelper_setUser({
      email: username,
      username: username,
      id: user_id
    });
  }
};

const setSocketUrl = (state, action) => {
  state.socketUrl = action.payload;
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginCredentials,
    setSocketUrl,
    setAuthFlowFinished: (state) => {
      state.isLoggedIn = true;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    finishLoading: (state) => {
      state.isLoading = false;
    },
    setCurrentRole: (state, action) => {
      state.currentRole = action.payload;
    },
    startRefreshLoading: (state) => {
      state.isRefreshingToken = true;
    },
    finishRefreshLoading: (state) => {
      state.isRefreshingToken = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logInWithPassword.fulfilled, setLoginCredentials);
    builder.addCase(logInWithPassword.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(logInWithSMS.fulfilled, setLoginCredentials);
    builder.addCase(logInWithSMS.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(logoutFunction.fulfilled, (state, action) => {
      state.isLoggedIn = false;
      state.roles = null;
      state.user = null;
      state.loginMethod = null;
      state.hasCredentials = false;
      state.socketUrl = null;
      StorageHelper.removeItem(StorageEnum.LOGIN_CREDENTIALS);
    });

    builder.addCase(fetchSocketUrl.fulfilled, setSocketUrl);
  }
});

export const {
  startLoading,
  finishLoading,
  startRefreshLoading,
  finishRefreshLoading,
  setCurrentRole,
  setAuthFlowFinished
} = authSlice.actions;

export default authSlice.reducer;
