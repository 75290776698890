import {
  Box,
  Card,
  CircularProgress,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import DropdownDateRangePicker from "../../../components/DropdownDateRangePicker";

import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import { RowContainer } from "../../MemberDetails/StartIntake/StyledComponents";
import { useGetReadingsByMemberQuery } from "common/services/ReadingsService";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import { isFalsy } from "common/helpers/helpers";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { SidebarContainerStyle } from "../VisitSections";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { LightRedDangerIcon } from "../../../assets/images/icons";
import { CustomTooltip } from "../../../styling/StyleComponents";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";

enum TabPanelIndex {
  glucose = 0,
  bloodPressure = 1
}

interface IProps {
  memberId: string;
}

export const RecentReadings = ({ memberId }: IProps) => {
  const [startDate, setStartDate] = useState<DateTime>();
  const [endDate, setEndDate] = useState<DateTime>();
  const [tabIndex, setTabIndex] = useState<number>(TabPanelIndex.glucose);
  const [show, setShow] = useState<boolean>(true);

  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const { data: member } = useGetMemberWithUsernameQuery(
    { username: memberId },
    { skip: isFalsy(memberId) }
  );

  const deviceType = useMemo(() => {
    switch (tabIndex) {
      case TabPanelIndex.glucose:
        return DeviceTypeEnum.GLUCOSE_CATEGORY;
      case TabPanelIndex.bloodPressure:
        return DeviceTypeEnum.BLOOD_PRESSURE;
    }
  }, [tabIndex]);

  return (
    <>
      <RowContainer justifyContent={"space-between"}>
        <Box
          display={"flex"}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setShow(!show);
          }}
        >
          {show ? (
            <ExpandLess fontSize={"small"} />
          ) : (
            <ExpandMore fontSize={"small"} />
          )}
          <Typography variant="h6">Recent Readings</Typography>
        </Box>
        <DropdownDateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={(date) => setStartDate(date)}
          onEndDateChange={(date) => setEndDate(date)}
          timezone={member?.patient.timezone}
          small
        />
      </RowContainer>

      {show && (
        <Card
          sx={{
            ...SidebarContainerStyle,
            height: "180px",
            overflow: "scroll"
          }}
        >
          <Tabs value={tabIndex} onChange={handleTabSelect} variant="fullWidth">
            <Tab
              label={"Glucose"}
              style={{ fontWeight: "600", fontSize: "82%" }}
              value={TabPanelIndex.glucose}
            />
            <Tab
              label={"Blood Pressure"}
              style={{ fontWeight: "600", fontSize: "82%" }}
              value={TabPanelIndex.bloodPressure}
            />
          </Tabs>

          <SlimReadingsTable
            memberId={memberId}
            deviceType={deviceType}
            endDate={endDate}
            startDate={startDate}
          />
        </Card>
      )}
    </>
  );
};

export const SlimReadingsTable = ({
  memberId,
  deviceType,
  startDate,
  endDate
}: {
  memberId: string;
  deviceType: DeviceTypeEnum;
  startDate: DateTime;
  endDate: DateTime;
}) => {
  const { data: member } = useGetMemberWithUsernameQuery(
    { username: memberId },
    { skip: isFalsy(memberId) }
  );
  const { data: readings, isFetching } = useGetReadingsByMemberQuery(
    {
      memberId,
      paramsObject: {
        sort: "measure_timestamp,DESC",
        device_type: deviceType,
        startdate: Math.round(startDate?.toSeconds()),
        enddate: Math.round(endDate?.toSeconds())
      }
    },
    {
      skip:
        startDate === undefined ||
        memberId === undefined ||
        endDate === undefined
    }
  );
  const containsData = isFetching || readings?.length === 0;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={containsData && "center"}
      justifyContent={containsData && "center"}
      height={containsData && 180}
    >
      {isFetching && <CircularProgress />}
      {!isFetching && readings?.length === 0 && (
        <Typography variant="body1">No data</Typography>
      )}

      {!isFetching &&
        readings?.map((item) => {
          if (isFalsy(item)) return;
          const {
            measure_timestamp,
            glucose,
            systolic,
            diastolic,
            c_is_fasting_v1,
            c_is_alertable_v1
          } = item;

          const dateTime = DateTime.fromSeconds(measure_timestamp).setZone(
            member?.patient.timezone
          );
          const value =
            deviceType === DeviceTypeEnum.GLUCOSE_CATEGORY
              ? `${glucose} mg/dL`
              : `${systolic}/${diastolic} mmHg`;

          const readingsStyle = { fontSize: "80%", fontWeight: 600 };

          return (
            <Box
              key={item.measure_timestamp}
              display={"flex"}
              justifyContent={"space-between"}
              margin={1}
            >
              <Flexbox flexDirection={"row"} gap={"5px"} alignItems={"center"}>
                <Typography variant={"body1"} color={"primary"}>
                  {dateTime?.toFormat("MM/dd")}
                </Typography>
                <Typography
                  variant={"caption"}
                  color={"primary"}
                  textAlign={"center"}
                >
                  {dateTime?.toFormat("hh:mm a")}
                </Typography>
                {c_is_alertable_v1 && (
                  <CustomTooltip
                    placement={"top"}
                    title={"Triggered Urgent Alert"}
                  >
                    <LightRedDangerIcon height={"22px"} />
                  </CustomTooltip>
                )}
              </Flexbox>

              <Flexbox flexDirection={"row"} gap={"5px"}>
                {c_is_fasting_v1 && (
                  <Typography
                    variant={"caption"}
                    color={"primary"}
                    sx={readingsStyle}
                  >
                    (Fasting)
                  </Typography>
                )}
                <Typography
                  variant={"body1"}
                  color={"primary"}
                  sx={readingsStyle}
                >
                  {value}
                </Typography>
              </Flexbox>
            </Box>
          );
        })}
    </Box>
  );
};
