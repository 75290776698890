import { CareFlowSubsectionsEnum } from "common/enums/Calendaring/Visits/CareFlowSectionsEnum";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import { isFalsy } from "common/helpers/helpers";
import { AppDispatch } from "common/redux";
import { addTime, setEnabledSections } from "common/redux/VisitsSlice";
import { GoalAssessmentType } from "common/types/GoalType";
import { CareFlowResponseType } from "common/types/Visits/CareFlowResponseType";
import VisitType from "common/types/Visits/VisitType";
import { DateTime, Duration } from "luxon";

const getField = async (careFlow: CareFlowResponseType, fieldId: string) => {
  // Loop through fields recursively to find the field
  for (const section of careFlow.sections) {
    for (const subsection of section?.subsections) {
      for (const field of subsection?.fields) {
        if (fieldId === field.field_id) return field;
        if (field.subfields) {
          for (const subfield of field?.subfields) {
            if (fieldId === subfield?.field_id) return subfield;
            if (subfield.subfields) {
              for (const subsubfield of subfield?.subfields) {
                if (fieldId === subsubfield?.field_id) return subsubfield;
                if (subsubfield.subfields) {
                  for (const subsubsubfield of subsubfield?.subfields) {
                    if (fieldId === subsubsubfield.field_id)
                      return subsubsubfield;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // If found, return the value of that field, else return null
  return null;
};

const getElapsedTime = (time: DateTime) => {
  const elapsedStartedTotalDateTime: Duration = null;
  if (time) {
    const now = DateTime.now().toUTC();
    return now.diff(time, "seconds");
  }

  return elapsedStartedTotalDateTime;
};

const getTimeFormat = (duration: Duration) => {
  if (duration?.as("minutes") < 60) return "mm:ss";
  else return "h:mm:ss";
};

const isDisabled = (visit: VisitType) =>
  visit?.status !== VisitStatusEnum.IN_PROGRESS;

const toggleNotConnectedCareMessage = (
  dispatch: AppDispatch,
  enabled: boolean
) => {
  dispatch(
    setEnabledSections({
      section_id: CareFlowSubsectionsEnum.NOT_CONNECTED_CARE_MESSAGE,
      enabled: enabled
    })
  );
};

const tickElapsedTime = (
  dispatch: AppDispatch,
) => {
  return setInterval(() => {
    dispatch(addTime({ actual_time_seconds: 1}));
  }, 1000);
};

const safeRefetch = (
  refetch: () => void,
  isUnitialized: boolean,
  isSuccess: boolean,
  isError: boolean
) => {
  if (isUnitialized || !(isSuccess || isError)) return;
  try {
    refetch();
  } catch (error) {
    console.log("error during refetch: " + error);
  }
};

const getGoalAssessmentToString = ({
  input,
  goal_id,
  assessment
}: {
  input: string;
  goal_id: string;
  assessment: GoalAssessmentType;
}) => {
  const records = parseGoalAssessmentString({ input });

  records[goal_id] = assessment;
  let res = "";
  // Convert back to string
  Object.entries(records)?.map((item) => {
    const current_goal_id = item[0];
    const current_assessment = item[1];
    const str = `${current_goal_id}:::${current_assessment?.assessment_id}||${current_assessment?.assessment_status}||${current_assessment?.current_value}||${current_assessment?.notes}`;
    if (Object.entries(records).indexOf(item) === 0) {
      res = str;
    } else {
      res = `${res}$$${str}`;
    }
  });
  return res;
};

const getGoalAssessmentFromString = ({
  input,
  goal_id
}: {
  input: string;
  goal_id: string;
}) => {
  const records = parseGoalAssessmentString({ input });
  return records[goal_id];
};

export const parseGoalAssessmentString = ({
  input
}: {
  input: string;
}): { [goal_id: string]: GoalAssessmentType } => {
  const res = {};
  if (isFalsy(input)) return res;
  input.split("$$").forEach((item) => {
    // format should match getGoalAssessmentToString
    const record = item.split(":::") ?? [];
    if (record.length < 2) return;

    const goalId = record[0];
    const assessmentCSV = record[1].split("||");
    const assessment = {
      assessment_id: assessmentCSV[0],
      assessment_status: assessmentCSV[1],
      current_value: assessmentCSV[2],
      notes: assessmentCSV[3]
    };
    res[goalId] = assessment;
  });
  return res;
};

export {
  getField,
  getElapsedTime,
  tickElapsedTime,
  safeRefetch,
  toggleNotConnectedCareMessage,
  getTimeFormat,
  isDisabled,
  getGoalAssessmentToString,
  getGoalAssessmentFromString
};
