import ReduxTagEnum from "../enums/ReduxTagEnum";
import { apiPAB } from "./AxiosService";
import StaffLicensingResponse from "../types/StaffLicensing/StaffLicensingResponse";
import InsuranceLicensingObject from "../types/StaffLicensing/InsuranceLicensingObject";
import StaffLicensingRequest from "../types/StaffLicensing/StaffLicensingRequest";

export const DELAY_AFTER_REQUEST_COMPLETED = 3000;

const staffLicensingService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.StaffLicensing]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInsuranceLicensings: builder.query<
        InsuranceLicensingObject[],
        {
          id?: string;
        }
      >({
        query: () => {
          return {
            url: `/insurancelicensings`,
            method: "GET"
          };
        }
      }),
      getStaffEnrollments: builder.query<
        StaffLicensingResponse[],
        {
          id?: string;
        }
      >({
        query: () => {
          return {
            url: `/staff-licensing-enrollment`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => {
          return [{ type: ReduxTagEnum.StaffLicensing }];
        }
      }),
      // Mutations
      createStaffEnrollment: builder.mutation<
        StaffLicensingResponse,
        { body: StaffLicensingRequest }
      >({
        query: ({ body }) => ({
          url: `/staff-licensing-enrollment`,
          method: "POST",
          data: body
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            setTimeout(() => {
              dispatch(
                staffLicensingService.util.invalidateTags([
                  { type: ReduxTagEnum.StaffLicensing }
                ])
              );
            }, DELAY_AFTER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      editStaffEnrollment: builder.mutation<
        StaffLicensingResponse,
        { licensing_id: string; body: Partial<StaffLicensingRequest> }
      >({
        query: ({ licensing_id, body }) => ({
          url: `/staff-licensing-enrollment/${licensing_id}`,
          method: "PUT",
          // // insurance_licensing_id and staff_id are required fields by this endpoint
          data: body
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            setTimeout(() => {
              dispatch(
                staffLicensingService.util.invalidateTags([
                  { type: ReduxTagEnum.StaffLicensing }
                ])
              );
            }, DELAY_AFTER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      deleteStaffEnrollment: builder.mutation<
        StaffLicensingResponse,
        { licensing_id: string }
      >({
        query: ({ licensing_id }) => ({
          url: `/staff-licensing-enrollment/${licensing_id}`,
          method: "DELETE"
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            setTimeout(() => {
              dispatch(
                staffLicensingService.util.invalidateTags([
                  { type: ReduxTagEnum.StaffLicensing }
                ])
              );
            }, DELAY_AFTER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      })
    })
  });

export const {
  useGetInsuranceLicensingsQuery,
  useGetStaffEnrollmentsQuery,
  useCreateStaffEnrollmentMutation,
  useEditStaffEnrollmentMutation,
  useDeleteStaffEnrollmentMutation
} = staffLicensingService;
