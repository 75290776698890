enum CareFlowSectionsEnum {
  PRE_CALL = "PRE_CALL",
  CALL = "CALL",
  POST_CALL = "POST_CALL"
}

enum CareFlowSubsectionsEnum {
  NOT_CONNECTED_CARE_MESSAGE = "NOT_CONNECTED_CARE_MESSAGE",
  PRE_CALL_CLINICAL_TOPICS = "PRE_CALL_CLINICAL_TOPICS",
  PRE_CALL_REVIEW_READINGS = "REVIEW_READINGS",
}

export { CareFlowSectionsEnum, CareFlowSubsectionsEnum };
