import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import { TurqoiseButton } from "../../styling";
import ReportedCancellations from "../../pages/ReportedCancellations/ReportedCancellations";
import SurveyLinkedEntitiesEnum from "common/enums/SurveyLinkedEntitiesEnum";
import { DateTimeColumn, TextColumn } from "../Table/helpers/TableColumns";
import { getNameOrUsername, isFalsy } from "common/helpers/helpers";
import { getRoleLabel } from "common/enums/RolesEnum";
import { DefaultTableCell } from "../../styling/StyleComponents";
import { Typography } from "@mui/material";

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const CancellationRequestModal = ({ isOpen, onRequestClose }: IProps) => {
  const header = "Cancellation Request History";

  return (
    <StyledModal
      isOpen={isOpen}
      modalHeight="70vh"
      modalWidth="1232px"
      contentLabel={header}
      onRequestClose={onRequestClose}
    >
      <ModalHeader onRequestClose={onRequestClose}>{header}</ModalHeader>
      <ModalBody>
        <ReportedCancellations
          showHeader={false}
          linked_entities={[SurveyLinkedEntitiesEnum.SUBMITTER]}
          tableColumns={[
            DateTimeColumn({
              header: "Date",
              accessor: "patient_survey.created_at",
              id: "date",
              format: "sql"
            }),
            TextColumn({
              id: "reasonCategory",
              header: "Category",
              accessor: "category"
            }),
            TextColumn({
              id: "subCategory",
              header: "Sub Category",
              accessor: "subCategory"
            }),
            TextColumn({
              id: "notes",
              header: "Notes",
              accessor: "notes"
            }),

            {
              name: "cancellationRequestTeamMember",
              id: "cancellationRequestTeamMember",
              accessor: "submitter",
              func: "accessor",
              sortingFn: (a, b) => {
                const labelA = getNameOrUsername(a.original?.staff, false);
                const labelB = getNameOrUsername(b.original?.staff, false);
                return labelA?.localeCompare(labelB);
              },
              cell: ({ getValue, row }: any) => {
                const value = getValue();

                const name = getNameOrUsername(value, false);

                const roles = [];
                if (!isFalsy(value?.roles)) {
                  value?.roles?.forEach((role) => {
                    const roleLabel = getRoleLabel(role);

                    // MEMBER_CARE_SPECIALIST role on Adam Admin user account is causing a crash
                    if (!isFalsy(roleLabel)) {
                      roles.push(roleLabel);
                    }
                  });
                }

                const rolesString = roles?.slice(0, 3).join(", ");
                return (
                  <DefaultTableCell>
                    <Typography variant="body1" color="primary">
                      {name}
                    </Typography>
                    <Typography variant="body2" color="secondary">
                      {rolesString}
                    </Typography>
                  </DefaultTableCell>
                );
              },
              header: "Team Member"
            }
          ]}
        />
      </ModalBody>

      <ModalFooter>
        <ModalFooterButtons>
          <TurqoiseButton onClick={onRequestClose}>Close</TurqoiseButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export default CancellationRequestModal;
