import { styled, Typography } from "@mui/material";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { error, gray } from "common/styling/colors";
import { StyledDangerIcon } from "../../Tasks/helpers";

const TasksContainer = styled("div")`
    position: relative;
    padding: 10px;
    border-radius: 4px;
    background-color: ${error[600]};
    border: 1px solid ${gray[300]};
    overflow: hidden;
`;

export const MissingConsentsBanner = () => {
  return (
    <TasksContainer>
      <Flexbox flexDirection={"row"} justifyContent={"center"} sx={{padding: "8px"}}>
        <Typography variant="h6" color={"white"}>
          Member is missing consents. We cannot provide service to them until
          they fill out consents.
        </Typography>
      </Flexbox>
    </TasksContainer>
  );
};
