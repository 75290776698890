import { styled, Typography } from "@mui/material";
import { warning } from "common/styling/colors";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import VisitContentField from "../VisitContentField";
import MemberType from "common/types/MemberType";
import { Flexbox } from "../../../../styling/NewStyleComponents";

export const FocusAreaContainer = styled(Flexbox)`
  display: flex;
  background-color: ${warning[200]};
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
`;

export const FocusArea = ({
  field,
  member
}: {
  field: CareFlowFieldType;
  member: MemberType;
}) => {
  const text =
    field?.placeholder?.length > 0 ? field?.placeholder : field?.value;

  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
      <FocusAreaContainer flexDirection={"column"}>
        <Typography variant="h6">💡  Focus Area</Typography>

        <Typography variant="body1" sx={{ margin: "10px 20px" }}>
          {text}
        </Typography>
      </FocusAreaContainer>
      {field?.subfields?.map((item) => {
        return (
          <Flexbox
            key={item.field_id}
            flexDirection={"column"}
            style={{ margin: "10px 40px" }}
          >
            <VisitContentField field={item} member={member} />
          </Flexbox>
        );
      })}
    </div>
  );
};
