import { useSelector } from "react-redux";

import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import VisitType from "common/types/Visits/VisitType";
import { setAnswer } from "common/redux/VisitsSlice";
import { RootState, useAppDispatch } from "common/redux";

import { DateTime } from "luxon";
import { useMemo } from "react";
import DateTimePicker from "../../../components/DateTimePicker";
import { isDisabled } from "../VisitHelper";

interface IProps {
  field: CareFlowFieldType;
  visit: VisitType;
}

const DATEFORMAT = "MMM dd, yyyy hh:mm a ZZZZ";

const VisitFieldDateTime = ({ field, visit }: IProps) => {
  const dispatch = useAppDispatch();
  const { answers } = useSelector((state: RootState) => state.visits);

  const onKeyDown = (e) => {
    if (e.key === "Delete") {
      // TBD ENG-5032 fix this case when pressing fn + delete, these all don't work :(
      e.target = null;
      e.preventDefault();
      e.nativeEvent.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    e.preventDefault();
  };

  const startDate = useMemo(() => {
    if (answers[field.field_id] === undefined) return undefined;
    return DateTime.fromFormat(answers[field.field_id] as string, DATEFORMAT);
  }, []);

  const disabled = isDisabled(visit);

  return (
    <DateTimePicker
      sx={{ marginTop: 0 }}
      label={field.label}
      value={startDate}
      disabled={disabled}
      onChange={(newDate: DateTime) => {
        if (newDate?.isValid) {
          dispatch(
            setAnswer({
              id: field.field_id,
              value: newDate.toFormat(DATEFORMAT)
            })
          );
        }
      }}
      slotProps={{
        field: {
          readOnly: true
        },
        textField: { onKeyDown }
      }}
    />
  );
};

export default VisitFieldDateTime;
