import { MouseEvent } from "react";
import { Button } from "@mui/material";

export const ContainedIconButton = ({
  icon,
  onClick = () => {},
  color = "primary",
  disabled = false
}: {
  icon: React.ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  disabled?: boolean;
}) => {
  return (
    <Button
      variant="contained"
      color={color}
      sx={{
        minWidth: "24px",
        padding: "6px 10px",
        ".MuiButton-startIcon": { margin: "0px" }
      }}
      disabled={disabled}
      onClick={onClick}
      startIcon={icon}
    />
  );
};
