enum MemberLinkedEntitiesEnum {
  NURSE = "nurse",
  PROVIDER = "provider",
  NPS = "nps",
  SUBMITTER = "submitter",
  PROVIDER_METADATA = "provider_metadata",
  PATIENT_INACTIVITY = "patient_inactivity",
  RETENTION_ACTIONS = "retention_actions",
  LATEST_ATTRITION_REASONS = "latest_attrition_reasons",
  ACCEPTED_CONSENTS = "accepted_consents",
  MISSING_LEGAL_FORMS = "missing_legal_forms", // Only consents
  MISSING_LEGAL_FORMS_FULL = "missing_legal_forms_full", // Consents + Attestations
  ACCEPTED_LEGAL_FORMS = "accepted_legal_forms",
  EXPIRING_CONSENTS = "expiring_consents",
  PATIENT_ACCESS_SUMMARY = "patient_access_summary",
  LATEST_ENCOUNTER = "latest_encounter",
  METADATA = "metadata",
  RELATIONSHIP_NOTES = "relationship_notes",
  GOALS = "goals",
  CARE_PLAN = "care_plan",
  CARE_SUMMARY = "care_summary"
}

export default MemberLinkedEntitiesEnum;
