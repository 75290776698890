import MemberType from "common/types/MemberType";
import { Flexbox } from "../../../../../styling/NewStyleComponents";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { DateTime } from "luxon";
import {
  AddIcCall,
  CallEnd,
  Home,
  KeyboardArrowDown,
  Mic,
  MicOff,
  Phone,
  PhoneAndroid
} from "@mui/icons-material";
import { ContainedIconButton } from "../../../../../components/Button/ContainedIconButton";
import { useEffect, useState } from "react";
import { Call, Device } from "@twilio/voice-sdk";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "common/redux";
import { isFalsy, isTruthy, maskPhoneNumber } from "common/helpers/helpers";
import {
  useAssignOutreachNumberMutation,
  useCreateVOIPTokenMutation
} from "common/services/VoiceService";
import {
  connectVoiceCall,
  disconnectVoiceCall
} from "common/redux/VoiceCallSlice";
import {
  getElapsedTime,
  getTimeFormat,
  isDisabled
} from "../../../VisitHelper";
import { useGetUserMetadataQuery } from "common/services/UserService";
import { useInterval } from "common/hooks/useInterval";

const CallMenuItem = ({
  phoneNumber,
  handleCall,
  label,
  icon
}: {
  phoneNumber: string;
  handleCall: (phoneNumber) => void;
  label: string;
  icon: React.ReactNode;
}) => {
  return (
    <MenuItem onClick={() => handleCall(phoneNumber)} disableRipple>
      <Flexbox flexDirection={"row"} alignItems={"center"} gap={"5px"}>
        {icon}
        <Typography variant={"caption"} color={"primary"}>
          {`${label}: ${maskPhoneNumber(phoneNumber)}`}
        </Typography>
      </Flexbox>
    </MenuItem>
  );
};

const DELAY_BETWEEN_CALLS = 4000;

export const VoiceCall = ({
  member,
  isInBanner = false
}: {
  member: MemberType;
  isInBanner?: boolean;
}) => {
  const [connection, setConnection] = useState<Call>(null);
  const [isMute, setIsMute] = useState<boolean>(false);
  const [statusText, setStatusText] = useState<string>(null);
  const [callStart, setCallStart] = useState<DateTime>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { start: cachedStart, connection: cachedConnection } = useSelector(
    (state: RootState) => state.voiceCall
  );
  const { visit } = useSelector((state: RootState) => state.visits);

  const disabled = isDisabled(visit);

  const { data: userMetadata } = useGetUserMetadataQuery(
    { staff_id: user?.user_id },
    { skip: isFalsy(user) }
  );

  const isDisconnected = connection?.status() === "closed";

  useEffect(() => {
    if (isTruthy(cachedConnection)) setConnection(cachedConnection);
  }, [cachedConnection]);

  useEffect(() => {
    if (isTruthy(cachedStart)) setCallStart(cachedStart);
  }, [cachedStart]);

  useInterval(() => {
    if (isFalsy(callStart)) return;
    if (isDisconnected) setStatusText("Disconnected");
    else {
      const time = getElapsedTime(callStart);
      setStatusText(time.toFormat(getTimeFormat(time)));
    }
  }, 1000);

  useEffect(() => {
    // When connection closes, show the call in closed state for a few seconds,
    // then show the Call button again
    if (isDisconnected) {
      setTimeout(() => setConnection(null), DELAY_BETWEEN_CALLS);
    }
  }, [connection?.status()]);

  useEffect(() => {
    if (
      isTruthy(userMetadata?.user_metadata?.allow_riq_voice_calls) &&
      isFalsy(voipToken)
    ) {
      createVOIPToken({ staff_id: user?.user_id });
    }
  }, [userMetadata]);

  const [createVOIPToken, { data: voipToken }] = useCreateVOIPTokenMutation();

  const dispatch = useAppDispatch();

  const handleEndCall = async () => {
    connection.disconnect();
    dispatch(disconnectVoiceCall());
  };

  const handleToggleMute = async () => {
    connection.mute(!isMute);
    setIsMute(!isMute);
  };

  const handleCall = async (number: string) => {
    try {
      handleClose();
      setCallStart(DateTime.now());
      const device = new Device(voipToken.access_token);
      // Device must be registered in order to receive incoming calls
      device.register();

      const params = {
        To: `+1${number}`,
        From: `+1${member?.metadata?.contact_settings?.outreach_number}`,
        VisitId: visit?.visit_id,
        StaffId: user?.user_id,
        MemberId: member?.patient?.patient_id
      };

      if (device) {
        const conn = await device.connect({ params });
        setConnection(conn);
        dispatch(
          connectVoiceCall({ visitId: visit?.visit_id, connection: conn })
        );
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const hasOutreachNumber = isTruthy(
    member?.metadata?.contact_settings?.outreach_number
  );

  const isInVisit = isTruthy(visit);

  const hasMemberPhoneNumber =
    isTruthy(member?.patient?.phone) || isTruthy(member?.patient?.mobile);

  const [assignOutreachNumber, { isLoading }] =
    useAssignOutreachNumberMutation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Flexbox flexDirection={"row"} gap={"5px"}>
      {!hasOutreachNumber && (
        <Button
          variant="contained"
          color={"info"}
          onClick={async () => {
            assignOutreachNumber({ patient_id: member?.patient?.patient_id });
          }}
          startIcon={<AddIcCall />}
          disabled={!isInVisit || isLoading}
        >
          Setup
        </Button>
      )}

      {hasOutreachNumber &&
        (isFalsy(connection) ? (
          <>
            <Button
              variant="contained"
              color={"info"}
              onClick={handleClick}
              startIcon={<Phone />}
              endIcon={<KeyboardArrowDown />}
              disabled={!isInVisit || !hasMemberPhoneNumber || disabled}
            >
              Call
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {isTruthy(member?.patient?.phone) && (
                <CallMenuItem
                  handleCall={handleCall}
                  phoneNumber={member?.patient?.phone}
                  label={"Home"}
                  icon={<Home color={"primary"} />}
                />
              )}
              {isTruthy(member?.patient?.mobile) && (
                <CallMenuItem
                  handleCall={handleCall}
                  phoneNumber={member?.patient?.mobile}
                  label={"Mobile"}
                  icon={<PhoneAndroid color={"primary"} />}
                />
              )}
            </Menu>
          </>
        ) : (
          <>
            <Button
              variant={isInBanner ? "contained" : "outlined"}
              onClick={() => {}}
              startIcon={isDisconnected ? <CallEnd /> : <Phone />}
              sx={{ border: 0, cursor: "auto", boxShadow: 0 }}
              disabled={false}
            >
              {statusText}
            </Button>
            <ContainedIconButton
              icon={connection?.isMuted() ? <MicOff /> : <Mic />}
              color={connection?.isMuted() ? "inherit" : "info"}
              onClick={async () => {
                await handleToggleMute();
              }}
            />
            <ContainedIconButton
              icon={<CallEnd />}
              color={"error"}
              onClick={async () => {
                await handleEndCall();
              }}
            />
          </>
        ))}
    </Flexbox>
  );
};
