import React, { useEffect, useMemo, lazy, Suspense, ReactElement } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";
import { DateTime } from "luxon";

import { RootState, useAppDispatch } from "common/redux";
import { SentryHelper_captureException } from "common/helpers/SentryHelper";
import { fetchSocketUrl, logOut, onAppStart } from "common/redux/AuthSlice";
import { CURRENT_TAB_ID } from "common/redux/VideoCallSlice";

import Layout from "../components/Layout";
import LoginPage from "../pages/Login/LoginPage";
import AthenaRouter from "../pages/AthenaRouter/AthenaRouter";
import NotFound from "../pages/NotFound";

import { EmotionTheme } from "./../styling";
import ReduxModal from "../components/Modal/ReduxModal";
import { parseJSON } from "../helpers/helpers";

import LoadingPage from "../pages/LoadingPage";
import { DelayedRender } from "common/helpers/components/DelayedRender";
import { setSelectedMemberId } from "common/redux/webadmin/LayoutSlice";
import { usePostHeartbeatQuery } from "common/services/UserPresenceService";

import RETENTION_SPECIALIST from "./roles/RETENTION_SPECIALIST";
import THN_MANAGER from "./roles/THN_MANAGER";
import NPN_MANAGER from "./roles/NPN_MANAGER";
import TH_NURSE from "./roles/TH_NURSE";
import NP_NURSE from "./roles/NP_NURSE";
import MD_PROVIDER from "./roles/MD_PROVIDER";
import NURSE_PROVIDER from "./roles/NURSE_PROVIDER";
import PROVIDER_MANAGER from "./roles/PROVIDER_MANAGER";
import NURSE_DIRECTOR from "./roles/NURSE_DIRECTOR";
import NPS from "./roles/NPS";
import NPS_MANAGER from "./roles/NPS_MANAGER";
import SALES_DIRECTOR from "./roles/SALES_DIRECTOR";
import ADMIN from "./roles/ADMIN";
import RCM_ADMIN from "./roles/RCM_ADMIN";
import TECHNICAL_SUPPORT from "./roles/TECHNICAL_SUPPORT";
import MEMBER_CARE_SPECIALIST from "./roles/MEMBER_CARE_SPECIALIST";
import ErrorType from "common/types/ErrorType";
import { useFeatureFlags } from "common/config/FeatureFlags";

export const ROUTES_BY_ROLE = {
  RETENTION_SPECIALIST,
  THN_MANAGER,
  NPN_MANAGER,
  TH_NURSE,
  NP_NURSE,
  MD_PROVIDER,
  NURSE_PROVIDER,
  PROVIDER_MANAGER,
  NURSE_DIRECTOR,
  NPS,
  NPS_MANAGER,
  SALES_DIRECTOR,
  ADMIN,
  RCM_ADMIN,
  TECHNICAL_SUPPORT,
  MEMBER_CARE_SPECIALIST
};

const VideoCallComponent = lazy(
  () => import("../components/Twilio/VideoCallComponent")
);

const HEARTBEAT_INTERVAL =
  // 3 minutes
  1000 * 60 * 3;

const ErrorFallback = ({
  error,
  resetErrorBoundary
}: {
  error: ErrorType;
  resetErrorBoundary: () => void;
}) => {
  const errorMessage = "message" in error && error.message;
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{errorMessage}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const ErrorComponent = ({ children }: { children: ReactElement }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={(error) => {
        SentryHelper_captureException(error);
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

const RouterTheme = () => {
  const dispatch = useAppDispatch();
  const { isLoggedIn, isRefreshingToken, currentRole } = useSelector(
    (state: RootState) => state.auth
  );

  const { code, tabId } = useSelector((state: RootState) => state.videoCall);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const featureFlags = useFeatureFlags();

  usePostHeartbeatQuery(null, {
    skip: !isLoggedIn || !featureFlags.USER_PRESENCE_MANAGEMENT,
    pollingInterval: HEARTBEAT_INTERVAL
  });

  useEffect(() => {
    if (pathname.includes("patient")) {
      const redirectUri = pathname.replaceAll("patient", "member");
      navigate(redirectUri);
    }
  }, []);

  useEffect(() => {
    // Reset selected member id and timer when navigating away from member page or download member chart
    if (
      !(
        pathname.includes("members/memberId") ||
        pathname.includes("member_chart_share/memberId")
      )
    ) {
      dispatch(setSelectedMemberId(undefined));
    }
  }, [pathname]);

  const tabOpenDate = useMemo(() => DateTime.now(), []);

  function logoutListener(event: StorageEvent) {
    const parsed = parseJSON(event.newValue);
    if (parsed?.auth) {
      const parsedAuth = parseJSON(parsed?.auth);
      if (parsedAuth.isLoggedIn === false) {
        // logout all other tabs
        dispatch(logOut(false));
      }
    }
  }

  useEffect(() => {
    // Creates listener for storage change events from other tabs.
    // Logout triggered from another tab
    if (isLoggedIn) {
      window.addEventListener("storage", logoutListener);
    }

    return () => {
      window.removeEventListener("storage", logoutListener);
    };
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(onAppStart());
  }, []);

  useEffect(() => {
    if (isLoggedIn) dispatch(fetchSocketUrl());
  }, [isLoggedIn]);

  const hideSideBar = isLoggedIn && pathname.includes("/video");

  const loadVideoCallComponents =
    isLoggedIn && code != null && tabId === CURRENT_TAB_ID;

  // This prevents the loading to display if the user is already using the app.
  // Loading should show only when the user opens the tab, not after.
  if (tabOpenDate > DateTime.now().minus({ seconds: 2 }) && isRefreshingToken)
    return (
      <DelayedRender delay={500}>
        <LoadingPage />
      </DelayedRender>
    );

  return (
    <EmotionTheme>
      <Layout hideSidebar={hideSideBar}>
        <ErrorComponent>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/athenarouter/:athenaId" element={<AthenaRouter />} />
            {isLoggedIn &&
              ROUTES_BY_ROLE[currentRole].routes.map(({ path, components }) => {
                return (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <React.Fragment key={path}>{components}</React.Fragment>
                    }
                  />
                );
              })}

            {isLoggedIn ? (
              <Route path="*" element={<NotFound />} />
            ) : (
              <Route path="*" element={<LoginPage to="/login" />} />
            )}
          </Routes>
        </ErrorComponent>
      </Layout>
      {loadVideoCallComponents && (
        <Suspense fallback={<LoadingPage />}>
          <VideoCallComponent />
        </Suspense>
      )}

      <ReduxModal />
    </EmotionTheme>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <RouterTheme />
    </BrowserRouter>
  );
};

export default Router;
