import styled from "@emotion/styled";
import { DateTime } from "luxon";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import MemberDetailsHeader from "../Header/MemberDetailsHeader";
import MemberDetailsLayout from "../MemberDetailsLayout";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { gray } from "common/styling/colors";
import { Typography } from "@mui/material";
import { Suspense, lazy, useState } from "react";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import { checkIdValid, formatName } from "common/helpers/helpers";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { useGetReadingData } from "../../../helpers/components/Charts/ChartHelpers";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import { Column } from "../../../styling/StyleComponents";
import MemberType from "common/types/MemberType";

const BloodPressureChart = lazy(() => import("./BloodPressureChart"));
const PulseChart = lazy(() => import("./PulseChart"));
const GlucoseChart = lazy(() => import("./GlucoseChart"));
const WeightScaleChart = lazy(() => import("./WeightScaleChart"));
const OximeterChart = lazy(() => import("./OximeterChart"));

const DATE_FORMAT = "MM/dd/yyyy";

const MemberDetailsReadingsContainer = styled.div`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const BiomarkerCard = styled.div`
  border-radius: 4px;
  border: 1px solid ${gray[300]};
  background: white;
  padding: 16px;
  flex: 1 1 auto;
  align-items: center;
`;

interface CardProps {
  title: string;
  value?: string;
  date?: DateTime;
}

const RenderCard = ({ title, value, date }: CardProps) => {
  return (
    <BiomarkerCard>
      <Flexbox
        justifyContent="space-between"
        alignItems={"center"}
        height={"100%"}
      >
        <Typography variant="h4">{title}</Typography>
        <Column alignItems={"center"}>
          <Typography variant="h4">{value}</Typography>
          {date && (
            <Typography variant="body1">
              {date?.toFormat(DATE_FORMAT)}
            </Typography>
          )}
        </Column>
      </Flexbox>
    </BiomarkerCard>
  );
};

function BiomarkerCards({
  readingType,
  patient,
  dateFilter
}: {
  readingType: DeviceTrendParam;
  patient: MemberType;
  dateFilter: number;
}) {
  const readingData = useGetReadingData(readingType, patient, dateFilter);
  return (
    <Flexbox flexDirection="column" gap="8px">
      <Typography variant="h6" color="textSecondary">
        {readingData?.text}
      </Typography>
      {readingData?.loading ? (
        <LoadingFallback count={3} />
      ) : (
        <Flexbox gap="8px" justifyContent="space-between">
          <RenderCard
            title="Highest"
            value={readingData?.highest}
            date={readingData?.highestDate}
          />
          <RenderCard
            title="Lowest"
            value={readingData?.lowest}
            date={readingData?.lowestDate}
          />

          <RenderCard
            title="Latest"
            value={readingData?.latest}
            date={readingData?.latestDate}
          />

          <RenderCard title="Average" value={readingData?.average} />
        </Flexbox>
      )}
    </Flexbox>
  );
}

const MemberDetailsReadings = () => {
  const params = useSanitizedParams();

  const { memberId, readingType } = params;
  const isValidId = checkIdValid(memberId);

  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities: [
        MemberLinkedEntitiesEnum.NURSE,
        MemberLinkedEntitiesEnum.PROVIDER,
        MemberLinkedEntitiesEnum.PROVIDER_METADATA,
        MemberLinkedEntitiesEnum.PATIENT_ACCESS_SUMMARY
      ]
    },
    { skip: memberId === undefined || isValidId === false }
  );

  const [showAverages, setShowAverages] = useState<boolean>(true);
  const [dateFilter, setDateFilter] = useState<number>(30);
  const [deviceIds, setDeviceIds] = useState<string[]>();

  const readingData = useGetReadingData(
    readingType as DeviceTrendParam,
    patient,
    dateFilter
  );

  const handleDataChange = ({
    dateFilter,
    showAverages,
    selectedDeviceIds: newSelectedDeviceIds
  }) => {
    setDateFilter(dateFilter);
    setShowAverages(showAverages);
    if (JSON.stringify(deviceIds) !== JSON.stringify(newSelectedDeviceIds))
      setDeviceIds(newSelectedDeviceIds);
  };

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return (
    <MemberDetailsReadingsContainer
      key={`${memberId}-${readingType}-readings-container`}
    >
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="hidden"
      >
        <MemberDetailsHeader key={`${memberId}-header-readings`} />

        {patient && (
          <MemberDetailsLayout
            key={`${memberId}-layout-readings`}
            memberId={memberId}
            // if the member details readings component is rendered, we won't be hiding readings
            hideReadings={false}
          >
            <div>
              <Flexbox flexDirection="column" gap="24px">
                <Typography variant="h4" color="textPrimary">
                  {formatName(readingType)}
                </Typography>
                {readingData && (
                  <BiomarkerCards
                    readingType={readingType as DeviceTrendParam}
                    patient={patient}
                    dateFilter={dateFilter}
                  />
                )}
                <div>
                  <div>
                    <Suspense fallback={<LoadingFallback delay={50} />}>
                      {readingType === DeviceTrendParam.BLOOD_PRESSURE && (
                        <BloodPressureChart
                          patient={patient}
                          dateFilter={dateFilter}
                          showAverages={showAverages}
                          deviceIds={deviceIds}
                          handleDataChange={handleDataChange}
                        />
                      )}
                      {readingType === DeviceTrendParam.GLUCOSE && (
                        <GlucoseChart
                          patient={patient}
                          dateFilter={dateFilter}
                          showAverages={showAverages}
                          deviceIds={deviceIds}
                          handleDataChange={handleDataChange}
                        />
                      )}
                      {readingType === DeviceTrendParam.PULSE && (
                        <PulseChart
                          patient={patient}
                          dateFilter={dateFilter}
                          showAverages={showAverages}
                          deviceIds={deviceIds}
                          handleDataChange={handleDataChange}
                        />
                      )}
                      {readingType === DeviceTrendParam.OXIMETER && (
                        <OximeterChart
                          patient={patient}
                          dateFilter={dateFilter}
                          showAverages={showAverages}
                          deviceIds={deviceIds}
                          handleDataChange={handleDataChange}
                        />
                      )}
                      {readingType === DeviceTrendParam.WEIGHT_SCALE && (
                        <WeightScaleChart
                          patient={patient}
                          dateFilter={dateFilter}
                          showAverages={showAverages}
                          deviceIds={deviceIds}
                          handleDataChange={handleDataChange}
                        />
                      )}
                    </Suspense>
                  </div>
                </div>
              </Flexbox>
            </div>
          </MemberDetailsLayout>
        )}
      </Flexbox>
    </MemberDetailsReadingsContainer>
  );
};

export default MemberDetailsReadings;
