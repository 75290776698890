import { useFormik } from "formik";
import styled from "@emotion/styled";

import {
  EMAIL_VALIDATION_REGEXP,
  unmaskPhoneNumber,
  PASSWORD_VALIDATION_REGEXP,
  PHONE_VALIDATION_REGEXP,
  validateFieldLength
} from "common/helpers/helpers";
import timezones from "common/config/timezones.json";
import { TurqoiseButton } from "../../styling";
import { SuccessText } from "../../styling/StyleComponents";
import { useCreateUserMutation } from "common/services/UserService";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import ErrorComponent from "../../components/ErrorComponent";
import RolesEnum, {
  createUserFormRoles,
  getRoleLabel
} from "common/enums/RolesEnum";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { MenuItem, TextField, Typography } from "@mui/material";
import PhoneInputField from "../../components/Input/PhoneInputField";
import PasswordInput from "../../components/Input/PasswordInput";

interface FormType {
  email: string;
  role?: RolesEnum | "";
  password: string;
  first: string;
  last: string;
  phone: string;
  mobile: string;
  timezone: string;
}

interface ErrorType extends Omit<FormType, "role"> {
  role: string;
}

const CreateUserFormContainer = styled.div`
  padding: 5%;
  width: 85%;
  background: ${(props) => props.theme.color.white};
  /* Stroke */

  border: 1px solid ${(props) => props.theme.color.defaultBorder};
  border-radius: 4px;
  margin: 2.5%;
`;

const SubmitButton = styled(TurqoiseButton)`
  margin-top: 38px;
`;

const SuccessContainer = styled.div`
  transition: height 0.66s ease-out;
  min-height: 28px;
`;

const CreateUserForm = () => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [
    createUserMutation,
    { data: createUserResponse, isError, error, isLoading }
  ] = useCreateUserMutation();

  const validate = (values: FormType) => {
    const errors: Partial<ErrorType> = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (!EMAIL_VALIDATION_REGEXP.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (!PASSWORD_VALIDATION_REGEXP.test(values.password)) {
      errors.password =
        "Password must contain at least 12 characters, one uppercase, one number and one special case character";
    }
    if (!values.role) {
      errors.role = "Required";
    }

    if (
      values["phone"] &&
      !PHONE_VALIDATION_REGEXP.test(unmaskPhoneNumber(values?.["phone"]))
    ) {
      errors["phone"] = "Please enter a valid 10-digit phone number";
    }

    if (
      values["mobile"] &&
      !PHONE_VALIDATION_REGEXP.test(unmaskPhoneNumber(values?.["mobile"]))
    ) {
      errors["mobile"] = "Please enter a valid 10-digit phone number";
    }

    if (values["email"] && !EMAIL_VALIDATION_REGEXP.test(values["email"])) {
      errors["email"] = "Invalid email address";
    }

    if (values["first"] && !validateFieldLength(2, 50, values?.["first"])) {
      errors["first"] = "Please enter a value between 2 and 50 characters.";
    }

    if (values["last"] && !validateFieldLength(2, 50, values?.["last"])) {
      errors["last"] = "Please enter a value between 2 and 50 characters.";
    }

    if (
      timezones.find((timezone) => {
        return timezone.value == values["timezone"];
      }) === undefined
    ) {
      errors["timezone"] = "Please select a valid timezone.";
    }

    return errors;
  };

  const onSubmit = async (values: FormType) => {
    const body = {
      ...values,
      phone: unmaskPhoneNumber(values.phone),
      mobile: unmaskPhoneNumber(values.mobile),
      status: MemberStatusEnum.ACTIVE,
      roles: [values.role as RolesEnum]
    };

    delete body?.role;

    await createUserMutation(body);
  };
  const formik = useFormik<FormType>({
    initialValues: {
      email: "",
      password: "",
      role: "",
      first: "",
      last: "",
      phone: "",
      mobile: "",
      timezone: ""
    },
    onSubmit,
    validate
  });

  const roles = createUserFormRoles(currentRole)
    ?.map((role) => {
      return {
        role,
        label: getRoleLabel(role as RolesEnum)
      };
    })
    ?.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

  const setFieldValue = (key, value) => {
    formik.setFieldValue(key, value).catch((error) => {});
  };

  return (
    <CreateUserFormContainer>
      <Typography variant="h1" color="text.primary">
        Create New User Account
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <br />
        <TextField
          value={formik.values.email}
          onChange={(e) => {
            setFieldValue("email", e.target.value);
          }}
          label="Email"
          data-testid="email"
          fullWidth
          placeholder={"Enter email"}
          error={formik.errors["email"] !== undefined}
          helperText={formik.errors["email"]}
        />

        <br />
        <br />
        <TextField
          fullWidth
          onChange={(event) => {
            setFieldValue("role", event.target.value);
          }}
          select
          value={formik.values.role}
          type="email"
          autoComplete="email"
          label="Role"
          data-testid="role"
        >
          {roles.map((item) => {
            return (
              <MenuItem
                key={item.role}
                value={item.role}
                data-testid={item.role}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </TextField>

        <br />
        <br />
        <PasswordInput
          value={formik.values.password}
          onChange={(e) => {
            setFieldValue("password", e.target.value);
          }}
          data-testid="password"
          label="Password"
          fullWidth
          placeholder={"Enter password"}
          error={formik.errors["password"] !== undefined}
          helperText={formik.errors["password"]}
        />

        <br />
        <br />
        <TextField
          value={formik.values.first}
          data-testid="firstName"
          onChange={(e) => {
            setFieldValue("first", e.target.value);
          }}
          label="First Name"
          fullWidth
          placeholder={"Enter first name"}
          error={formik.errors["first"] !== undefined}
          helperText={formik.errors["first"]}
          autoComplete="given-name"
        />

        <br />
        <br />
        <TextField
          value={formik.values.last}
          onChange={(e) => {
            setFieldValue("last", e.target.value);
          }}
          label="Last Name"
          data-testid="lastName"
          fullWidth
          placeholder={"Enter last name"}
          error={formik.errors["last"] !== undefined}
          helperText={formik.errors["last"]}
          autoComplete="family-name"
        />
        <br />
        <br />
        <PhoneInputField
          value={formik.values.phone}
          data-testid="phone"
          label={"Phone number"}
          fullWidth
          onChange={(event) => setFieldValue("phone", event.target.value)}
          placeholder={"Enter phone number"}
        />
        <br />
        <br />
        <PhoneInputField
          value={formik.values.mobile}
          data-testid="mobile"
          label={"Mobile Number"}
          fullWidth
          onChange={(event) => setFieldValue("mobile", event.target.value)}
          placeholder={"Enter mobile number"}
        />
        <br />
        <br />
        <TextField
          fullWidth
          onChange={(event) => {
            setFieldValue("timezone", event.target.value);
          }}
          data-testid="timezone"
          select
          value={formik.values.timezone}
          type="email"
          autoComplete="email"
          label="Timezone"
        >
          {timezones.map(({ label, value }) => {
            return (
              <MenuItem key={value} value={value} data-testid={value}>
                {label}
              </MenuItem>
            );
          })}
        </TextField>

        <br />
        <SubmitButton
          type="submit"
          loading={isLoading}
          disabled={!formik.isValid || !formik.dirty}
        >
          Create Account
        </SubmitButton>
        <SuccessContainer>
          {createUserResponse && (
            <SuccessText
              margin={"12px 0 0 0"}
            >{`User with username: ${createUserResponse.username} successfully created.`}</SuccessText>
          )}
        </SuccessContainer>
      </form>
      {isError && (
        <>
          <br />
          <ErrorComponent error={error} />
        </>
      )}
    </CreateUserFormContainer>
  );
};

export default CreateUserForm;
