import { DateTime } from "luxon";
import { isFalsy } from "common/helpers/helpers";
import {
  useGetGoalsQuery,
  useUpsertGoalAssessmentsMutation,
  useUpsertGoalsMutation
} from "common/services/MemberGoalsRelationshipService";
import ErrorComponent from "../../../components/ErrorComponent";
import {
  Box,
  Button,
  Container,
  MenuItem,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { blue, gray } from "common/styling/colors";
import { Circle, Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import AddGoalModal from "../../../components/Goals/AddGoalModal";
import {
  getGoalTitleByCategory,
  getPrettyGoalAssessmentStatus,
  GoalAssessmentStatusEnum,
  GoalAssessmentType,
  GoalStatusEnum,
  GoalType,
  GoalTypeEnum,
  ProcessedGoalsType,
  processGoals
} from "common/types/GoalType";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { Flexbox } from "../../../styling/NewStyleComponents";
import Table from "../../../components/Table/Table";
import StyledIconButton from "../../../components/Button/StyledIconButton";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { dispatch } from "common/redux";
import MemberType from "common/types/MemberType";
import { hasDiabetes, hasHypertension } from "common/types/Visits/CarePlanType";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import VisitContentField from "./VisitContentField";
import CareFlowFieldTypeEnum from "common/enums/CareFlowFieldTypeEnum";
import { FallbackText } from "../../../components/Table/helpers/TableHelpers";
import { DefaultTableCell } from "../../../styling/StyleComponents";
import { dateWithRelative } from "../../../components/Table/helpers/TableCells";

const GridContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Row = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface IProps {
  memberId: string;
  goalOnly?: boolean; // Whether we only want to show the goal and not the "progress to goal" tab
  clinicalOnly?: boolean; // Whether the control should only show clinical goals
  condensed?: boolean; // Whether to show the title (Goals) and description (SMART Goals)
}

interface FormType {
  assessment_status?: GoalAssessmentStatusEnum;
  current_value?: string;
  notes?: string;
}

const ASSESMENT_VALUES = Object.keys(GoalAssessmentStatusEnum);

const COLUMNS = [
  {
    header: "Status",
    id: "goalAssessmentAssessmentStatus",
    name: "goalAssessmentAssessmentStatus",
    accessor: "assessment_status",
    func: "accessor",
    size: 100,
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <DefaultTableCell>
          {getPrettyGoalAssessmentStatus(value)}
        </DefaultTableCell>
      );
    }
  },
  {
    header: "Notes",
    name: "goalAssessmentNotes",
    id: "goalAssessmentNotes",
    accessor: "notes",
    func: "accessor",
    size: 100,
    cell: ({ getValue }) => {
      const value = getValue();
      return <DefaultTableCell>{value}</DefaultTableCell>;
    }
  },
  {
    header: "Notes",
    name: "goalAssessmentNotes",
    id: "goalAssessmentNotes",
    accessor: "notes",
    func: "accessor",
    size: 300,
    cell: ({ getValue }) => {
      const value = getValue();
      return <DefaultTableCell>{value}</DefaultTableCell>;
    }
  },
  {
    header: "Assessed On",
    name: "goalAssessmentAssessedOn",
    id: "goalAssessmentAssessedOn",
    accessor: "assessed_on",
    func: "accessor",
    sortingFn: "dateTimeSortingSQL",
    size: 100,
    cell: ({ getValue }) => {
      const value = getValue();
      let date = DateTime.fromSQL(value);
      if (date?.isValid === false) date = DateTime.fromISO(value);

      return <DefaultTableCell>{dateWithRelative({ date })}</DefaultTableCell>;
    },
    enableColumnFilter: true
  }
];

const RenderGoalProgress = ({
  latest_assessments
}: {
  latest_assessments: GoalAssessmentType[];
}) => {
  return (
    <>
      {!latest_assessments ||
        (latest_assessments?.length === 0 && (
          <Flexbox alignItems={"center"} width={"100%"}>
            <FallbackText text={`No progress records for this goal found`} />
          </Flexbox>
        ))}
      {latest_assessments && latest_assessments?.length > 0 && (
        <Flexbox
          gap="10px"
          flexDirection="column"
          width={"100%"}
          marginTop={"10px"}
        >
          <Table
            tableColumns={COLUMNS}
            data={latest_assessments}
            showTableCount={false}
          />
          <Typography variant={"caption"} textAlign={"right"}>
            Only the 5 latest progress records will be shown.
          </Typography>
        </Flexbox>
      )}
    </>
  );
};

export const RenderGoal = ({
  goal,
  memberId,
  goalOnly = false,
  onEditPressed = () => {}
}: {
  goal: GoalType;
  memberId: string;
  goalOnly: boolean;
  onEditPressed?: (goal: GoalType) => void;
}) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const [upsertMutation, { isLoading, isSuccess }] =
    useUpsertGoalAssessmentsMutation();
  const [upsertGoalsMutation] = useUpsertGoalsMutation();

  const formik = useFormik<FormType>({
    initialValues: {
      assessment_status: undefined,
      current_value: "",
      notes: ""
    },
    validate: (values) => {
      const errors = {};
      if (!values.assessment_status) {
        errors["assessment_status"] = "Mandatory field";
      }

      if (!values.current_value) {
        errors["current_value"] = "Mandatory field";
      }
      return errors;
    },
    onSubmit: (values) => {
      upsertMutation({
        goalAssessments: [
          {
            goal_id: goal.goal_id,
            assessment_status: values.assessment_status,
            current_value: values.current_value,
            notes: values.notes
          }
        ],
        member_id: memberId
      });
      formik.resetForm();
    },
    enableReinitialize: true
  });

  enum TabPanelIndex {
    newAssessment = 0,
    latestAssessments = 1
  }

  const renderTabIndex = (index) => {
    return (
      <>
        {index === TabPanelIndex.latestAssessments && (
          <Row>
            <RenderGoalProgress latest_assessments={latest_assessments} />
          </Row>
        )}
        {index === TabPanelIndex.newAssessment && (
          <>
            <br />
            <Row style={{ justifyContent: "flex-start", gap: "20px" }}>
              <Circle
                color={
                  formik.values.assessment_status ===
                    GoalAssessmentStatusEnum.ON_TRACK ||
                  formik.values.assessment_status ===
                    GoalAssessmentStatusEnum.COMPLETED
                    ? "success"
                    : formik.values.assessment_status ===
                        GoalAssessmentStatusEnum.OFF_TRACK
                      ? "error"
                      : "disabled"
                }
              />
              <TextField
                label={"Status"}
                select={true}
                slotProps={{
                  select: {
                    variant: "outlined",
                    MenuProps: { PaperProps: { sx: { maxHeight: 200 } } },
                    onChange: (event) => {
                      formik.setFieldValue(
                        "assessment_status",
                        event.target.value
                      );
                    }
                  }
                }}
                value={formik.values.assessment_status ?? ""}
                style={{ width: 200 }}
                error={!isFalsy(formik.errors["assessment_status"])}
                helperText={formik.errors["assessment_status"]}
                placeholder={"Select"}
              >
                {ASSESMENT_VALUES.map((item) => (
                  <MenuItem key={item} value={item}>
                    {getPrettyGoalAssessmentStatus(item)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Current Value"
                value={formik.values.current_value ?? ""}
                onChange={(event) =>
                  formik.setFieldValue("current_value", event.target.value)
                }
              />
              <TextField label="Target Value" value={target_value} disabled />
            </Row>
            <br />
            <Row
              style={{
                justifyContent: "flex-start",
                gap: "20px",
                marginLeft: "44px"
              }}
            >
              <TextField
                label="Notes"
                placeholder="Add note"
                multiline={true}
                rows={2}
                fullWidth={true}
                value={formik.values.notes ?? ""}
                onChange={(event) =>
                  formik.setFieldValue("notes", event.target.value)
                }
                error={!isFalsy(formik.errors["notes"])}
                helperText={formik.errors["notes"]}
              />
            </Row>
            <br />
            <Row
              style={{
                display: "flex",
                gap: "20px",
                marginLeft: "44px",
                alignItems: "center",
                marginBottom: "10px"
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={!formik.isValid}
                onClick={() => formik.handleSubmit()}
                loading={isLoading}
              >
                Add progress
              </LoadingButton>

              {isSuccess && (
                <>
                  <br />
                  <Typography
                    variant="body1"
                    color="green"
                  >{`Added progress to goal`}</Typography>
                </>
              )}
            </Row>
          </>
        )}
      </>
    );
  };

  const { goal_description, goal_category, target_value, latest_assessments } =
    goal;

  //const [showProgress, setShowProgress] = useState<boolean>(false);

  const onDeleteGoalPressed = (goal: GoalType) => {
    Alert_show({
      dispatch,
      id: "delete_goal",
      title: "Confirm",
      content: "Are you sure you want to delete this goal?",
      size: "small",
      type: "warning",
      row: true,
      buttons: [
        {
          text: "Delete",
          style: "destructive",
          onPress: () => {
            upsertGoalsMutation({
              goals: [
                {
                  goal_id: goal.goal_id,
                  goal_type: goal.goal_type,
                  goal_category: goal.goal_category,
                  created_by: goal.created_by,
                  created_on: goal.created_on,
                  goal_description: goal.goal_description,
                  target_value: goal.target_value,
                  goal_status: GoalStatusEnum.INACTIVE
                }
              ],
              member_id: memberId
            });
            Alert_close({ dispatch, id: "delete_goal" });
          }
        },
        {
          text: "Cancel",
          style: "cancel",
          onPress: () => {
            Alert_close({ dispatch, id: "delete_goal" });
          }
        }
      ]
    });
  };

  return (
    <Flexbox
      flexDirection={"column"}
      sx={{
        border: 2,
        borderColor: `${gray[300]}`,
        borderRadius: "4px",
        boxShadow: 0,
        minWidth: "320px"
      }}
    >
      <Flexbox
        flexDirection={"row"}
        justifyContent={"end"}
        marginBottom={"-15px"}
      >
        {/* <StyledIconButton
          onClick={() => {
            setShowProgress(!showProgress);
          }}
          Icon={ManageSearch}
          iconColor={blue[700]}
          color={"transparent"}
          size={"small"}
          captionText={`${latest_assessments?.length}`}
        /> */}
        <StyledIconButton
          onClick={() => onEditPressed(goal)}
          Icon={Edit}
          iconColor={blue[700]}
          color={"transparent"}
          size={"small"}
        />
        <StyledIconButton
          onClick={() => onDeleteGoalPressed(goal)}
          Icon={Delete}
          iconColor={blue[700]}
          color={"transparent"}
          size={"small"}
        />
      </Flexbox>
      <Container
        sx={{
          background: "white",
          padding: "5px"
        }}
      >
        <Row>
          <div>
            <Typography variant="h4" mt={"15px"}>
              {getGoalTitleByCategory(goal_category)}
            </Typography>
            <Typography variant="body1">{goal_description}</Typography>
          </div>
        </Row>
        {/*showProgress && (
          <Flexbox alignContent={"center"} flexDirection={"row"} width={"100%"}>
            <RenderGoalProgress latest_assessments={latest_assessments} />
          </Flexbox>
        )*/}

        <br />
        {!goalOnly && (
          <>
            <Tabs
              value={tabIndex}
              onChange={handleTabSelect}
              aria-label="Goal Tabs"
            >
              <Tab
                key={TabPanelIndex.newAssessment}
                label={"Update Progress"}
                style={{ fontWeight: "600" }}
              />
              <Tab
                key={TabPanelIndex.latestAssessments}
                label={"Progress to Goal"}
                style={{ fontWeight: "600" }}
              />
            </Tabs>
            {renderTabIndex(tabIndex)}
          </>
        )}
      </Container>
    </Flexbox>
  );
};

export const VisitFieldGoalManagement = ({
  memberId,
  goalOnly = false,
  clinicalOnly = false,
  condensed = false
}: IProps) => {
  const [processedGoals, setProcessedGoals] = useState<ProcessedGoalsType>({
    clinical: [],
    member: []
  });

  const { data: member, error } = useGetGoalsQuery(
    { member_id: memberId },
    { skip: isFalsy(memberId) }
  );

  const [addGoalModalVisible, setAddGoalModalVisible] =
    useState<boolean>(false);
  const [selectedGoalForEdit, setSelectedGoalForEdit] =
    useState<GoalType>(null);
  const [newGoalType, setNewGoalType] = useState<GoalTypeEnum>(null);

  useEffect(() => {
    const process = async () => {
      const newProcessedGoals = processGoals({
        goals: member?.goals,
        clinicalOnly: clinicalOnly
      });
      setProcessedGoals(newProcessedGoals);
    };

    if (member?.goals) process();
  }, [member]);

  const triggerAddGoal = (newGoalType: GoalTypeEnum) => {
    setNewGoalType(newGoalType);
    setAddGoalModalVisible(true);
  };

  const triggerEditGoal = (goal: GoalType) => {
    setAddGoalModalVisible(true);
    setSelectedGoalForEdit(goal);
    setNewGoalType(goal.goal_type);
  };

  return (
    <>
      {!condensed && (
        <>
          <Typography variant="h5">Goals</Typography>
        </>
      )}

      <br />
      <Row>
        <Typography variant="h5" color="primary">
          Clinical Goals
        </Typography>
        <Button
          variant="outlined"
          onClick={() => triggerAddGoal(GoalTypeEnum.CLINICAL)}
        >
          Add goal
        </Button>
      </Row>

      <br />
      <GridContainer>
        <>
          {processedGoals?.clinical.length === 0 && (
            <Typography
              variant="h6"
              color="text.secondary"
              textAlign={"center"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              height={"50px"}
            >
              No clinical goals found
            </Typography>
          )}
          {processedGoals?.clinical?.map((goal) => {
            return (
              <RenderGoal
                key={goal.goal_id}
                goal={goal}
                memberId={member?.patient?.patient_id}
                goalOnly={goalOnly}
                onEditPressed={triggerEditGoal}
              />
            );
          })}
        </>
      </GridContainer>
      <br />
      {!clinicalOnly && (
        <>
          <Row>
            <Typography variant="h5" color="primary">
              Member Goals
            </Typography>
            <Button
              variant="outlined"
              onClick={() => triggerAddGoal(GoalTypeEnum.MEMBER)}
            >
              Add goal
            </Button>
          </Row>

          <br />
          <GridContainer mb={"50px"}>
            <>
              {processedGoals?.member.length === 0 && (
                <Typography
                  variant="h6"
                  color="text.secondary"
                  textAlign={"center"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"50px"}
                >
                  No member goals found
                </Typography>
              )}
              {processedGoals?.member?.map((goal) => {
                return (
                  <RenderGoal
                    key={goal.goal_id}
                    goal={goal}
                    memberId={member?.patient?.patient_id}
                    goalOnly={goalOnly}
                    onEditPressed={triggerEditGoal}
                  />
                );
              })}
            </>
          </GridContainer>
        </>
      )}

      <ErrorComponent error={error} />

      <AddGoalModal
        isOpen={addGoalModalVisible}
        onRequestClose={() => {
          setAddGoalModalVisible(false);
          setSelectedGoalForEdit(null);
          setNewGoalType(null);
        }}
        member={member}
        newGoalType={newGoalType}
        goalForEdit={selectedGoalForEdit}
      />
    </>
  );
};

export const VisitFieldClinicalGoalSummary = ({
  member
}: {
  member: MemberType;
}) => {
  const assessmentOptions = [
    {
      option: getPrettyGoalAssessmentStatus(GoalAssessmentStatusEnum.ON_TRACK)
    },
    {
      option: getPrettyGoalAssessmentStatus(GoalAssessmentStatusEnum.OFF_TRACK)
    }
  ];

  return (
    <>
      <VisitFieldGoalManagement
        memberId={member?.patient?.patient_id}
        condensed={true}
        goalOnly={true}
        clinicalOnly={true}
      />
      {hasDiabetes(member) && (
        <VisitContentField
          field={{
            field_id:
              CareFlowFieldIdsEnum.PRECALL_CLINICAL_TOPICS_ASSESS_GM_READINGS,
            type: CareFlowFieldTypeEnum.RADIO,
            label:
              "How are the members readings tracking toward their diabetes goals?",
            options: assessmentOptions
          }}
          member={member}
        />
      )}
      {hasHypertension(member) && (
        <VisitContentField
          field={{
            field_id:
              CareFlowFieldIdsEnum.PRECALL_CLINICAL_TOPICS_ASSESS_BP_READINGS,
            type: CareFlowFieldTypeEnum.RADIO,
            label:
              "How are the members readings tracking toward their hypertension goals?",
            options: assessmentOptions
          }}
          member={member}
        />
      )}
    </>
  );
};
