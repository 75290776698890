import { DateTime } from "luxon";

import { apiMessaging } from "./AxiosService";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import MessagingResponseType from "../types/Messaging/MessagingResponseType";

interface GetTwilioInboundSMSParams {
  member_id?: string;
  startDate?: DateTime;
  endDate?: DateTime;
}

const messagingService = apiMessaging
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Messaging]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getTwilioInboundSMSNotifications: builder.query<
        MessagingResponseType[],
        GetTwilioInboundSMSParams
      >({
        query: ({
          member_id,
          startDate,
          endDate
        }: {
          member_id?: string;
          startDate: DateTime;
          endDate: DateTime;
        }) => {
          const params = QueryString_stringify({
            start: startDate.toUTC().toISO(),
            end: endDate.toUTC().toISO(),
            patient_id: member_id
          });
          return {
            url: `/twilio/inbound/sms/search?${params}`,
            method: "GET"
          };
        },
        providesTags: () => [{ type: ReduxTagEnum.Messaging }]
      }),
      unRegisterPushNotificationToken: builder.mutation<
        any,
        {
          member_id?: string;
          deviceToken: string;
        }
      >({
        query: ({ deviceToken, member_id }) => {
          const data = {
            identity: member_id,
            binding_type: "fcm",
            address: deviceToken
          };
          return {
            url: `/twilio/push/unregister-binding`,
            method: "POST",
            data
          };
        }
      }),
      registerPushNotificationToken: builder.mutation<
        any,
        {
          member_id?: string;
          deviceToken: string;
        }
      >({
        query: ({ deviceToken, member_id }) => {
          const data = {
            identity: member_id,
            binding_type: "fcm",
            address: deviceToken
          };
          return {
            url: `/twilio/push/register-binding`,
            method: "POST",
            data
          };
        }
      })
    })
  });

export const {
  useGetTwilioInboundSMSNotificationsQuery,
  useUnRegisterPushNotificationTokenMutation,
  useRegisterPushNotificationTokenMutation
} = messagingService;

export default messagingService;
