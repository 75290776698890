import { DateTime } from "luxon";
import { Column, DefaultTableCell } from "../../../styling/StyleComponents";
import { store } from "common/redux";
import { getNameOrUsername, isFalsy } from "common/helpers/helpers";
import { Link, Typography } from "@mui/material";
import { getRoleLabel } from "common/enums/RolesEnum";

export const dateCell = ({ getValue, format = "MM/dd/yyyy" }) => {
  return (
    <DefaultTableCell>
      {DateTime.fromSQL(getValue()).toFormat(format)}
    </DefaultTableCell>
  );
};

export const dateWithRelative = ({ date }: { date: DateTime }) => {
  let dateString = "N/A";
  if (date?.isValid) {
    dateString = date.toLocaleString(DateTime.DATE_SHORT);
  }

  return (
    <Column
      style={{
        gap: "0px"
      }}
    >
      <Typography variant="body1" color={"primary"}>
        {dateString}
      </Typography>
      {date?.isValid && (
        <Typography variant="body2" color={"primary"}>
          {date.toRelative()}
        </Typography>
      )}
    </Column>
  );
};

export const staffMemberWithRole = ({ getValue }: any) => {
  const value = getValue();
  const displayValue = getNameOrUsername(value);

  const roles = [];
  value?.roles?.forEach((role) => {
    const roleLabel = getRoleLabel(role);
    // MEMBER_CARE_SPECIALIST role on Adam Admin user account is causing a crash
    if (!isFalsy(roleLabel)) {
      roles.push(roleLabel);
    }
  });

  const roleString = roles.slice(0, 3).join(", ");

  const isMe = value?.user_id === store.getState().auth.user.user_id;

  return (
    <DefaultTableCell
      style={{
        overflowWrap: "anywhere"
      }}
    >
      <Typography color="primary" variant="body1">
        {displayValue ?? "User no longer exists."} {isMe && "(me)"}
      </Typography>
      <Typography color="secondary" variant="body1" fontSize={12}>
        {roleString ?? "N/A"}
      </Typography>
    </DefaultTableCell>
  );
};

export const defaultCell = ({ getValue }) => {
  const value = getValue();

  const replaced = value?.split("_");
  const capitalized = replaced
    ?.map((text) => {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    })
    .join(" ");

  return (
    <DefaultTableCell>
      {capitalized !== "" ? capitalized : "N/A"}
    </DefaultTableCell>
  );
};

interface UserNameProps {
  id?: string;
  link?: string;
  children?: string;
}

export const UserNameCell = ({ id, link, children }: UserNameProps) => {
  if (link) {
    return (
      <Link
        id={id}
        color={"primary"}
        fontSize={"14px"}
        fontWeight={"600"}
        style={{ textDecoration: "none" }}
        href={link}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <Typography
        id={id}
        color={"primary"}
        fontSize={"14px"}
        fontWeight={"bold"}
      >
        {children ?? "N/A"}
      </Typography>
    );
  }
};

export const OrderLinkCell = ({ getValue }) => {
  const value = getValue();

  return (
    <UserNameCell id="orderLink" link={`/orders/${value}`}>
      {value}
    </UserNameCell>
  );
};
