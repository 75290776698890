import {
  CareFlowAnswerType,
  CareFlowFieldType
} from "common/types/Visits/CareFlowResponseType";
import { DateTime } from "luxon";
import { dispatch } from "common/redux";
import { setAnswer } from "common/redux/VisitsSlice";
import {
  useGetCareFlowAnswerQuery,
  useGetVisitQuery
} from "common/services/VisitsService";
import { isFalsy } from "common/helpers/helpers";
import MemberType from "common/types/MemberType";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { useGetUserWithUsernameQuery } from "common/services/UserService";
import { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import UserType from "common/types/UserType";
import {
  dateWithRelative,
  defaultCell,
  staffMemberWithRole
} from "../../../components/Table/helpers/TableCells";
import VisitType from "common/types/Visits/VisitType";
import { isDisabled } from "../VisitHelper";
import { CircularProgress } from "@mui/material";

interface IProps {
  field: CareFlowFieldType;
  visit: VisitType;
  member: MemberType;
}

interface MergedData extends CareFlowAnswerType {
  lastModifiedBy: UserType;
}

const VisitFieldPreCallLastVisitFollowUp = ({
  field,
  visit,
  member
}: IProps) => {
  const disabled = isDisabled(visit);
  const [mergedData, setMergedData] = useState<MergedData[]>(null);

  const {
    data: lastVisitFollowup,
    isSuccess: isAnswerSuccess,
    isError: isAnswerError
  } = useGetCareFlowAnswerQuery(
    {
      field_id: CareFlowFieldIdsEnum.CALL_NEXT_STEPS_ACTION_ITEMS,
      patient_id: member?.patient?.patient_id
    },
    {
      skip:
        isFalsy(member?.patient?.patient_id) || disabled || mergedData !== null
    }
  );

  const {
    data: lastVisit,
    isSuccess: isVisitSuccess,
    isError: isVisitError,
    isFetching
  } = useGetVisitQuery(
    { visit_id: lastVisitFollowup?.visit_id },
    {
      skip:
        isFalsy(lastVisitFollowup?.visit_id) || disabled || mergedData !== null
    }
  );

  const {
    data: lastModifiedBy,
    isSuccess: isUserSuccess,
    isError: isUserError
  } = useGetUserWithUsernameQuery(
    {
      username: lastVisit?.staff_id
    },
    { skip: isFalsy(lastVisit?.staff_id) || disabled || mergedData !== null }
  );

  useEffect(() => {
    if (isAnswerSuccess && isVisitSuccess && isUserSuccess) {
      if (!isFalsy(lastVisitFollowup?.value)) {
        setMergedData([
          { ...lastVisitFollowup, lastModifiedBy: lastModifiedBy }
        ]);
        dispatch(
          setAnswer({
            id: field.field_id,
            value: lastVisitFollowup?.value
          })
        );
      } else {
        setMergedData([]);
      }
    }

    if (isAnswerError || isVisitError || isUserError) setMergedData([]);
  }, [lastVisitFollowup, lastModifiedBy, lastVisit]);

  return (
    <>
      {isFetching && <CircularProgress />}
      {mergedData && (
        <Table
          data={mergedData}
          noDataText="No previous follow-up items found"
          tableColumns={[
            {
              id: "Follow-Up Items",
              name: "Follow-Up Items",
              header: "Follow-Up Items",
              accessor: "value",
              cell: defaultCell
            },
            {
              id: "Recorded By",
              name: "Recorded By",
              header: "Recorded By",
              accessor: "lastModifiedBy.user",
              size: 75,
              cell: staffMemberWithRole
            },
            {
              id: "Date",
              name: "Date",
              header: "Date",
              accessor: "modified_at",
              size: 75,
              cell: ({ getValue }) => {
                const value = getValue();
                let date;
                if (value) {
                  date = DateTime.fromISO(value);
                }
                return dateWithRelative({ date });
              }
            }
          ]}
        />
      )}
    </>
  );
};

export default VisitFieldPreCallLastVisitFollowUp;
