import { Typography } from "@mui/material";

import { RootState, useAppDispatch } from "common/redux";

import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { useSelector } from "react-redux";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { checkIdValid, isFalsy } from "common/helpers/helpers";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import { useEffect } from "react";
import { setSelectedMemberId } from "common/redux/webadmin/LayoutSlice";
import { useGetUserWithUsernameQuery } from "common/services/UserService";
import UserLinkedEntitiesEnum from "common/enums/UserLinkedEntitiesEnum";
import { MemberHeader } from "./MemberHeader";

const MemberDetailsHeader = ({
  hideButtons = false
}: {
  hideButtons?: boolean;
}) => {
  const params = useSanitizedParams();
  const dispatch = useAppDispatch();

  const { memberId } = params;

  const isValidId = checkIdValid(memberId);

  const { selectedMemberId } = useSelector((state: RootState) => state.layout);

  useEffect(() => {
    dispatch(setSelectedMemberId(memberId));
  }, [memberId]);

  const {
    data: patient,
    isLoading: isLoadingPatient,
    isSuccess: isLoadedPatient,
    isError: isPatientError
  } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities: [
        MemberLinkedEntitiesEnum.NURSE,
        MemberLinkedEntitiesEnum.PROVIDER,
        MemberLinkedEntitiesEnum.PROVIDER_METADATA,
        MemberLinkedEntitiesEnum.PATIENT_ACCESS_SUMMARY,
        MemberLinkedEntitiesEnum.CARE_PLAN,
        MemberLinkedEntitiesEnum.MISSING_LEGAL_FORMS,
      ]
    },
    { skip: memberId === undefined || isValidId === false }
  );

  const {
    data: nurseDetails,
    isFetching: nurseDetailsIsFetching,
    isSuccess: nurseDetailsIsSuccess,
    isUninitialized: nurseDetailsIsUninitialized
  } = useGetUserWithUsernameQuery(
    {
      username: patient?.assigned_nurse?.user_id,
      linked_entities: [UserLinkedEntitiesEnum.METADATA]
    },
    { skip: isFalsy(patient?.assigned_nurse?.user_id) }
  );

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return (
    <>
      {isLoadingPatient || nurseDetailsIsFetching ? (
        <LoadingFallback count={3} delay={500} />
      ) : (
        // Render content after dataloading finishes to prevent race condition
        <>
          {/* Render content after redux is synced up */}
          {selectedMemberId === memberId &&
            // Wait until nurseDetails is loaded
            ((isLoadedPatient && nurseDetailsIsSuccess) ||
              // or if nurseDetails does not need to be loaded
              (isLoadedPatient &&
                isFalsy(patient?.assigned_nurse?.user_id) &&
                nurseDetailsIsUninitialized)) && (
              // then render content
              <MemberHeader
                memberId={memberId}
                nurseZoomPhone={nurseDetails?.metadata?.extra?.zoom_phone}
                patient={patient}
                isPatientError={isPatientError}
                hideButtons={hideButtons}
              />
            )}
        </>
      )}
    </>
  );
};

export default MemberDetailsHeader;
