type MemberDetailsRoute =
  | ""
  | "appointments"
  | "start-intake"
  | "readings"
  | "overview"
  | "goals"
  | "encounters"
  | "orders"
  | "profile"
  | "profile/:tab"
  | "edit-appointment";

type StaffDetailsRoute = "schedule" | "profile" | ":tab";

type NurseScheduleRoute = ":tab" | "schedules" | "time_off";

const MEMBER_DETAILS = "/members/memberId/:memberId";
const STAFF_DETAILS = "/staff/staffId";

const Routes = {
  ROOT: "/",
  NPN_DETAILS: "/npn/:userId",
  REPORT: "/reports",
  REPRORT_DETAILS: "/reports/:reportId",
  REPORT_DETAILS_BY_MEMBER: "/reports/:reportId/memberId/:memberId",

  NURSE_REDIRECT: "/nurses/nurseId/:userId",
  NURSE_SCHEDULE: "/nurses/nurse-schedule/nurseId/:nurseId",
  NURSE_SCHEDULES: "/nurse-schedules",
  NURSE_SCHEDULE_SAME_DAY_TIME_OFF:
    "/nurses-schedules/same-day-time-off/:nurseId",
  NURSE_SCHEDULE_TIME_OFF: "/nurses-schedules/time-off/:nurseId",
  NURSE_SCHEDULE_MANUAL_REASSIGNMENT:
    "/nurses-schedules/manual-reassignment/:eventId",
  NURSE_SCHEDULE_DETAILS: (tab?: NurseScheduleRoute) =>
    tab ? `/nurses-schedules/${tab}` : "/nurses-schedules/",

  AWAITING_PROVIDER: "/awaiting-provider",
  INACTIVE_MEMBERS: "/inactive-members",
  ASSIGNED_MEMBERS: (param: ":nurseId" | "{{userId}}") =>
    `/members/nurseId/${param}`,
  UNASSIGNED_MEMBERS: "/unassigned-members",

  PROVIDER_SCHEDULES: "/provider-schedules",
  PROVIDERS: "/providers",
  MY_PROVIDERS: "/my-providers",

  CREATE_USER: "/create-user",
  NURSES: "/nurses",

  ALL_MEMBERS: "/members",

  ARC_OF_DAY: "/aod-dashboard",
  ARC_OF_DAY_DETAILS: "/aod-dashboard/:taskId",

  MY_TEAM: "/my-team",
  ALL_TEAMS: "/all-teams",
  TEAM_DETAILS: (param: string) => `/team/${param}`,
  RETENTION: "/retention/:tabId",
  CREATE_TEAM: "/create-team",
  TASKS: "/tasks",
  COMPLETED_TODOS: "/my-completed-todos",

  DEVICES_BY_MEMBER: "/devices/:memberId",
  REGISTER_MEMBER: "/register-member",
  REQUEST_CONSENTS: "/request-consents",

  ORDERS: "/orders",
  ORDERS_DETAILS: "/orders/:orderId",
  VISITS: "/visits/:visitId",

  PROVIDER_ENROLLMENT: "/provider-enrollment",

  STAFF_MEMBERS: "/staff-members",
  STAFF_DETAILS: (
    userIdKey: ":userId" | ":staffId" | ":nurseId",
    param?: StaffDetailsRoute
  ) => (param ? `${STAFF_DETAILS}/${userIdKey}/${param}` : STAFF_DETAILS),

  MEMBER_DETAILS: (param?: MemberDetailsRoute) =>
    param ? `${MEMBER_DETAILS}/${param}` : MEMBER_DETAILS,

  ATHENA_REDIRECT: "/athenaredirect/:memberId",
  CANCELLATIONS_HISTORY: `${MEMBER_DETAILS}/cancellations-history`,
  CANCELLED_MEMBERS: "/cancellations-history",
  MEMBER_READINGS: `${MEMBER_DETAILS}/readings/:readingType`,
  MEMBER_APPOINTMENTS: `${MEMBER_DETAILS}/appointments`,
  NEW_PROVIDER_APPOINTMENT: `${MEMBER_DETAILS}/new-provider-appointment`,
  EDIT_APPOINTMENT: `${MEMBER_DETAILS}/edit-appointment`,
  NEW_APPOINTMENT: `${MEMBER_DETAILS}/new-appointment`,
  NEW_NURSE_APPOINTMENT: `${MEMBER_DETAILS}/new-nurse-appointment`,

  MIGRATE_MEMBER: "/migrate-patient",

  MY_INTAKES: "/my-intakes",
  MY_CONVERSIONS: "/my-conversions",
  MY_REGISTRATIONS: "/my-registrations",

  NMNS: "/nmns",

  MY_PROFILE: "/my-profile",
  HELP: "/help",
  LOGOUT: "/_logout"
};

export default Routes;
