import { useGetOrdersByStaffIdQuery } from "common/services/OrdersService";
import Table from "../../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import ErrorComponent from "../../../components/ErrorComponent";
import { getDeviceInfoBySku } from "../../../helpers/helpers";
import {
  DateTimeColumn,
  MemberColumn,
  OrderDevicesOrdersItemsColumn,
  OrderIdColumn,
  OrderPatientNameColumn,
  OrderStatusColumn,
  OrderTrackingColumn
} from "../../../components/Table/helpers/TableColumns";

const tableColumns = [
  OrderIdColumn,
  OrderPatientNameColumn,
  DateTimeColumn({
    header: "Ordered",
    id: "created_at",
    accessor: "order.created_at",
    size: 100,
    format: "sql"
  }),
  OrderStatusColumn({
    accessor: "order.status.sm_status",
    header: "Order Status"
  }),
  DateTimeColumn({
    header: "Shipped",
    id: "shipped_at",
    accessor: "order.shipping.shipping_date",
    size: 100,
    format: "sql"
  }),
  DateTimeColumn({
    header: "Delivered",
    id: "delivered_at",
    accessor: "order.shipping.delivered_date",
    size: 120,
    format: "sql"
  }),
  OrderTrackingColumn,
  MemberColumn({ accessor: "patient" }),
  OrderDevicesOrdersItemsColumn
];

interface IProps {
  staffId: string;
}

const OrdersTab = ({ staffId }: IProps) => {
  const navigate = useNavigate();

  const { data, error } = useGetOrdersByStaffIdQuery({ staffId });

  const mappedData = data?.map((item) => {
    const items = item.order?.items?.map((item) => {
      const { sku, quantity } = item;
      const deviceInfo = getDeviceInfoBySku(sku);
      const { description } = deviceInfo || {};
      if (description && quantity) {
        return {
          ...item,
          description: `${description} x${quantity}`
        };
      } else {
        return {
          ...item,
          description: `${sku} x${quantity}`
        };
      }
    });

    return {
      ...item,
      order: {
        ...item.order,
        items: items ?? []
      }
    };
  });
  return (
    <>
      {mappedData && (
        <Table
          data={mappedData}
          tableProps={{ navigate }}
          tableColumns={tableColumns}
          tableHeader="{{COUNT}} orders"
          estimateRowSize={(index: number) => {
            const item = mappedData[index];
            const itemsAmount = item?.order?.items?.length;

            return Math.max(79, 55 + 12 * itemsAmount);
          }}
        />
      )}
      <ErrorComponent error={error} />
    </>
  );
};

export default OrdersTab;
