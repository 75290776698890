import { Button } from "@mui/material";
import { DateTime } from "luxon";

import Table from "../../../components/Table/Table";
import MemberType from "common/types/MemberType";
import EditRelationshipNoteForm from "../EditRelationshipNoteForm";
import { Alert_show } from "common/helpers/AlertHelper";
import { AppDispatch, RootState, dispatch } from "common/redux";
import { useSelector } from "react-redux";
import { canEditRelationshipNotes } from "common/enums/RolesEnum";
import VisitType from "common/types/Visits/VisitType";
import { isDisabled } from "../VisitHelper";
import Routes from "../../../routes/Routes";
import {
  DefaultTableCell,
  StatusBadge
} from "../../../styling/StyleComponents";
import { dateWithRelative } from "../../../components/Table/helpers/TableCells";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import DeleteRelationshipNoteForm from "../DeleteRelationshipNoteForm";

interface IProps {
  member: MemberType;
  visit?: VisitType;
}

interface ColumnProps {
  dispatch?: AppDispatch;
  member?: MemberType;
  isEditDeleteAllowed?: boolean;
}
export const RELATIONSHIP_NOTES_COLUMNS = ({
  dispatch,
  member,
  isEditDeleteAllowed
}: ColumnProps) => [
  {
    id: "relationshipNotesCategory",
    name: "relationshipNotesCategory",
    header: "Category",
    accessor: "category",
    func: "accessor",
    size: 100,
    cell: ({ getValue }) => {
      const category = getValue();
      return (
        <>{category && <StatusBadge status={category} hideDot={true} />}</>
      );
    },
    enableColumnFilter: true
  },
  {
    header: "Note",
    id: "relationshipNotesNote",
    name: "relationshipNotesNote",
    accessor: "note",
    func: "accessor",
    size: 300,
    cell: ({ getValue }) => {
      const value = getValue();
      return <DefaultTableCell>{value}</DefaultTableCell>;
    }
  },
  {
    header: "Last Updated",
    id: "relationshipNotesLastModified",
    name: "relationshipNotesLastModified",
    accessor: "last_modified",
    func: "accessor",
    sortingFn: "dateTimeSortingSQL",
    size: 100,
    cell: ({ getValue }) => {
      const value = getValue();
      let date = DateTime.fromSQL(value);
      if (date?.isValid === false) date = DateTime.fromISO(value);

      return <DefaultTableCell>{dateWithRelative({ date })}</DefaultTableCell>;
    },
    enableColumnFilter: true
  },
  {
    header: "Actions",
    id: "relationshipNotesActions",
    name: "relationshipNotesActions",
    func: "accessor",
    size: 100,
    cell: ({ row }: any) => {
      const relationshipNote = row?.original;

      if (!isEditDeleteAllowed) return <></>;

      return (
        <Flexbox gap="8px" alignItems="center" justifyContent={"flex-start"}>
          <EditOutlined
            sx={{ cursor: "pointer" }}
            onClick={() => {
              Alert_show({
                dispatch,
                id: "editRelationshipNote",
                title: "Edit Relationship Note",
                content: (
                  <EditRelationshipNoteForm
                    member={member}
                    relationshipNote={relationshipNote}
                  />
                ),
                size: "large",
                buttons: []
              });
            }}
          />
          <DeleteOutlineOutlined
            sx={{ cursor: "pointer" }}
            onClick={() => {
              Alert_show({
                dispatch,
                id: "deleteEvent",
                title: "Delete Relationship Note",
                content: (
                  <DeleteRelationshipNoteForm
                    relationshipNote={relationshipNote}
                  />
                ),
                type: "warning",
                size: "large",
                buttons: []
              });
            }}
          />
        </Flexbox>
      );
    },
    enableColumnFilter: true
  }
];

const VisitFieldMemberRelationshipNotes = ({ member, visit }: IProps) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);
  const isEditDeleteAllowed = canEditRelationshipNotes(currentRole);

  const isInCareFlow = window.location.href.includes(
    Routes.VISITS.replace(":visitId", "")
  );
  const disabled = isInCareFlow ? isDisabled(visit) : false;

  return (
    <>
      <br />
      {isEditDeleteAllowed && (
        <Button
          variant="outlined"
          disabled={disabled}
          onClick={() => {
            Alert_show({
              dispatch,
              id: "editRelationshipNote",
              title: "Add Relationship Note",
              content: <EditRelationshipNoteForm member={member} />,
              size: "large",
              buttons: []
            });
          }}
        >
          Add New Relationship Note
        </Button>
      )}
      {member?.relationship_notes && (
        <Table
          tableColumns={RELATIONSHIP_NOTES_COLUMNS({
            member,
            dispatch,
            isEditDeleteAllowed
          })}
          data={member?.relationship_notes}
          noDataText="No relationship notes have been added for this member"
        />
      )}
    </>
  );
};

export default VisitFieldMemberRelationshipNotes;
