
import ReduxTagEnum from "../enums/ReduxTagEnum";
import { apiVoice } from "./AxiosService";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { AppDispatch } from "../redux";
import MemberMetadataType from "../types/MemberMetadataType";

async function clearCache(
  queryFulfilled: PromiseWithKnownReason<any, any>,
  dispatch: AppDispatch
) {
  try {
    await queryFulfilled;
    setTimeout(() => {
      dispatch(voiceService.util.invalidateTags([ReduxTagEnum.Voice]));
    }, 3000);
  } catch (error) {}
}

const voiceService = apiVoice
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Voice, ReduxTagEnum.Member]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries

      // Mutations
      createVOIPToken: builder.mutation<
        { access_token: string },
        { staff_id: string }
      >({
        query: ({ staff_id }) => {
          const data = {
            staff_id: staff_id
          };
          return {
            url: `/voip-token`,
            method: "POST",
            data
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          clearCache(queryFulfilled, dispatch);
        }
      }),
      assignOutreachNumber: builder.mutation<
        MemberMetadataType,
        { patient_id: string }
      >({
        query: ({ patient_id }) => {
          return {
            url: `/assign/outreach/${patient_id}`,
            method: "POST"
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Member }
        ],
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          clearCache(queryFulfilled, dispatch);
        }
      })
    })
  });

export const { useCreateVOIPTokenMutation, useAssignOutreachNumberMutation } =
  voiceService;
