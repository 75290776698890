// This component will eventually replace dataFieldColumn.tsx
// https://copilotiq.atlassian.net/browse/ENG-6953

import { DateTime } from "luxon";
import replace from "lodash.replace";

import { getNameOrUsername, isFalsy } from "common/helpers/helpers";
import { canSeeOrdersOnStaffDetailsCurrentRole } from "common/enums/RolesEnum";

import { dateWithRelative, OrderLinkCell, UserNameCell } from "./TableCells";
import {
  Column,
  CustomTooltip,
  DefaultTableCell,
  DefaultTableHeader,
  ExternalLink,
  StatusBadge
} from "../../../styling/StyleComponents";
import { HelpIcon, ThreeDotsIcon } from "../../../assets/images/icons";
import {
  getDeviceInfoBySku,
  getFormattedDate,
  getTrackingUrl
} from "../../../helpers/helpers";
import { Button, Typography } from "@mui/material";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";

const MEMBER_ID_PARAM = ":memberId";

// Common
interface ColumnProps {
  header: string;
  id: string;
  accessor: string;
  size?: number;
}

interface DateTimeColumnProps extends ColumnProps {
  format: "iso" | "sql";
  showRelativeTime?: boolean;
}

export const DateTimeColumn = ({
  header,
  id,
  accessor,
  format,
  size = 200,
  showRelativeTime = true
}: DateTimeColumnProps) => {
  return {
    header,
    name: id,
    id,
    accessor,
    size,
    func: "accessor",
    sortingFn: format === "iso" ? "dateTimeSortingISO" : "dateTimeSortingSQL",
    cell: ({ getValue }) => {
      const value = getValue();
      let date;
      if (format === "iso") date = DateTime.fromISO(value);
      else date = DateTime.fromSQL(value);

      return showRelativeTime
        ? dateWithRelative({ date })
        : getFormattedDate(date);
    }
  };
};

export const NumberColumn = ({ header, id, accessor }: ColumnProps) => {
  return {
    header,
    name: id,
    id,
    accessor,
    func: "accessor",
    sortingFn: "numberSorting",
    cell: ({ getValue }) => {
      const value = getValue();

      return <DefaultTableCell>{value}</DefaultTableCell>;
    }
  };
};

interface TextColumnProps extends ColumnProps {
  getText?: ({ getValue, row }) => string;
}

export const TextColumn = ({
  header,
  id,
  accessor,
  getText,
  size = 200
}: TextColumnProps) => {
  return {
    header,
    name: id,
    id,
    accessor,
    func: "accessor",
    size,
    cell: ({ getValue, row }) => {
      const text = getText?.({ getValue, row });

      return <DefaultTableCell>{text ?? getValue()}</DefaultTableCell>;
    }
  };
};

export const MemberColumn = ({ accessor }) => {
  return {
    header: "Member",
    name: "member" + accessor,
    id: "member" + accessor,
    accessor,
    func: "accessor",
    sortingFn: (a, b) => {
      const labelA = getNameOrUsername(a.original.patient);
      const labelB = getNameOrUsername(b.original.patient);
      return labelA.localeCompare(labelB);
    },
    cell: ({ getValue, row, column: { id }, table }: any) => {
      const value = getValue();
      const displayValue = getNameOrUsername(value);

      return (
        <UserNameCell link={`/members/memberId/${value?.patient_id}`}>
          {displayValue}
        </UserNameCell>
      );
    }
  };
};

// Orders
export const OrderIdColumn = {
  header: "Order No.",
  id: "order_id",
  name: "order_id",
  accessor: "order.order_id",
  func: "accessor",
  size: 250,
  cell: OrderLinkCell
};

export const OrderPatientNameColumn = {
  header: "Name",
  id: "orderPatientName",
  name: "orderPatientName",
  accessor: "patient",
  func: "accessor",
  cell: ({ getValue, table }) => {
    const value = getValue();
    const patientId = value?.patient_id;
    let displayValue = getNameOrUsername(value);
    const { externalLink } = table.options.meta;
    const link = replace(externalLink, MEMBER_ID_PARAM, patientId);

    return <UserNameCell link={link}>{displayValue}</UserNameCell>;
  }
};

export const OrderPatientStatusColumn = {
  header: () => {
    return (
      <DefaultTableHeader style={{ display: "flex", alignItems: "center" }}>
        Member Status&nbsp;
        <CustomTooltip
          title={
            <>
              Pending members have their devices ordered (no
              readings/encounters).
              <br />
              Active members have at least one reading and encounter
            </>
          }
        >
          <HelpIcon />
        </CustomTooltip>
      </DefaultTableHeader>
    );
  },
  name: "orderPatientStatus",
  id: "orderPatientStatus",
  accessor: "patient",
  func: "accessor",
  cell: ({ getValue, row, column: { id }, table }: any) => {
    const value = getValue();
    // need to do it like this instead of in the accessor or it will break the table :(
    const status = value?.status;
    return status ? <StatusBadge status={status} /> : null;
  }
};

export const OrderStatusColumn = ({
  accessor,
  header,
  filterFn = undefined
}) => {
  return {
    header,
    id: "orderStatus" + accessor,
    name: "orderStatus" + accessor,
    accessor,
    func: "accessor",
    size: 110,
    cell: ({ getValue, row, column: { id }, table }: any) => {
      const value = getValue();
      return <StatusBadge status={value} hideDot={true} />;
    },
    enableColumnFilter: true,
    filterEnabled: true,
    filterFn
  };
};

export const OrderTrackingColumn = {
  header: "Tracking",
  name: "tracking",
  id: "tracking",
  accessor: "order.shipping.tracking_number",
  func: "accessor",
  size: 260,
  sortingFn: (a, b) => {
    let trackingNumberA = a?.original?.order?.shipping?.tracking_number;
    let trackingNumberB = b?.original?.order?.shipping?.tracking_number;

    if (isFalsy(trackingNumberA)) trackingNumberA = "";
    if (isFalsy(trackingNumberB)) trackingNumberB = "";

    return trackingNumberA?.localeCompare(trackingNumberB);
  },
  cell: ({ row, column: { id }, table }: any) => {
    const value = row?.original?.order;
    const trackingNumber = value?.shipping?.tracking_number;
    const carrierCode = value?.shipping?.carrier_code ?? "USPS";

    const trackingUrl = getTrackingUrl(trackingNumber, carrierCode);

    // TBD - display error when we get api errors, for example peter parker athena id 7474
    if (value) {
      return (
        <ExternalLink href={trackingUrl} target="_blank">
          {trackingNumber}
        </ExternalLink>
      );
    }

    return <></>;
  }
};

export const OrderOrderedByColumn = {
  header: "Ordered By",
  name: "ordered_by",
  id: "ordered_by",
  accessor: "ordered_by",
  func: "accessor",
  cell: ({ getValue, table }) => {
    const value = getValue();
    const name = getNameOrUsername(value);
    const { navigate, currentRole } = table.options.meta;

    const link = `/staff/staffId/${value?.user_id}/orders`;

    const canSeeOrdersOnStaffProfile =
      canSeeOrdersOnStaffDetailsCurrentRole(currentRole);
    return (
      <DefaultTableCell
        style={{
          cursor: canSeeOrdersOnStaffProfile ? "pointer" : "default"
        }}
        onClick={() => {
          if (canSeeOrdersOnStaffProfile) navigate(link);
        }}
      >
        {name}
      </DefaultTableCell>
    );
  }
};

export const OrderDevicesOrdersItemsColumn = {
  header: "Items",
  name: "deviceOrdersItems",
  id: "deviceOrdersItems",
  accessor: "order.items",
  func: "accessor",
  size: 250,
  cell: ({ getValue }: any) => {
    const value = getValue();

    return (
      <DefaultTableCell>
        {value?.map((item: any, index: number) => {
          const { sku, device_id, description } = item || {};
          const id = `${index}-${sku}-${device_id}`;
          return <div key={id}>{`${description}`}</div>;
        })}
      </DefaultTableCell>
    );
  }
};

export const OrderItemsColumn = {
  header: "Items",
  name: "orderItems",
  id: "orderItems",
  accessor: "order.items",
  func: "accessor",
  size: 250,
  cell: ({ getValue }: any) => {
    const value = getValue();
    return (
      <DefaultTableCell>
        {value?.map((item: any, index: number) => {
          const { quantity, sku, device_id } = item || {};
          const id = `${index}-${sku}-${device_id}`;
          const deviceInfo = getDeviceInfoBySku(sku);
          const { description } = deviceInfo || {};
          if (description && quantity) {
            return <div key={id}>{`${description} x${quantity}`}</div>;
          } else {
            return <div key={id}>{`${sku} x${quantity}`}</div>;
          }
        })}
      </DefaultTableCell>
    );
  }
};
