import ComplexityEnum from "../enums/Calendaring/Appointments/ComplexityEnum";
import VisitReasonsEnum from "../enums/Calendaring/Visits/VisitReasonsEnum";
import EncounterReasonsEnum from "../enums/EncounterReasonsEnum";
import RolesEnum, {
  NP_Roles,
  ProviderRoles,
  TH_Roles,
  hasIntakeNurseRole,
  hasProviderRole,
  hasTnRole,
  isProviderRole
} from "../enums/RolesEnum";
import { DateTime } from "luxon";
import { isFalsy } from "./helpers";
import VideoCallDeclineReasonEnum from "../enums/Calendaring/Visits/VideoCallDeclineReasonEnum";
import EncounterPatientLocationEnum from "../enums/Encounters/EncounterPatientLocationEnum";
import EncounterPatientNotAtHomeDetailEnum from "../enums/Encounters/EncounterPatientNotAtHomeDetailEnum";

const filterByRole = (encounters: any[], roles: RolesEnum[]) => {
  return encounters?.filter((item) => {
    // If an item does not have an encounter, check the item.visit?.submitted_by_roles
    const submitter_roles = item.encounter
      ? item.encounter?.submitter_roles
      : item.visit?.submitted_by_roles;
    if (isFalsy(submitter_roles)) return false;
    for (const i in roles) {
      if (submitter_roles?.includes(roles[i])) return true;
    }
    return false;
  });
};

const encounterReasons = (role: RolesEnum) => {
  const isProvider = isProviderRole(role);

  const reasons = [
    {
      label: "CDM",
      value: EncounterReasonsEnum.CHRONIC_DISEASE_MANAGEMENT
    },
    {
      label: "Reactivating",
      value: EncounterReasonsEnum.REACTIVATING
    }
  ];
  if (!isProvider) {
    reasons.push({
      label: "RPM Care",
      value: EncounterReasonsEnum.REMOTE_PATIENT_MONITORING
    });
  }

  return reasons;
};

const tnVisitTypeReasons = () => {
  return [
    { label: "Nurse Visit", value: "disabled", disabled: true },
    {
      label: "Nurse Visit (Connected)",
      value: VisitReasonsEnum.REMOTE_PATIENT_MONITORING
    },
    {
      label: "Nurse Monitoring (Not Connected)",
      value: VisitReasonsEnum.ASYNC_REMOTE_PATIENT_MONITORING
    },
    { label: "Device Set Up", value: "disabled", disabled: true },
    {
      label: "Device Set Up (Connected)",
      value: VisitReasonsEnum.DEVICE_SETUP
    },
    {
      label: "Attempted Device Set Up (Not Connected)",
      value: VisitReasonsEnum.ASYNC_DEVICE_SETUP
    }
  ];
};

const TnEncounterReasons = [
  {
    label: "Nurse Visit (Connected)",
    value: VisitReasonsEnum.REMOTE_PATIENT_MONITORING
  },
  {
    label: "Nurse Monitoring (Not Connected)",
    value: VisitReasonsEnum.ASYNC_REMOTE_PATIENT_MONITORING
  },
  {
    label: "Device Set Up (Connected)",
    value: VisitReasonsEnum.DEVICE_SETUP
  },
  {
    label: "Attempted Device Set Up (Not Connected)",
    value: VisitReasonsEnum.ASYNC_DEVICE_SETUP
  }
];

const NewProviderEncounterReasons = [
  {
    label: "Provider Intake",
    value: VisitReasonsEnum.PROVIDER_INTAKE
  },
  { label: "Reactivation", value: VisitReasonsEnum.REACTIVATING },
  {
    label: "Provider Visit",
    value: VisitReasonsEnum.CHRONIC_DISEASE_MANAGEMENT
  }
];

const PatientPhysicalLocationDropdownOptions = [
  { label: "At Home", value: EncounterPatientLocationEnum.AT_HOME },
  { label: "Not at Home", value: EncounterPatientLocationEnum.NOT_AT_HOME }
];

const PatientNotAtHomeDropdownOptions = [
  { label: "Hospital", value: EncounterPatientNotAtHomeDetailEnum.HOSPITAL },
  {
    label: "Skilled Nursing Facility",
    value: EncounterPatientNotAtHomeDetailEnum.SKILLED_NURSING_FACILITY
  },
  {
    label: "Other / Private Location",
    value: EncounterPatientNotAtHomeDetailEnum.OTHER
  }
];

const newEncounterReasons = (role: RolesEnum) => {
  // TBD fix this mapping to use new enum values with this epic
  // https://copilotiq.atlassian.net/browse/ENG-4988
  const isProvider = ProviderRoles.includes(role);
  const isIntakeNurse = NP_Roles.includes(role);
  // const isTN = TH_Roles.includes(role);

  if (isIntakeNurse) {
    return [{ label: "Intake", value: VisitReasonsEnum.NURSE_INTAKE }];
  }

  if (isProvider) {
    const reasons = [];
    // ENG-5064 - UI guards- ignore provider encounters if the most recent provider encounter is older than 3 years

    // ENG-5639 - Always show this reason
    // if (!hasProviderSubmittedEncounter) {
    reasons.push({
      label: "Provider Intake",
      value: VisitReasonsEnum.PROVIDER_INTAKE
    });
    // }

    reasons.push({
      label: "Reactivation",
      value: VisitReasonsEnum.REACTIVATING
    });
    reasons.push({
      label: "Provider Visit",
      value: VisitReasonsEnum.CHRONIC_DISEASE_MANAGEMENT
    });

    return reasons;
  }

  // we aren't using this at the moment - tbd if we need to add it back in

  // if (isTN) {
  //   return [
  //     {
  //       label: "Device Set Up",
  //       value: VisitReasonsEnum.INITIAL_VISIT
  //     },
  //     {
  //       label: "Nurse Monitoring",
  //       value: VisitReasonsEnum.ASYNC_REMOTE_PATIENT_MONITORING
  //     },
  //     {
  //       label: "Nurse Visit",
  //       value: VisitReasonsEnum.REMOTE_PATIENT_MONITORING
  //     }
  //   ];
  // }

  return [];
};

const roleHasMultipleMatches = (roles: RolesEnum[]) => {
  return (
    Number(hasProviderRole(roles)) +
      Number(hasIntakeNurseRole(roles)) +
      Number(hasTnRole(roles)) >
    1
  );
};

const getEncounterTypeForRole = (
  originalType: VisitReasonsEnum | EncounterReasonsEnum,
  submitterRoles: RolesEnum[],
  starts_on: string
) => {
  const VISITS_CUTOFF_DATE = DateTime.fromFormat("2024-03-15", "yyyy-MM-dd");

  const isAfterCutoff =
    DateTime.fromISO(starts_on)?.isValid &&
    DateTime.fromISO(starts_on) > VISITS_CUTOFF_DATE;

  const isProvider = submitterRoles?.some((role) =>
    ProviderRoles.includes(role)
  );
  // TBD change this to support intake nurse floats
  const isIntakeNurse = submitterRoles?.some((role) => NP_Roles.includes(role));
  const isTN = submitterRoles?.some((role) => TH_Roles.includes(role));

  // after cutover date logic
  if (isAfterCutoff) {
    switch (originalType) {
      // old reasons
      case VisitReasonsEnum.INITIAL_VISIT:
        if (roleHasMultipleMatches(submitterRoles)) {
          return "-";
        } else if (isTN) {
          return "Device Set Up (Not Connected)";
        } else if (isIntakeNurse) {
          return "Intake";
        } else if (isProvider) {
          return "Provider Intake";
        }
      case VisitReasonsEnum.ASYNC_REMOTE_PATIENT_MONITORING:
        return "Nurse Monitoring (Not Connected)";
      case VisitReasonsEnum.REMOTE_PATIENT_MONITORING:
        return "Nurse Visit (Connected)";
      case VisitReasonsEnum.ASYNC_REMOTE_PATIENT_MONITORING:
        return "Nurse Monitoring (Not Connected)";
      case VisitReasonsEnum.REMOTE_PATIENT_MONITORING:
        return "Nurse Visit (Connected)";
      // new reasons
      case VisitReasonsEnum.DEVICE_SETUP:
        return "Device Set Up (Connected)";
      case VisitReasonsEnum.ASYNC_DEVICE_SETUP:
        return "Attempted Device Set Up (Not Connected)";
      case VisitReasonsEnum.NURSE_FOLLOWUP:
        return "Nurse Visit (Connected)";
      case VisitReasonsEnum.CHRONIC_DISEASE_MANAGEMENT:
        return "Provider Follow Up";
      case VisitReasonsEnum.REACTIVATING:
        return "Provider Reactivation";
      case VisitReasonsEnum.NURSE_INTAKE:
        return "Nurse Intake";
      case VisitReasonsEnum.PROVIDER_INTAKE:
        return "Provider Intake";
      case VisitReasonsEnum.PROVIDER_FOLLOWUP:
        return "Provider Follow Up";
      default:
        return "-";
    }
  } else {
    // before cutover date logic
    if (isProvider) {
      switch (originalType) {
        // old reasons
        case VisitReasonsEnum.INITIAL_VISIT:
          return "Provider Intake";
        case VisitReasonsEnum.CHRONIC_DISEASE_MANAGEMENT:
          return "Provider Follow Up";
        case VisitReasonsEnum.REACTIVATING:
          return "Provider Reactivation";
        // new reasons
        case VisitReasonsEnum.PROVIDER_INTAKE:
          return "Provider Intake";
        case VisitReasonsEnum.PROVIDER_FOLLOWUP:
          return "Provider Follow Up";
        case VisitReasonsEnum.REMOTE_PATIENT_MONITORING:
          return "Provider Follow Up";
        default:
          if (roleHasMultipleMatches(submitterRoles)) {
            return "-";
          }
          return "-";
      }
      // put TN logic before IN logic to handle the intake float role - where
      // a TN is also given the IN role and helps cover both functionalities
    } else if (isTN) {
      switch (originalType) {
        // old reasons
        case VisitReasonsEnum.INITIAL_VISIT:
          return "Device Set Up (Connected)";
        case VisitReasonsEnum.ASYNC_REMOTE_PATIENT_MONITORING:
          return "Nurse Monitoring (Not Connected)";
        case VisitReasonsEnum.REMOTE_PATIENT_MONITORING:
          return "Nurse Visit (Connected)";
        case VisitReasonsEnum.ASYNC_REMOTE_PATIENT_MONITORING:
          return "Nurse Monitoring (Not Connected)";
        case VisitReasonsEnum.REMOTE_PATIENT_MONITORING:
          return "Nurse Visit (Connected)";
        // new reasons
        case VisitReasonsEnum.DEVICE_SETUP:
          return "Device Set Up (Connected)";
        case VisitReasonsEnum.ASYNC_DEVICE_SETUP:
          return "Attempted Device Set Up (Not Connected)";
        case VisitReasonsEnum.NURSE_FOLLOWUP:
          return "Nurse Visit (Connected)";
        default:
          if (roleHasMultipleMatches(submitterRoles)) {
            return "-";
          }
          return "-";
      }
    } else if (isIntakeNurse) {
      switch (originalType) {
        // old reason
        case VisitReasonsEnum.INITIAL_VISIT:
          return "Nurse Intake";
        // new reason
        case VisitReasonsEnum.NURSE_INTAKE:
          return "Nurse Intake";
      }
    } else {
      if (roleHasMultipleMatches(submitterRoles)) {
        return "-";
      }
      return "-";
    }
  }
};

function getComplexityTypes(reason: VisitReasonsEnum) {
  if (reason === VisitReasonsEnum.PROVIDER_INTAKE) {
    return [
      {
        label: "99202 (New Straightforward)",
        value: ComplexityEnum["99202 (New Straightforward)"]
      },
      { label: "99203 (New Low)", value: ComplexityEnum["99203 (New Low)"] },
      {
        label: "99204 (New Moderate)",
        value: ComplexityEnum["99204 (New Moderate)"]
      },
      { label: "99205 (New High)", value: ComplexityEnum["99205 (New High)"] },
      {
        label: "99212 (Established Straightforward)",
        value: ComplexityEnum["99212 (Established Straightforward)"]
      },
      {
        label: "99213 (Established Low)",
        value: ComplexityEnum["99213 (Established Low)"]
      },
      {
        label: "99214 (Established Moderate)",
        value: ComplexityEnum["99214 (Established Moderate)"]
      },
      {
        label: "99215 (Established High)",
        value: ComplexityEnum["99215 (Established High)"]
      }
    ];
  } else if (reason === VisitReasonsEnum.REACTIVATING) {
    return [
      {
        label: "99202 (New Straightforward)",
        value: ComplexityEnum["99202 (New Straightforward)"]
      },
      { label: "99203 (New Low)", value: ComplexityEnum["99203 (New Low)"] },
      {
        label: "99204 (New Moderate)",
        value: ComplexityEnum["99204 (New Moderate)"]
      },
      { label: "99205 (New High)", value: ComplexityEnum["99205 (New High)"] },
      {
        label: "99212 (Established Straightforward)",
        value: ComplexityEnum["99212 (Established Straightforward)"]
      },
      {
        label: "99213 (Established Low)",
        value: ComplexityEnum["99213 (Established Low)"]
      },
      {
        label: "99214 (Established Moderate)",
        value: ComplexityEnum["99214 (Established Moderate)"]
      },
      {
        label: "99215 (Established High)",
        value: ComplexityEnum["99215 (Established High)"]
      }
    ];
  } else if (
    reason === VisitReasonsEnum.PROVIDER_FOLLOWUP ||
    reason === VisitReasonsEnum.CHRONIC_DISEASE_MANAGEMENT
  ) {
    return [
      {
        label: "99212 (Established Straightforward)",
        value: ComplexityEnum["99212 (Established Straightforward)"]
      },
      {
        label: "99213 (Established Low)",
        value: ComplexityEnum["99213 (Established Low)"]
      },
      {
        label: "99214 (Established Moderate)",
        value: ComplexityEnum["99214 (Established Moderate)"]
      },
      {
        label: "99215 (Established High)",
        value: ComplexityEnum["99215 (Established High)"]
      }
    ];
  } else {
    return [
      {
        label: "99202 (New Straightforward)",
        value: ComplexityEnum["99202 (New Straightforward)"]
      },
      { label: "99203 (New Low)", value: ComplexityEnum["99203 (New Low)"] },
      {
        label: "99204 (New Moderate)",
        value: ComplexityEnum["99204 (New Moderate)"]
      },
      { label: "99205 (New High)", value: ComplexityEnum["99205 (New High)"] }
    ];
  }
}

const VideoCallDeclineReasons = [
  {
    label: "Patient Unable",
    value: VideoCallDeclineReasonEnum.PATIENT_UNABLE
  },
  {
    label: "Patient Unwilling",
    value: VideoCallDeclineReasonEnum.PATIENT_UNWILLING
  }
];

/**
 * This function checks if the encounters array has a provider-submitted Provider Intake or Provider Follow Up encounter in the last 3 years
 * @param historicalEncounters array of encounters
 * @returns {boolean} true if the array has a provider-submitted Provider Intake or Provider Follow Up encounter in the last 3 years
 */

const arrayHasProviderSubmittedEncounter = (historicalEncounters) => {
  return (
    historicalEncounters &&
    historicalEncounters?.length > 0 &&
    // historical encounters is sorted by newest encounters first, findIndex will return the most recent encounter
    historicalEncounters?.findIndex((item) => {
      const roles =
        item?.encounter?.submitter_roles ?? item?.visit?.submitted_by_roles;
      return (
        roles?.findIndex((role: RolesEnum) => isProviderRole(role)) > -1 &&
        // ENG-5064 ignore provider encounters if the most recent provider encounter is older than 3 years
        // ENG 5639 - don't check for 3 years
        // Math.abs(DateTime.fromISO(item?.starts_on).diffNow("years").years) <
        //   3 &&
        // new reasons
        (item?.encounter?.reason === VisitReasonsEnum.PROVIDER_INTAKE ||
          item?.encounter?.reason === VisitReasonsEnum.PROVIDER_FOLLOWUP ||
          // old reasons
          item?.encounter?.reason === VisitReasonsEnum.DEVICE_SETUP ||
          item?.encounter?.reason === VisitReasonsEnum.INITIAL_VISIT ||
          item?.encounter?.reason ===
            VisitReasonsEnum.CHRONIC_DISEASE_MANAGEMENT ||
          item?.encounter?.reason ===
            VisitReasonsEnum.REMOTE_PATIENT_MONITORING)
      );
    }) > -1
  );
};

export {
  filterByRole,
  encounterReasons,
  newEncounterReasons,
  getComplexityTypes,
  getEncounterTypeForRole,
  NewProviderEncounterReasons,
  PatientPhysicalLocationDropdownOptions,
  PatientNotAtHomeDropdownOptions,
  tnVisitTypeReasons,
  TnEncounterReasons,
  roleHasMultipleMatches,
  arrayHasProviderSubmittedEncounter,
  VideoCallDeclineReasons
};
