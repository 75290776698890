interface SalesforceAccountType {
  Birthdate__c: string;
  City: string;
  Country: string;
  CountryCode: string;
  Email: string;
  FirstName: string;
  Gender__c: string;
  Id: string;
  LastName: string;
  LeadSource: string;
  MiddleName: string;
  MobilePhone: string;
  Phone: string;
  PostalCode: string;
  Language__c: SalesforceLanguageEnum;
  State: string;
  StateCode: string;
  Status: string;
  Street: string;
  Street_2__c: string;
  tz__Timezone_IANA__c: string;
  Notes__c: string;
  validation_message: string;
}

export enum SalesforceLanguageEnum {
  SPANISH = "Spanish", ENGLISH = "English"
}
export default SalesforceAccountType;
