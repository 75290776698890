import styled from "@emotion/styled";
import { CustomTooltip } from "../../styling/StyleComponents";
import { CarePlanConditionsEnum } from "common/types/Visits/CarePlanType";
import { useNavigate } from "react-router-dom";
import { BpPdfIcon, GlucosePdfIcon } from "../../assets/images";
import { logoColors } from "common/styling/colors";

const SMALL_DIMENSION = "20px";
const LARGE_DIMENSION = "32px";

export const SmallDiabetes = styled(GlucosePdfIcon)`
  height: ${SMALL_DIMENSION};
  width: ${SMALL_DIMENSION};
  // background: ${logoColors.LightBlue};
  svg {
    fill: ${logoColors.LightBlue};
  }
`;

export const SmallHypertension = styled(BpPdfIcon)`
  height: ${SMALL_DIMENSION};
  width: ${SMALL_DIMENSION};
  // background: ${logoColors.DarkBlue};
`;

export const LargeDiabetes = styled(GlucosePdfIcon)`
  height: ${LARGE_DIMENSION};
  width: ${LARGE_DIMENSION};
  // background: ${logoColors.LightBlue};
`;

export const LargeHypertension = styled(BpPdfIcon)`
  height: ${LARGE_DIMENSION};
  width: ${LARGE_DIMENSION};
  // background: ${logoColors.DarkBlue};
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 36px;
  align-items: center;
`;

const getTooltip = (condition: string) => `${condition}`;

const ConditionButton = ({
  memberId,
  condition,
  disabled
}: {
  memberId: string;
  condition: CarePlanConditionsEnum;
  disabled: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <CustomTooltip
      placement="top"
      title={getTooltip(
        condition.charAt(0).toUpperCase() + condition.substring(1).toLowerCase()
      )}
    >
      <ButtonWrapper
        onClick={() => {
          if (!disabled) navigate(`/members/memberId/${memberId}/goals`);
        }}
      >
        {condition === CarePlanConditionsEnum.DIABETES && (
          <LargeDiabetes />
        )}
        {condition === CarePlanConditionsEnum.HYPERTENSION && (
          <LargeHypertension />
        )}
      </ButtonWrapper>
    </CustomTooltip>
  );
};

export const DiabetesButton = ({
  memberId,
  disabled = false
}: {
  memberId: string;
  disabled?: boolean;
}) => {
  return (
    <ConditionButton
      memberId={memberId}
      condition={CarePlanConditionsEnum.DIABETES}
      disabled={disabled}
    />
  );
};

export const HypertensionButton = ({
  memberId,
  disabled = false
}: {
  memberId: string;
  disabled?: boolean;
}) => {
  return (
    <ConditionButton
      memberId={memberId}
      condition={CarePlanConditionsEnum.HYPERTENSION}
      disabled={disabled}
    />
  );
};
