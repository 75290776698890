import { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import styled from "@emotion/styled";
import { TurqoiseButton } from "../../styling";
import { isValidSalesforceId } from "../../helpers/components/Forms/FormHelpers";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  TextField,
  Typography
} from "@mui/material";
import { useGetSalesforceLeadMutation } from "common/services/MemberRegistrationService";
import {
  useRequestConsentsMutation,
  useGetPendingConsentsByLeadIdQuery
} from "common/services/MemberConsentsService";
import Table from "../../components/Table/Table";
import {
  getNameOrUsername,
  maskPhoneNumber,
  PHONE_VALIDATION_REGEXP
} from "common/helpers/helpers";
import { DefaultTableCell } from "../../styling/StyleComponents";
import ErrorComponent from "../../components/ErrorComponent";
import { SalesforceLanguageEnum } from "common/types/SalesforceAccountType";
import { FocusAreaContainer } from "../Visits/ui/GeneralFields/FocusArea";
import { Flexbox } from "../../styling/NewStyleComponents";

const POST_SEND_POLLING_INTERVAL = 2500;

const RequestConsentResult = styled.div`
  font-weight: 500;
  font-size: medium;
  text-align: center;
`;

const RequestConsentResultError = styled(RequestConsentResult)`
  color: ${(props) => props.theme.color.red};
`;

const RequestConsentButtonRow = styled.div`
  margin: 2.5%;
  margin-bottom: 5%;
`;

const TurqoiseButtonSmall = styled(TurqoiseButton)`
  width: 45%;
  margin: 0% 2.5%;
`;

const RefreshTableDataButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

// This component should be moved to a helper / common table components class.
const DatetimeWithTimezone = ({
  value,
  timezone
}: {
  value: string;
  timezone: string;
}) => {
  const date = DateTime.fromISO(value, { zone: timezone });
  if (!date.isValid || value === "0001-01-01T00:00:00.000Z") {
    return <DefaultTableCell />;
  }

  return (
    <DefaultTableCell>
      {date.toLocaleString(DateTime.DATETIME_FULL)}
    </DefaultTableCell>
  );
};

interface FormType {
  salesforceLead: string;
}

interface IProps {
  salesforceLead: string;
  salesforceInitialLead: string;
  setSalesforceLead: Dispatch<SetStateAction<string>>;
}

const RequestConsentsForm = ({
  salesforceLead,
  salesforceInitialLead,
  setSalesforceLead
}: IProps) => {
  const [refresh, setRefresh] = useState<number>(0);
  const [sendSpanishConsents, setSendSpanishConsents] =
    useState<boolean>(false);
  const refreshTimerRef = useRef<NodeJS.Timeout>(null);

  const [
    getSalesforceLeadMutation,
    {
      data: leadData,
      error: isError,
      isSuccess: isLoaded,
      isLoading: isFetching,
      reset: salesforceLeadReset
    }
  ] = useGetSalesforceLeadMutation();

  useEffect(() => {
    const fetchSalesforceLead = async (salesforceLead) => {
      await getSalesforceLeadMutation(salesforceLead);
    };
    if (
      isValidSalesforceId(salesforceLead) &&
      !isFetching &&
      !isLoaded &&
      !isError
    ) {
      fetchSalesforceLead(salesforceLead);
    }
  }, [salesforceLead, isError, isLoaded, isFetching]);

  useEffect(() => {
    return () => {
      if (refreshTimerRef.current && refresh > 0)
        clearTimeout(refreshTimerRef.current);
    };
  }, []);

  const patientName = isLoaded
    ? getNameOrUsername({
        username: "",
        first: leadData?.FirstName,
        last: leadData?.LastName,
        middle: leadData?.MiddleName
      })
    : undefined;
  const patientMobile = isLoaded ? leadData?.MobilePhone : undefined;
  const isMobileValid = PHONE_VALIDATION_REGEXP.test(patientMobile);
  const patientEmail = isLoaded ? leadData?.Email : undefined;
  const [
    sendSmsMutation,
    {
      error: sendSmsError,
      isSuccess: sendSmsSuccess,
      isLoading: sendSmsLoading
    }
  ] = useRequestConsentsMutation();
  const [
    sendEmailMutation,
    {
      error: sendEmailError,
      isSuccess: sendEmailSuccess,
      isLoading: sendEmailLoading
    }
  ] = useRequestConsentsMutation();

  const sendSmsRequest = async () => {
    await sendSmsMutation({
      body: { consent: { lead_id: salesforceLead, mobile: patientMobile }, enEspañol: sendSpanishConsents }
    });
    triggerRefresh();
  };

  const sendEmailRequest = async () => {
    await sendEmailMutation({
      body: { consent: { lead_id: salesforceLead, email: patientEmail }, enEspañol: sendSpanishConsents }
    });
    triggerRefresh();
  };

  const triggerRefresh = () => {
    setRefresh(POST_SEND_POLLING_INTERVAL);
    if (refreshTimerRef.current) clearTimeout(refreshTimerRef.current);
    refreshTimerRef.current = setTimeout(() => {
      setRefresh(0);
      clearTimeout(refreshTimerRef.current);
    }, POST_SEND_POLLING_INTERVAL * 5);
  };

  const { currentData: pendingConsentsData } =
    useGetPendingConsentsByLeadIdQuery(
      { salesforceLeadId: salesforceLead },
      { skip: !leadData, pollingInterval: refresh }
    );

  const renderDateTimeCell = ({ getValue }) => {
    const timezone = leadData.tz__Timezone_IANA__c
      ? leadData.tz__Timezone_IANA__c
      : "America/New_York";

    return <DatetimeWithTimezone value={getValue()} timezone={timezone} />;
  };

  useEffect(() => {
    setSendSpanishConsents(
      leadData?.Language__c === SalesforceLanguageEnum.SPANISH
    );
  }, [leadData]);

  const validate = (values: FormType) => {
    const errors: { [key: string]: string } = {};

    if (!values.salesforceLead) {
      errors.salesforceLead = "Required";
    }

    if (values.salesforceLead && !isValidSalesforceId(values.salesforceLead)) {
      errors.salesforceLead = "Please enter a valid Salesforce Lead ID";
    }
    return errors;
  };

  const onSubmitLookup = async (values: FormType) => {
    const { salesforceLead } = values;
    if (isValidSalesforceId(salesforceLead)) {
      if (salesforceInitialLead !== salesforceLead || !isLoaded) {
        setSalesforceLead(salesforceLead);
        salesforceLeadReset();
      }
    }
  };

  const formikLookup = useFormik<FormType>({
    initialValues: {
      salesforceLead: salesforceLead
    },
    onSubmit: onSubmitLookup,
    validate
  });

  const {
    values,
    handleSubmit: handleSubmitLookup,
    setFieldValue,
    errors,
    isValid
  } = formikLookup;

  const noMobileText = isMobileValid
    ? `No mobile number on lead`
    : "Invalid mobile";

  return (
    <div>
      <Card sx={{ minWidth: 500 }}>
        <CardContent>
          <Typography variant="h3">Lookup Salesforce Lead</Typography>
          <br />
          <form onSubmit={handleSubmitLookup}>
            <TextField
              label="Salesforce Lead ID"
              value={values.salesforceLead}
              fullWidth
              onChange={(event) =>
                setFieldValue("salesforceLead", event.target.value)
              }
              error={errors["salesforceLead"] !== undefined}
              helperText={errors["salesforceLead"]}
            />

            <br />
            <br />
            <TurqoiseButton
              type="submit"
              loading={isFetching}
              disabled={
                !isValid ||
                !values.salesforceLead ||
                !isValidSalesforceId(values.salesforceLead) ||
                Object.keys(errors)?.length > 0
              }
            >
              Lookup
            </TurqoiseButton>
          </form>
        </CardContent>
      </Card>
      <br />
      {isError ? (
        <RequestConsentResultError>
          {isError["message"]}
        </RequestConsentResultError>
      ) : (
        ""
      )}
      {isLoaded ? (
        <div>
          <Card>
            <CardContent>
              <Typography variant="h3">{`Request Consents for ${patientName}`}</Typography>
              {leadData?.Language__c === SalesforceLanguageEnum.SPANISH && (
                <FocusAreaContainer flexDirection={"column"}>
                  <Typography variant="h6">💡 Note</Typography>
                  <Typography variant="body1">
                    Salesforce indicates this lead's primary language is Spanish
                  </Typography>
                </FocusAreaContainer>
              )}
              <Flexbox flexDirection={"row"} sx={{ justifyContent: "center" }}>
                <Checkbox
                  checked={sendSpanishConsents}
                  onChange={() => setSendSpanishConsents(!sendSpanishConsents)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="body1" sx={{ alignSelf: "center" }}>
                  Send consents in Spanish
                </Typography>
              </Flexbox>
              <RequestConsentButtonRow>
                <TurqoiseButtonSmall
                  onClick={sendSmsRequest}
                  loading={sendSmsLoading}
                  disabled={!isMobileValid}
                >
                  {patientMobile && isMobileValid ? (
                    <>
                      Send SMS to
                      <br />
                      {maskPhoneNumber(patientMobile)}
                    </>
                  ) : (
                    noMobileText
                  )}
                </TurqoiseButtonSmall>
                <TurqoiseButtonSmall
                  onClick={sendEmailRequest}
                  loading={sendEmailLoading}
                  disabled={!patientEmail}
                >
                  {patientEmail ? (
                    <>
                      Send Email to
                      <br />
                      {patientEmail}
                    </>
                  ) : (
                    `No email on lead`
                  )}
                </TurqoiseButtonSmall>
              </RequestConsentButtonRow>

              {!isMobileValid && (
                <ErrorComponent error="Mobile phone is not valid" />
              )}
              {sendSmsSuccess || sendEmailSuccess ? (
                <RequestConsentResult>Sent!</RequestConsentResult>
              ) : (
                ""
              )}
              {sendSmsError || sendEmailError ? (
                <RequestConsentResultError>
                  Unable to send request
                </RequestConsentResultError>
              ) : (
                ""
              )}
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardContent>
              <Typography variant="h3">Historical Consent Requests</Typography>
              {pendingConsentsData && pendingConsentsData.length > 0 ? (
                <div>
                  Requests expire within 48 hours
                  <Table
                    data={pendingConsentsData}
                    tableColumns={[
                      {
                        name: "requestConsentsRequested",
                        size: 150,
                        id: "date",
                        accessor: "created",
                        header: "Requested",
                        cell: renderDateTimeCell
                      },
                      {
                        name: "requestConsentsMethod",
                        size: 200
                      },
                      {
                        name: "requestConsentsViewed",
                        size: 150,
                        id: "viewed",
                        accessor: "viewed",
                        header: "Viewed",
                        cell: renderDateTimeCell
                      },
                      {
                        name: "requestConsentsAccepted",
                        size: 150,
                        id: "accepted",
                        accessor: "accepted",
                        header: "Accepted",
                        cell: renderDateTimeCell
                      }
                    ]}
                  />
                  <RefreshTableDataButton onClick={triggerRefresh}>
                    Refresh
                  </RefreshTableDataButton>
                </div>
              ) : (
                <RequestConsentResult>
                  No previous consent requests for this lead
                </RequestConsentResult>
              )}
              <br />
            </CardContent>
          </Card>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default RequestConsentsForm;
