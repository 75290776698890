import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import Modal from "./../components/Modal/Modal";
import { gray } from "common/styling/colors";

export const StyledModal = styled(Modal)<{
  modalHeight: string;
  modalWidth?: string;
}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 4px;
  height: ${(props) => props.modalHeight};
  width: ${(props) => props.modalWidth ?? "50%"};
  overflow-y: scroll;
  transition: height 0.66s ease-out";
`;

const ModalHeaderContainer = styled.div`
  height: 70px;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: rgba(24, 24, 25, 0.9);
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid ${gray[300]};
`;

interface ModalHeaderProps {
  children: React.ReactNode;
  onRequestClose?: () => void;
  hideCloseIcon?: boolean;
}

export const ModalHeader = ({
  children,
  hideCloseIcon,
  onRequestClose
}: ModalHeaderProps) => {
  return (
    <ModalHeaderContainer>
      {children} <ModalHeaderFillSpace />
      {onRequestClose !== undefined && !hideCloseIcon && (
        <IconButton onClick={onRequestClose}>
          <CloseIcon />
        </IconButton>
      )}
    </ModalHeaderContainer>
  );
};

const ModalHeaderFillSpace = styled.div`
  flex: 1;
`;

export const ModalHeaderFlexbox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 25px;
`;

export const BodyHeader = styled.div`
  color: rgba(24, 24, 25, 0.7);
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  margin-bottom: 10px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const ModalFooterButtons = styled.div<{
  row?: boolean;
  margin?: string;
  width?: string;
}>`
  display: flex;
  gap: 5px;
  width: ${({ width }) => width ?? "100%"};
  flex-direction: ${({ row }) => (row ? "row" : "column")};

  margin: ${({ margin }) => margin ?? "15px"};
`;
