import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { Platform } from "react-native";
import { Call } from "@twilio/voice-sdk";

interface StateType {
    visitId: string;
    tabId: string;
    start: DateTime;
    end: DateTime;
    connection: Call;
  }

export const CURRENT_TAB_ID = Platform.OS === "web" ? uuidv4() : "";

const initialState: StateType = {
    visitId: null,
    tabId: null,
    start: null,
    end: null,
    connection: null
  };

export const voiceCallSlice = createSlice({
    name: "voiceCall",
    initialState,
    reducers: {
        connectVoiceCall: (state, action) => {
            const { visitId, connection } = action.payload;
            state.visitId = visitId; 
            state.connection = connection;
            state.start = DateTime.now();
        },
        disconnectVoiceCall: (state) => {
            state.visitId = null;
            state.tabId = null;
            state.connection = null;
            state.end = DateTime.now();
        },
        resetStateVoiceCall: (state) => {
            state = initialState;
        }
    }
});

export const {
    connectVoiceCall,
    disconnectVoiceCall,
    resetStateVoiceCall
} = voiceCallSlice.actions;

export default voiceCallSlice.reducer;
