import { CircularProgress, Chip } from "@mui/material";
import { blue } from "common/styling/colors";
import { DateTime } from "luxon";

interface IProps {
    isFetchingPull: boolean;
    lastPulledData: DateTime;
}

const InsuranceInformationChipStatus = ({ isFetchingPull, lastPulledData }: IProps) => {
  if (isFetchingPull) return <CircularProgress size={18} />;
  if (lastPulledData && !isFetchingPull) {
    const days = Math.round(DateTime.now()?.diff(lastPulledData, ["days"]).days);
    return (
      <Chip
        sx={{ backgroundColor: blue[100], color: blue[700] }}
        label={`Last pulled ${days} ${days === 1 ? "day" : "days"} ago`}
      />
    );
  }
  return null;
}

export default InsuranceInformationChipStatus;
