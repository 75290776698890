import { useCallback, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { Button, Typography, styled } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { RootState, store } from "common/redux";
import RolesEnum, {
  canCreatePastEncounter,
  canDeleteEncounters,
  canEditIntakeEncounters,
  canEditTnEncounters,
  canSeeCareFlow,
  canSeeEncounterProviderDetails,
  canSeeEncountersOnStaffDetailsCurrentRole,
  getRoleLabel,
  hasIntakeNurseRole,
  hasProviderRole,
  hasTnRole
} from "common/enums/RolesEnum";

import MemberDetailsHeader from "./Header/MemberDetailsHeader";
import MemberDetailsLayout from "./MemberDetailsLayout";
import { Flexbox } from "../../styling/NewStyleComponents";

import Table from "../../components/Table/Table";
import {
  checkIdValid,
  formatName,
  getNameOrUsername,
  isFalsy
} from "common/helpers/helpers";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import ErrorComponent from "../../components/ErrorComponent";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import DeleteEncounterConfirmationModal from "./DeleteEncounterConfirmationModal";
import { useNavigate } from "react-router-dom";

// old endpoint
import { useGetEncountersQuery } from "common/services/EncountersService";
import EncounterLinkedEntitiesEnum from "common/enums/EncounterLinkedEntitiesEnum";
import DropdownDateRangePicker from "../../components/DropdownDateRangePicker";
import MemberType from "common/types/MemberType";
import { SubmitProviderEncounterModal } from "./Header/SubmitEncounter/SubmitProviderEncounterModal";
import NewAddPastEncounterModal from "../PatientData/NewAddPastEncounterModal";
import { TableColumn } from "../../components/Table/TableTypes";
import { NewProviderEncounterReasons } from "common/helpers/EncounterHelper";
import { EditTnEncounterModal } from "./Header/SubmitEncounter/EditTnEncounterModal";
import { EditEncounterCell } from "../../components/Table/helpers/EditEncounterCell";
import {
  Column,
  CustomTooltip,
  DefaultTableCell
} from "../../styling/StyleComponents";
import StyledIconButton from "../../components/Button/StyledIconButton";
import { blue } from "common/styling/colors";
import { CheckCircle, Delete, Diversity1, Edit } from "@mui/icons-material";
import {
  isEditDeleteAllowed,
  isSameDayEditDeleteAllowed,
  matchesTenDaySameMonthEncounterRestriction
} from "../../components/Table/helpers/TableHelpers";
import {
  dateWithRelative,
  UserNameCell
} from "../../components/Table/helpers/TableCells";
import { VisitMotivationTypesEnum_toString } from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import { getAthenaPatientUrl } from "common/helpers/EnvVarsHelper";
import { copyToClipboard } from "../../styling/CopyPatientLinkToClipboard";
import { Athena } from "../../assets/images/icons";
import { red } from "@mui/material/colors";

// new endpoint
// import { useGetEncounterVisitsQuery } from "common/services/VisitsService";

const MemberDetailsEncounterContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EncountersTitleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledColumn = styled(Column)`
  align-items: center;
  gap: 0px;
`;

const StyledAthenaIcon = styled(Athena)`
  height: 24px;
  width: 24px;
`;

export function getEncounterColumns(
  currentRole: RolesEnum,
  isMemberProfile: boolean,
  {
    navigate,
    handleDeleteEncounter,
    currentUserId = undefined,
    setSelectedEncounter,
    setEditPastProviderEncounterModalVisible,
    setEditPastTnEncounterModalVisible,
    hideDeleteEdit = undefined
  }
) {
  const arr: TableColumn[] = [
    {
      header: "Type",
      id: "encounterType",
      name: "encounterType",
      accessor: "type",
      func: "accessor",
      size: 220,
      cell: ({ getValue, row }: any) => {
        const value = getValue();
        const motivationReason = row?.original?.visit?.motivation_reason;

        return (
          <DefaultTableCell>
            <Typography variant="body1">{value}</Typography>
            {!isFalsy(motivationReason) && (
              <Typography variant="body2">
                {VisitMotivationTypesEnum_toString(motivationReason)}
              </Typography>
            )}
          </DefaultTableCell>
        );
      }
    }
  ];

  arr.push({
    header: "Duration",
    id: "encounterDurationEditableNew",
    name: "encounterDurationEditableNew",
    accessor: "time.duration",
    func: "accessor",
    sortingFn: "numberSorting",
    size: 70,
    cell: ({ getValue, row }: any) => {
      const value = getValue();

      const isProviderEncounter = hasProviderRole(
        row?.original?.encounter?.submitter_roles ??
          row?.original?.visit?.submitted_by_roles ??
          row?.original?.user?.roles
      );

      const isTnEncounter = hasTnRole(
        row?.original?.encounter?.submitter_roles ??
          row?.original?.visit?.submitted_by_roles ??
          row?.original?.user?.roles
      );

      const isIntakeNurseEncounter = hasIntakeNurseRole(
        row?.original?.encounter?.submitter_roles ??
          row?.original?.visit?.submitted_by_roles ??
          row?.original?.user?.roles
      );

      const editable =
        !hideDeleteEdit &&
        // use submitted_by instead of created_by, created_by is the user who created the encounter which could be different
        // for add past encounter
        // provider and intake nurse encounter logic - only the person who submitted the encounter can edit it
        ((isProviderEncounter &&
          (row?.original?.encounter?.submitted_by === currentUserId ||
            row?.original?.encounter?.requested_by === currentUserId) &&
          isSameDayEditDeleteAllowed(row?.original?.encounter?.starts_on)) ||
          // tn encounter logic - tn managers can edit TN encounters- ENG-7567
          (isTnEncounter &&
            // tn encounter logic - tn managers can edit TN encounters- ENG-7567
            ((canEditTnEncounters(currentRole) &&
              matchesTenDaySameMonthEncounterRestriction(
                row?.original?.encounter?.starts_on
              )) ||
              // nurses can edit their own encounters on the day of
              (row?.original?.encounter?.submitted_by === currentUserId &&
                isSameDayEditDeleteAllowed(
                  row?.original?.encounter?.starts_on
                )))) ||
          // Intake Nurse encounter logic
          (isIntakeNurseEncounter &&
            // in encounter logic - tn managers can edit IN encounters- ENG-7567
            ((canEditIntakeEncounters(currentRole) &&
              matchesTenDaySameMonthEncounterRestriction(
                row?.original?.encounter?.starts_on
              )) ||
              (row?.original?.encounter?.submitted_by === currentUserId &&
                isSameDayEditDeleteAllowed(
                  row?.original?.encounter?.starts_on
                )))));

      if (isProviderEncounter && row?.original?.encounter) {
        const complexityParsed = row?.original?.encounter?.complexity?.replace(
          "CPT_",
          ""
        );
        const selectedEncounter = {
          member_name: row?.original?.fullname,
          total_time: row?.original?.encounter?.duration,
          visit_type: row?.original?.encounter?.reason,
          modality: row?.original?.encounter?.modality,
          complexity: complexityParsed,
          encounter_id: row?.original?.encounter?.encounter_id,
          video_call_decline_reason:
            row?.original?.encounter?.video_call_decline_reason,
          patient_location: row?.original?.encounter?.patient_location,
          not_at_home_detail: row?.original?.encounter?.not_at_home_detail
        };

        return (
          <Flexbox alignItems="center">
            <DefaultTableCell>{value}</DefaultTableCell>
            {editable && (
              <StyledIconButton
                Icon={Edit}
                sx={{ marginLeft: 1 }}
                iconColor={blue[700]}
                onClick={() => {
                  setSelectedEncounter(selectedEncounter);
                  setEditPastProviderEncounterModalVisible(true);
                }}
                color={"transparent"}
                size={"small"}
              />
            )}
          </Flexbox>
        );
      }

      if (isTnEncounter && row?.original?.encounter) {
        const selectedEncounter = {
          member_name: row?.original?.fullname,
          total_time: row?.original?.encounter?.duration,
          visit_type: row?.original?.encounter?.reason,
          encounter_id: row?.original?.encounter?.encounter_id
        };

        return (
          <Flexbox alignItems="center">
            <DefaultTableCell>{value}</DefaultTableCell>
            {editable && (
              <StyledIconButton
                Icon={Edit}
                sx={{ marginLeft: 1 }}
                iconColor={blue[700]}
                onClick={() => {
                  setSelectedEncounter(selectedEncounter);
                  setEditPastTnEncounterModalVisible(true);
                }}
                color={"transparent"}
                size={"small"}
              />
            )}
          </Flexbox>
        );
      }

      if (!value) {
        return <DefaultTableCell>N/A</DefaultTableCell>;
      }

      return <EditEncounterCell editable={editable} value={value} row={row} />;
    }
  });

  arr.push({
    header: "Date",
    id: "encounterDateV2",
    name: "encounterDateV2",
    // accessor for PAB encounters endpoint
    accessor: "encounter.starts_on",
    // accessor for encounter visits endpoint - comment this back in when we want to use this endpoint
    // accessor: "starts_on",
    func: "accessor",
    sortingFn: "dateTimeSortingISO",
    size: 70,
    cell: ({ getValue }: any) => {
      const value = getValue();
      const date = DateTime.fromISO(value);

      return dateWithRelative({ date });
    }
  });
  if (isMemberProfile) {
    arr.push({
      header: "Team Member",
      id: "encounterSubmitterV2",
      name: "encounterSubmitterV2",
      // accessor for PAB encounters endpoint
      accessor: "submitter",
      // accessor for encounter visits endpoint - comment this back in when we want to use this endpoint
      // accessor: "user",
      func: "accessor",
      size: 180,
      sortingFn: (a, b) => {
        const labelA = getNameOrUsername(a.original?.submitter);
        const labelB = getNameOrUsername(b.original?.submitter);
        return labelA?.localeCompare(labelB);
      },
      cell: ({ getValue }: any) => {
        const value = getValue();
        const displayValue = getNameOrUsername(value);

        const roles = [];
        value?.roles?.forEach((role) => {
          const roleLabel = getRoleLabel(role);
          // MEMBER_CARE_SPECIALIST role on Adam Admin user account is causing a crash
          if (!isFalsy(roleLabel)) {
            roles.push(roleLabel);
          }
        });

        const roleString = roles.slice(0, 3).join(", ");

        const isMe = value?.user_id === store.getState().auth.user.user_id;

        const link = `/staff/staffId/${value?.user_id}/encounters`;
        const canSeeEncountersOnStaffDetails =
          canSeeEncountersOnStaffDetailsCurrentRole(currentRole);
        return (
          <DefaultTableCell
            style={{
              overflowWrap: "anywhere",
              cursor: canSeeEncountersOnStaffDetails && "pointer"
            }}
            onClick={() => {
              if (canSeeEncountersOnStaffDetails) navigate(link);
            }}
          >
            <Typography color="primary" variant="body1">
              {displayValue ?? "User no longer exists."} {isMe && "(me)"}
            </Typography>
            <Typography color="secondary" variant="body1" fontSize={12}>
              {roleString ?? "N/A"}
            </Typography>
          </DefaultTableCell>
        );
      }
    });
  } else {
    arr.push({
      header: "Member",
      id: "encounterMember",
      name: "encounterMember",
      accessor: "patient",
      func: "accessor",
      sortingFn: (a, b) => {
        const labelA = getNameOrUsername(a.original.patient);
        const labelB = getNameOrUsername(b.original.patient);
        return labelA.localeCompare(labelB);
      },
      size: 180,
      cell: ({ getValue, row, column: { id }, table }: any) => {
        const value = getValue();
        const displayValue = getNameOrUsername(value);

        return (
          <UserNameCell
            link={`/members/memberId/${value?.patient_id}/encounters`}
          >
            {displayValue}
          </UserNameCell>
        );
      }
    });
  }

  if (canSeeEncounterProviderDetails(currentRole)) {
    arr.push({
      header: "Modality",
      name: "encounterModality",
      id: "encounterModality",
      accessor: "encounter.modality",
      func: "accessor",
      size: 65,
      cell: ({ getValue, row, column: { id }, table }: any) => {
        const value = getValue();
        return (
          <DefaultTableCell data-testid={value}>
            {formatName(value)}
          </DefaultTableCell>
        );
      }
    });
    arr.push({
      header: "Complexity",
      id: "encounterComplexity",
      name: "encounterComplexity",
      accessor: "encounter.complexity",
      func: "accessor",
      size: 80,
      cell: ({ getValue, row, column: { id }, table }: any) => {
        const value = getValue();

        if (!value) {
          return <DefaultTableCell>N/A</DefaultTableCell>;
        }
        return <DefaultTableCell>{formatName(value)}</DefaultTableCell>;
      }
    });
    arr.push({
      header: "Video Call Decline Reason",
      id: "encounterVideoCallDeclineReason",
      name: "encounterVideoCallDeclineReason",
      accessor: "encounter.video_call_decline_reason",
      func: "accessor",
      size: 90,
      cell: ({ getValue, row, column: { id }, table }: any) => {
        const value = getValue();

        if (!value) {
          return <DefaultTableCell>N/A</DefaultTableCell>;
        }
        return <DefaultTableCell>{formatName(value)}</DefaultTableCell>;
      }
    });
    arr.push({
      header: "Patient Physical Location",
      id: "patientPhysicalLocation",
      name: "patientPhysicalLocation",
      accessor: "encounter.patient_location",
      func: "accessor",
      size: 150,
      cell: ({ getValue, row, column: { id }, table }: any) => {
        const value = getValue();

        if (!value) {
          return <DefaultTableCell>N/A</DefaultTableCell>;
        }

        if (value === "NOT_AT_HOME") {
          const notAtHomeDetail = row?.original?.encounter?.not_at_home_detail;
          return (
            <DefaultTableCell>
              Not at Home - {formatName(notAtHomeDetail)}
            </DefaultTableCell>
          );
        }
        return <DefaultTableCell>{formatName(value)}</DefaultTableCell>;
      }
    });
  }

  arr.push({
    header: "Actions",
    id: "encounterActions",
    name: "encounterActions",
    accessor: "",
    func: "accessor",
    enableSorting: false,
    size: 120,
    cell: ({ row }: any) => {
      const encounterId = row?.original?.encounter?.["encounter_id"];

      const isDeleteWithinTenDaysAllowed =
        canDeleteEncounters(currentRole) &&
        matchesTenDaySameMonthEncounterRestriction(
          row?.original?.encounter?.starts_on
        );

      const isDeleteAllowed =
        isEditDeleteAllowed(row?.original?.encounter?.starts_on) &&
        // use submitted_by instead of created_by, created_by is the user who created the encounter which could be different
        // for add past encounter
        (row?.original?.encounter?.submitted_by === currentUserId ||
          row?.original?.encounter?.requested_by === currentUserId ||
          currentRole === RolesEnum.ADMIN);

      const isDeleteEncounterAllowed = isDeleteWithinTenDaysAllowed
        ? isDeleteWithinTenDaysAllowed
        : isDeleteAllowed;

      // const encounterId = row?.original?.visit?.["visit_id"];

      // const isDeleteAllowed =
      //   isEditDeleteAllowed(row?.original?.visit?.encounter_started_on) &&
      //   // use submitted_by instead of created_by, created_by is the user who created the encounter which could be different
      //   // for add past encounter
      //   (row?.original?.visit?.submitted_by === currentUserId ||
      //     currentRole === RolesEnum.ADMIN);

      const athenaId = row?.original?.patient?.external_accounts?.athena;

      const [copiedToClipboard, setCopiedToClipboard] =
        useState<boolean>(false);

      return (
        <DefaultTableCell
          style={{
            overflowWrap: "anywhere",
            alignItems: "center",
            display: "flex",
            gap: "5px"
          }}
        >
          <Row style={{ gap: "10px" }}>
            {athenaId && (
              <>
                {copiedToClipboard ? (
                  <CheckCircle color={"success"} style={{ margin: "9px" }} />
                ) : (
                  <CustomTooltip
                    title={"Copy a link to the member's Athena profile"}
                    placement="left"
                  >
                    <div>
                      <StyledIconButton
                        Icon={ContentCopyIcon}
                        iconColor={blue[700]}
                        border="square"
                        onClick={(e) => {
                          e.stopPropagation();
                          const athenaUrl = getAthenaPatientUrl(athenaId);

                          copyToClipboard(athenaUrl).then((result) => {
                            if (result) {
                              setCopiedToClipboard(true);
                              setTimeout(() => {
                                setCopiedToClipboard(false);
                              }, 1000);
                            }
                          });
                        }}
                      />
                    </div>
                  </CustomTooltip>
                )}
                <CustomTooltip title={"Go to member’s Athena profile"}>
                  <div>
                    <StyledColumn
                      sx={{ cursor: "pointer" }}
                      onClick={() => window.open(getAthenaPatientUrl(athenaId))}
                    >
                      <StyledAthenaIcon />
                      {athenaId}
                    </StyledColumn>
                  </div>
                </CustomTooltip>
              </>
            )}
            {canSeeCareFlow(currentRole) &&
              row?.original?.visit?.has_care_flow && (
                <CustomTooltip title={"View this task"}>
                  <div>
                    <StyledIconButton
                      Icon={Diversity1}
                      iconColor={blue[700]}
                      border="square"
                      onClick={(e) => {
                        navigate(`/visits/${row?.original?.visit?.visit_id}`);
                      }}
                    />
                  </div>
                </CustomTooltip>
              )}
            {isDeleteEncounterAllowed && !hideDeleteEdit && (
              <StyledIconButton
                Icon={Delete}
                testId={`deleteIcon-${row?.original?.time?.duration}`}
                iconColor={red[700]}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteEncounter(encounterId);
                }}
                color={"transparent"}
              />
            )}
          </Row>
        </DefaultTableCell>
      );
    }
  });

  return arr;
}

export const EncountersTable = ({
  member,
  hideDeleteEdit = false
}: {
  member: MemberType;
  hideDeleteEdit?: boolean;
}) => {
  const { currentRole, user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const defaultStartsAfter = useMemo(
    () => DateTime.now().minus({ days: 7 }).startOf("day"),
    []
  );
  const defaultStartsBefore = useMemo(() => DateTime.now().endOf("day"), []);

  const [selectedEncounterId, setSelectedEncounterId] = useState<string>("");
  const [startsAfter, setStartsAfter] = useState<DateTime>(defaultStartsAfter);
  const [startsBefore, setStartsBefore] =
    useState<DateTime>(defaultStartsBefore);
  const [
    editPastProviderEncounterModalVisible,
    setEditPastProviderEncounterModalVisible
  ] = useState<boolean>(false);
  const [editPastTnEncounterModalVisible, setEditPastTnEncounterModalVisible] =
    useState<boolean>(false);
  const [
    addPastProviderEncounterModalVisible,
    setAddPastProviderEncounterModalVisible
  ] = useState<boolean>(false);
  const [isDeleteEncounterModalOpen, setIsDeleteEncounterModalOpen] =
    useState<boolean>(false);
  const closeDeleteEncounterModal = () => {
    setIsDeleteEncounterModalOpen(false);
    setSelectedEncounterId("");
  };

  const [selectedEncounter, setSelectedEncounter] = useState(undefined);
  const handleDeleteEncounter = (encounter_id: string) => {
    setSelectedEncounterId(encounter_id);
    setIsDeleteEncounterModalOpen(true);
  };
  const setEditProviderModal = useCallback(
    (visible: boolean) => {
      if (!visible) setSelectedEncounter(undefined);
      setEditPastProviderEncounterModalVisible(visible);
    },
    [setSelectedEncounter, setEditPastProviderEncounterModalVisible]
  );

  const setEditTnModal = useCallback(
    (visible: boolean) => {
      if (!visible) setSelectedEncounter(undefined);
      setEditPastTnEncounterModalVisible(visible);
    },
    [setSelectedEncounter, setEditPastTnEncounterModalVisible]
  );

  const tableProps = {
    navigate,
    handleDeleteEncounter,
    currentRole,
    currentUserId: user?.user_id,
    setSelectedEncounter,
    setEditPastProviderEncounterModalVisible,
    setEditPastTnEncounterModalVisible,
    hideDeleteEdit
  };
  const encounterColumns = getEncounterColumns(currentRole, true, tableProps);

  const {
    data: encounterVisits,
    isFetching: encounterVisitsIsFetching,
    error: encounterVisitsError
  } = useGetEncountersQuery(
    {
      patient_id: member?.patient?.patient_id,
      startsAfter: startsAfter,
      startsBefore: startsBefore,
      linked_entities: [EncounterLinkedEntitiesEnum.VISIT]
    },
    { skip: isFalsy(member?.patient) }
  );

  return (
    <>
      {canCreatePastEncounter(currentRole) && !hideDeleteEdit && (
        <Button
          variant="outlined"
          onClick={() => setAddPastProviderEncounterModalVisible(true)}
        >
          Add Past Encounter
        </Button>
      )}
      <EncountersTitleContainer>
        <Typography variant="h4" mt="16px">
          Encounters History
        </Typography>

        <DropdownDateRangePicker
          onEndDateChange={setStartsBefore}
          onStartDateChange={setStartsAfter}
          endDate={startsBefore}
          startDate={startsAfter}
          dateRangeDefault="30_days"
        />
      </EncountersTitleContainer>
      {encounterVisits && !encounterVisitsIsFetching && (
        <Table
          tableColumns={encounterColumns}
          data={encounterVisits}
          tableProps={tableProps}
        />
      )}
      {encounterVisitsIsFetching && <LoadingFallback count={10} />}
      {encounterVisitsError && <ErrorComponent error={encounterVisitsError} />}

      {/* We are only using this for NP encounters right now */}
      <SubmitProviderEncounterModal
        key={`neweditEncounter-${selectedEncounter?.encounter_id}`}
        modalOpen={
          editPastProviderEncounterModalVisible && !isFalsy(selectedEncounter)
        }
        setModalOpen={setEditProviderModal}
        memberId={member?.patient?.patient_id}
        isProvider={true}
        encounterReasons={NewProviderEncounterReasons}
        memberName={selectedEncounter?.member_name}
        selectedEncounter={selectedEncounter}
        hasProviderSubmittedEncounter={true}
        hasInvalidProviderAssigned={false}
      />
      <EditTnEncounterModal
        key={`neweditTnEncounter-${selectedEncounter?.encounter_id}`}
        modalOpen={
          editPastTnEncounterModalVisible && !isFalsy(selectedEncounter)
        }
        setModalOpen={setEditTnModal}
        selectedEncounter={selectedEncounter}
      />
      <NewAddPastEncounterModal
        patient={member}
        isOpen={addPastProviderEncounterModalVisible}
        onRequestClose={() => setAddPastProviderEncounterModalVisible(false)}
        historicalEncounters={encounterVisits}
      />
      <DeleteEncounterConfirmationModal
        isOpen={isDeleteEncounterModalOpen}
        onRequestClose={() => closeDeleteEncounterModal()}
        encounterId={selectedEncounterId}
      />
      <br />
    </>
  );
};

const MemberDetailsEncounters = ({
  hideReadings = false
}: {
  hideReadings?: boolean;
}) => {
  const params = useSanitizedParams();
  const { memberId } = params;
  const isValidId = checkIdValid(memberId);

  const { data: member } = useGetMemberWithUsernameQuery(
    {
      username: memberId
    },
    { skip: isValidId === false }
  );

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return (
    <MemberDetailsEncounterContainer>
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="hidden"
      >
        <MemberDetailsHeader key={`${memberId}-header-encounters`} />
        <MemberDetailsLayout
          key={`${memberId}-layout-encounters`}
          memberId={memberId}
          hideReadings={hideReadings}
        >
          <br />
          <EncountersTable member={member} />
        </MemberDetailsLayout>
      </Flexbox>
    </MemberDetailsEncounterContainer>
  );
};

export default MemberDetailsEncounters;
