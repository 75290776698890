import { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";

import {
  StyledModal,
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader
} from "../../styling/StyleModal";

import { TurqoiseButton, WhiteButton } from "../../styling/StyleComponents";
import { PASSWORD_VALIDATION_REGEXP } from "common/helpers/helpers";

import ErrorComponent from "../../components/ErrorComponent";
import PasswordInput from "../../components/Input/PasswordInput";
import { useResetPasswordMutation } from "common/services/UserService";
import { Typography } from "@mui/material";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface FormType {
  new_password: string;
  new_password_repeat: string;
}

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  username: string;
}

const ResetPasswordModal = ({ isOpen, onRequestClose, username }: IProps) => {
  const [resetPasswordMutation, { isError, isLoading, error, isSuccess }] =
    useResetPasswordMutation();
  const dispatch = useAppDispatch();

  const validate = (values: FormType) => {
    const errors = {};

    if (!values["new_password"]) {
      errors["new_password"] = "Required";
    } else if (!PASSWORD_VALIDATION_REGEXP.test(values["new_password"])) {
      errors["new_password"] =
        "Password must contain at least 12 characters, one uppercase, one number and one special case character";
    }
    if (!values["new_password_repeat"]) {
      errors["new_password_repeat"] = "Required";
    } else if (values["new_password"] !== values["new_password_repeat"]) {
      errors["new_password_repeat"] = "Passwords don't match.";
    }

    return errors;
  };

  const timerRef = useRef(null);

  useEffect(() => {
    if (isSuccess) {
      onRequestClose();
      timerRef.current = setTimeout(() => {
        Alert_show({
          dispatch,
          title: "Success",
          id: "resetPasswordSuccess",
          content: <>Successfully changed password.</>,
          size: "small",
          buttons: [
            {
              text: "Close",
              onPress: () =>
                Alert_close({ dispatch, id: "resetPasswordSuccess" }),
              style: "default"
            }
          ]
        });
      }, 300);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [isSuccess]);

  const onSubmit = async (values) => {
    await resetPasswordMutation({
      username,
      new_password: values?.["new_password"]
    });
  };

  const {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    dirty,
    isValid,
    handleReset
  } = useFormik<FormType>({
    initialValues: {
      new_password: "",
      new_password_repeat: ""
    },
    validate,
    onSubmit
  });

  useEffect(() => {
    // reset form on modal close
    if (!isOpen) {
      handleReset(null);
    }
  }, [isOpen]);

  return (
    <StyledModal
      isOpen={isOpen}
      contentLabel="Reset Password Modal"
      modalHeight="70vh"
      onRequestClose={onRequestClose}
    >
      <Form onSubmit={handleSubmit}>
        <ModalHeader onRequestClose={onRequestClose}>
          Reset Password
        </ModalHeader>
        <ModalBody>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Enter new password for {username}:
          </Typography>

          <div>
            <PasswordInput
              label="New Password"
              value={values.new_password}
              onChange={(event) =>
                setFieldValue("new_password", event.target.value)
              }
              error={errors["new_password"] !== undefined}
              helperText={errors["new_password"]}
              fullWidth
            />
            <br />
            <br />
            <PasswordInput
              label="Confirm New Password"
              value={values.new_password_repeat}
              onChange={(event) =>
                setFieldValue("new_password_repeat", event.target.value)
              }
              error={errors["new_password_repeat"] !== undefined}
              helperText={errors["new_password_repeat"]}
              fullWidth
            />

            {isError && (
              <>
                <br />
                <ErrorComponent error={error} />
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons>
            <TurqoiseButton
              loading={isLoading}
              disabled={!dirty || !isValid}
              type="submit"
            >
              Reset
            </TurqoiseButton>
            <WhiteButton onClick={onRequestClose}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </Form>
    </StyledModal>
  );
};

export default ResetPasswordModal;
