import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import { useGetReadingsTrendsByMemberQuery } from "common/services/ReadingsService";
import MemberType from "common/types/MemberType";
import { useEffect, useState } from "react";
import { isFalsy } from "common/helpers/helpers";
import { useAppDispatch } from "common/redux";
import { Column, StatusBadge } from "../../../styling/StyleComponents";
import { Box, LinearProgress, Typography } from "@mui/material";
import { hasDiabetes, hasHypertension } from "common/types/Visits/CarePlanType";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { gray } from "common/styling/colors";
import { useCollapse } from "react-collapsed";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import VisitType from "common/types/Visits/VisitType";
import GlucoseChart from "../../MemberDetails/Readings/GlucoseChart";
import VisitFieldMemberReadingsReview from "./VisitFieldMemberReadingsReview";
import BloodPressureChart from "../../MemberDetails/Readings/BloodPressureChart";
import { forceTrendAnswers } from "./PreCall/ReviewCarePlan/ReadingStatCards";

interface IProps {
  member: MemberType;
  visit: VisitType;
}

const DELAY_REVIEW_READINGS = 3000;

const VisitFieldReadingTrends = ({ member }: IProps) => {
  const dispatch = useAppDispatch();

  const [pause, setPause] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(10);
  const [bpDateFilter, setBPDateFilter] = useState<number>(7);
  const [glucoseDateFilter, setGlucoseDateFilter] = useState<number>(7);

  const { data, isLoading, isSuccess } = useGetReadingsTrendsByMemberQuery(
    {
      memberId: member?.patient?.patient_id,
      paramsObject: {
        device_type: [
          DeviceTrendParam.BLOOD_PRESSURE,
          DeviceTrendParam.GLUCOSE
        ],
        trend_days: [7, 30]
      }
    },
    { skip: isFalsy(member?.patient?.patient_id) }
  );

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress !== 100)
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 25
        );
    }, 600);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setPause(false);
      forceTrendAnswers({ data, dispatch });
    }, DELAY_REVIEW_READINGS);
    setTimeout(() => {
      forceTrendAnswers({ data, dispatch });
    }, DELAY_REVIEW_READINGS * 2);
  }, [data, isSuccess]);
  const {
    getCollapseProps: bpCollapseProps,
    getToggleProps: bpToggleProps,
    isExpanded: isBPExpanded
  } = useCollapse({
    defaultExpanded: false
  });

  const {
    getCollapseProps: glucoseCollapseProps,
    getToggleProps: glucoseToggleProps,
    isExpanded: isGlucoseExpanded
  } = useCollapse({
    defaultExpanded: false
  });

  return (
    <Column>
      {pause ? (
        <Box margin={"30px 0px"}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="caption" textAlign={"center"}>
            Loading reading data...
          </Typography>
        </Box>
      ) : (
        <>
          <Typography variant="body1" color={gray[900]} marginBottom={"10px"}>
            The member has the following conditions:
          </Typography>
          <Flexbox gap={"10px"} mb={"10px"}>
            {member?.care_plan?.conditions?.map((item) => {
              return <StatusBadge key={item} status={item} hideDot={true} />;
            })}
            {(member?.care_plan == null ||
              member?.care_plan?.conditions?.length === 0) && (
              <Typography
                variant="h6"
                color="text.secondary"
                textAlign={"center"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                The member has no conditions
              </Typography>
            )}
          </Flexbox>
          <VisitFieldMemberReadingsReview />
        </>
      )}
      {!pause && !isLoading && hasDiabetes(member) && (
        <>
          <Box
            display={"flex"}
            sx={{ cursor: "pointer" }}
            {...glucoseToggleProps()}
          >
            {isGlucoseExpanded ? (
              <ExpandLess
                fontSize={"small"}
                sx={{ margin: "15px 5px 10px 0px" }}
              />
            ) : (
              <ExpandMore
                fontSize={"small"}
                sx={{ margin: "15px 5px 10px 0px" }}
              />
            )}
            <Typography
              variant="h6"
              color={gray[900]}
              textTransform={"uppercase"}
              margin={"15px 5px 10px 0px"}
            >
              Review Glucose Readings
            </Typography>
          </Box>
          <Column {...glucoseCollapseProps()}>
            <GlucoseChart
              patient={member}
              defaultDays={7}
              dateFilter={glucoseDateFilter}
              hideNonDateControls={true}
              showTableOverride={true}
              handleDataChange={({ dateFilter }) =>
                setGlucoseDateFilter(dateFilter)
              }
            />
          </Column>
        </>
      )}
      {!pause && !isLoading && hasHypertension(member) && (
        <>
          <Box display={"flex"} sx={{ cursor: "pointer" }} {...bpToggleProps()}>
            {isBPExpanded ? (
              <ExpandLess
                fontSize={"small"}
                sx={{ margin: "15px 5px 10px 0px" }}
              />
            ) : (
              <ExpandMore
                fontSize={"small"}
                sx={{ margin: "15px 5px 10px 0px" }}
              />
            )}
            <Typography
              variant="h6"
              color={gray[900]}
              textTransform={"uppercase"}
              margin={"15px 5px 10px 0px"}
            >
              Review Blood Pressure Readings
            </Typography>
          </Box>
          <Column {...bpCollapseProps()}>
            <BloodPressureChart
              patient={member}
              defaultDays={7}
              dateFilter={bpDateFilter}
              hideNonDateControls={true}
              showTableOverride={true}
              handleDataChange={({ dateFilter }) => setBPDateFilter(dateFilter)}
            />
          </Column>
        </>
      )}
    </Column>
  );
};

export default VisitFieldReadingTrends;
