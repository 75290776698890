import styled from "@emotion/styled";
import { useFormik } from "formik";
import {
  Box,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { TurqoiseButton, WhiteButton } from "../../../../styling";
import ErrorComponent from "../../../../components/ErrorComponent";
import { DateTime } from "luxon";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../../../styling/StyleModal";
import VisitReasonsEnum from "common/enums/Calendaring/Visits/VisitReasonsEnum";
import { useCallback, useEffect, useMemo } from "react";
import {
  Alert_close,
  Alert_loading,
  Alert_show
} from "common/helpers/AlertHelper";
import { RootState, useAppDispatch } from "common/redux";
import CommunicationTypeEnum from "common/enums/Calendaring/CommunicationTypeEnum";
import ComplexityEnum from "common/enums/Calendaring/Appointments/ComplexityEnum";
import { Flexbox } from "../../../../styling/NewStyleComponents";
import {
  DomainDisabledOutlined,
  DomainOutlined,
  InfoOutlined,
  PhoneOutlined,
  VideocamOutlined
} from "@mui/icons-material";
import { gray } from "common/styling/colors";
import NumberInput from "../../../../components/Input/NumberInput";
import { CustomTooltip } from "../../../../styling/StyleComponents";
import {
  getComplexityTypes,
  PatientNotAtHomeDropdownOptions,
  VideoCallDeclineReasons
} from "common/helpers/EncounterHelper";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import { ProviderRoles } from "common/enums/RolesEnum";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import UserLinkedEntitiesEnum from "common/enums/UserLinkedEntitiesEnum";
import { useUpdateMemberCarersMutation } from "common/services/MemberRegistrationService";
import LocalizedStrings from "common/localizations/LocalizedStrings";
import { useSelector } from "react-redux";
import {
  useCreateEncounterMutation,
  useUpdateEncounterMutation
} from "common/services/EncountersService";
import VideoCallDeclineReasonEnum from "common/enums/Calendaring/Visits/VideoCallDeclineReasonEnum";
import { isTruthy } from "common/helpers/helpers";
import SelectedEncounterType from "common/types/SelectedEncounterType";
import EncounterPatientNotAtHomeDetailEnum from "common/enums/Encounters/EncounterPatientNotAtHomeDetailEnum";
import EncounterPatientLocationEnum from "common/enums/Encounters/EncounterPatientLocationEnum";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface EncounterReason {
  label: string;
  value: VisitReasonsEnum;
}

interface IProps {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  memberId: string | undefined;
  isProvider: boolean;
  encounterReasons: EncounterReason[];
  memberName: string;
  hasProviderSubmittedEncounter: boolean;
  hasInvalidProviderAssigned: boolean;
  selectedEncounter?: SelectedEncounterType;
}

interface FormValues {
  total_time: number;
  visit_type: VisitReasonsEnum;
  modality: CommunicationTypeEnum;
  complexity: ComplexityEnum;
  video_call_decline_reason: VideoCallDeclineReasonEnum;
  patient_location?: EncounterPatientLocationEnum;
  not_at_home_detail?: EncounterPatientNotAtHomeDetailEnum;
}

export const SubmitProviderEncounterModal = ({
  modalOpen,
  setModalOpen,
  memberId,
  isProvider,
  encounterReasons,
  memberName,
  hasProviderSubmittedEncounter,
  hasInvalidProviderAssigned,
  selectedEncounter
}: IProps) => {
  const default_total_time = useMemo(() => selectedEncounter?.total_time, []);
  const default_visit_type = useMemo(() => selectedEncounter?.visit_type, []);
  const default_modality = useMemo(() => selectedEncounter?.modality, []);
  const default_complexity = useMemo(() => selectedEncounter?.complexity, []);
  const default_video_call_decline_reason = useMemo(
    () => selectedEncounter?.video_call_decline_reason,
    []
  );
  const default_patient_location = useMemo(
    () => selectedEncounter?.patient_location,
    []
  );
  const default_not_at_home_detail = useMemo(
    () => selectedEncounter?.not_at_home_detail,
    []
  );
  const selected_encounter_id = useMemo(
    () => selectedEncounter?.encounter_id,
    [selectedEncounter]
  );
  const dispatch = useAppDispatch();
  const { user, currentRole } = useSelector((state: RootState) => state.auth);
  const currentUserIsProvider = ProviderRoles.includes(currentRole);

  const { data: authenticatedUser } = useGetAuthenticatedUser({
    linkedEntities: [UserLinkedEntitiesEnum.METADATA]
  });

  const departmentId = authenticatedUser?.metadata?.athena_dpt_id;

  const [
    createEncounter,
    {
      data: createEncounterData,
      isSuccess: createEncounterSuccess,
      fulfilledTimeStamp: createEncounterFulfilledTimestamp,
      isLoading: createEncounterLoading,
      error: createEncounterError,
      reset: resetEndAppointment
    }
  ] = useCreateEncounterMutation({});

  const [
    updateEncounterMutation,
    {
      isError: updateEncounterIsError,
      isLoading: updateEncounterIsLoading,
      error: updateEncounterError,
      isSuccess: updateEncounterIsSuccess
    }
  ] = useUpdateEncounterMutation();

  const [
    updatePatientCarers,
    {
      error: updateProviderError,
      isSuccess: isupdateProviderSuccess,
      isError: isUpdateProviderError
    }
  ] = useUpdateMemberCarersMutation();

  useEffect(() => {
    const id = "updateProviderAssignment";
    if (isupdateProviderSuccess) {
      Alert_close({ dispatch, id });
      resetEndAppointment();
    } else if (isUpdateProviderError) {
      Alert_close({ dispatch, id });
      resetEndAppointment();

      const totalTime = createEncounterData?.duration;

      if (totalTime && departmentId) {
        Alert_show({
          dispatch,
          id,
          title: "Update Provider Assignment",
          content: (
            <>
              <div>
                Successfully submitted {totalTime} minutes for this member.
                <br /> Should you be{" "}
                <b data-testid="usualProviderMessage">
                  set as the usual provider for {memberName}
                </b>
                {/* */}?
              </div>
              {isUpdateProviderError && (
                // @ts-ignore
                <ErrorComponent error={updateProviderError} />
              )}
            </>
          ),
          type: "warning",
          size: "small",
          buttons: [
            {
              text: LocalizedStrings.cancel,
              style: "cancel",
              onPress: () => {
                Alert_close({ dispatch, id });
                resetEndAppointment();
              }
            },
            {
              text: LocalizedStrings.submit,
              style: "default",
              onPress: async () => {
                await updateProviderHandler(user?.user_id);
              }
            }
          ]
        });
      }
    }
  }, [isupdateProviderSuccess, updateProviderError]);

  useEffect(() => {
    if (
      createEncounterSuccess &&
      currentUserIsProvider &&
      (!hasProviderSubmittedEncounter || hasInvalidProviderAssigned)
    ) {
      handleModalClose();
      const totalTime = createEncounterData?.duration;

      if (totalTime) {
        const id = "updateProviderAssignment";
        Alert_show({
          dispatch,
          id,
          title: "Update Provider Assignment",
          content: (
            <div>
              Successfully submitted {totalTime} minutes for this member. Should
              you be{" "}
              <b data-testid="usualProviderMessage">
                set as the usual provider for {memberName}
              </b>
              {/* */}?
            </div>
          ),
          type: "warning",
          size: "medium",
          buttons: [
            {
              text: LocalizedStrings.cancel,
              style: "cancel",
              onPress: () => {
                Alert_close({ dispatch, id });
                resetEndAppointment();
              }
            },
            {
              text: LocalizedStrings.submit,
              style: "default",
              onPress: async () => {
                Alert_loading({ dispatch, id });
                await updateProviderHandler(user?.user_id);
                Alert_close({ dispatch, id });
                resetEndAppointment();
              },
              hasLoadingState: true
            }
          ]
        });
      }
    } else if (createEncounterSuccess) {
      handleModalClose();

      const id = "submitTimeSuccess";
      Alert_show({
        dispatch,
        id,
        title: "Success! Time Submitted",
        content: (
          <>
            Your time was successfully submitted! <br />
          </>
        ),
        type: "success",
        size: "small",
        buttons: [
          {
            text: "Done",
            style: "cancel",
            onPress: () => Alert_close({ dispatch, id })
          }
        ]
      });
    }
  }, [
    createEncounterSuccess,
    createEncounterData,
    memberName,
    hasProviderSubmittedEncounter,
    hasInvalidProviderAssigned
  ]);

  useEffect(() => {
    if (updateEncounterIsError) {
      const id = "updateError";
      Alert_show({
        dispatch,
        id,
        title: "Something went wrong updating the encounter.",
        content: (
          <ErrorComponent
            error={updateEncounterError}
            showErrorResponseMessage
            hideErrorCode
          />
        ),
        size: "small",
        type: "warning"
      });
    }
  }, [updateEncounterIsError, updateEncounterError]);

  useEffect(() => {
    if (updateEncounterIsSuccess) {
      handleModalClose();
    }
  }, [updateEncounterIsSuccess]);

  async function updateProviderHandler(provider_id: string) {
    await updatePatientCarers({
      patient_id: memberId,
      carers: {
        provider_id: provider_id
      }
    });
  }

  async function updateEncounterHandler(data) {
    await updateEncounterMutation({
      encounterId: selected_encounter_id,
      data
    });
  }

  const validate = useCallback(
    (values: FormValues) => {
      const isEdit = default_visit_type && selected_encounter_id;
      const errors = {};

      if (!values.total_time) {
        errors["total_time"] = "Required";
      }

      if (!values.visit_type && !default_visit_type) {
        errors["visit_type"] = "Required";
      }
      if (!values.modality) {
        errors["modality"] = "Required";
      }
      if (
        values.visit_type !==
          VisitReasonsEnum.ASYNC_REMOTE_PATIENT_MONITORING &&
        !values.complexity
      ) {
        errors["complexity"] = "Required";
      }

      if (
        !values.patient_location &&
        // we want this to be backwards compatible - a user should not be prevented from editing
        // a past encounter if they don't select a patient location
        // tbd switch this over and remove this line once 10 business days have passed after deployment to prod
        !isEdit
      ) {
        errors["patient_location"] = "Required";
      }

      if (
        values.patient_location === EncounterPatientLocationEnum.NOT_AT_HOME &&
        !values.not_at_home_detail
      ) {
        errors["not_at_home_detail"] = "Required";
      }

      if (
        values.modality === CommunicationTypeEnum.PHONE &&
        !values.video_call_decline_reason
      ) {
        errors["video_call_decline_reason"] = "Required";
      }

      // if we are editing, check if the values are the same before allowing submission
      if (
        default_visit_type &&
        values.total_time === default_total_time &&
        values.modality === default_modality &&
        values.complexity === default_complexity &&
        values.video_call_decline_reason ===
          default_video_call_decline_reason &&
        values.patient_location === default_patient_location &&
        values.not_at_home_detail === default_not_at_home_detail
      ) {
        errors["visit_type"] = "Please make a change before submitting";
      }

      return errors;
    },
    [default_visit_type, selected_encounter_id]
  );

  const onSubmit = async (values: FormValues) => {
    if (default_visit_type && selected_encounter_id) {
      const updateBody: any = {};
      if (values.total_time !== default_total_time) {
        updateBody.duration = values.total_time;
      }

      if (values.complexity !== default_complexity) {
        updateBody.complexity = `CPT_${values.complexity}`;
      }

      if (
        values.video_call_decline_reason !== default_video_call_decline_reason
      ) {
        updateBody.video_call_decline_reason = values.video_call_decline_reason;
      }

      if (values.patient_location !== default_patient_location) {
        updateBody.patient_location = values.patient_location;
      }

      if (values.not_at_home_detail !== default_not_at_home_detail) {
        updateBody.not_at_home_detail = values.not_at_home_detail;
      }

      if (values.modality !== default_modality) {
        updateBody.modality = values.modality;
      }

      await updateEncounterHandler(updateBody);
    } else {
      await createEncounter({
        body: {
          patient_id: memberId,
          submitted_by: user?.user_id,
          starts_on: DateTime.now().toUTC().toISO(),
          reason: values.visit_type,
          complexity: `CPT_${values.complexity}`,
          duration: values.total_time,
          ...(values.modality && { modality: values.modality }),
          ...(values.video_call_decline_reason && {
            video_call_decline_reason: values.video_call_decline_reason
          }),
          ...(values.patient_location && {
            patient_location: values.patient_location
          }),
          ...(values.not_at_home_detail && {
            not_at_home_detail: values.not_at_home_detail
          })
        }
      });
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      total_time: default_total_time,
      visit_type: default_visit_type,
      // only providers can select video calls for now
      modality: isProvider ? default_modality : CommunicationTypeEnum.PHONE,
      complexity: default_complexity,
      video_call_decline_reason: default_video_call_decline_reason,
      patient_location: default_patient_location,
      not_at_home_detail: default_not_at_home_detail
    },
    validate,
    onSubmit
  });

  const handleModalClose = () => {
    formik.resetForm();
    resetEndAppointment();
    setModalOpen(false);
  };

  const isCreateLoading = useGetDelayedLoadingBoolean(
    createEncounterLoading,
    createEncounterFulfilledTimestamp,
    createEncounterData,
    // DELAY_AFTER_APPOINTMENTS_REQUEST_COMPLETED +
    500
  );

  const isUpdateLoading = useGetDelayedLoadingBoolean(
    updateEncounterIsLoading,
    createEncounterFulfilledTimestamp,
    createEncounterData,
    500
  );

  const onVisitTypeChange = useCallback(
    (event) => {
      // reset complexity if the visit type is changed to a type that doesn't have the current complexity
      if (
        isTruthy(formik.values.complexity) &&
        !getComplexityTypes(event.target.value)?.some(
          (type) => type.value == formik.values?.complexity
        )
      ) {
        formik.setFieldValue("complexity", undefined);
      }
      formik.setFieldValue("visit_type", event.target.value);
    },
    [formik?.values?.visit_type, formik?.values?.complexity, getComplexityTypes]
  );

  return (
    <StyledModal
      isOpen={modalOpen}
      contentLabel="Enter Time Spent Modal"
      modalHeight="max-content"
      onRequestClose={handleModalClose}
    >
      <Form onSubmit={formik.handleSubmit}>
        <ModalHeader onRequestClose={handleModalClose}>
          {selected_encounter_id && default_visit_type
            ? "Edit Encounter"
            : "Enter Time Spent"}
        </ModalHeader>
        <ModalBody>
          <Flexbox gap="20px" flexDirection="column">
            <Flexbox justifyContent="space-between" alignItems="start">
              {!default_visit_type && (
                <Flexbox flexBasis="48%" gap="8px" flexDirection="column">
                  <InputLabel>
                    <Typography variant="h6" color="text.primary">
                      Visit Type
                    </Typography>
                  </InputLabel>
                  <TextField
                    data-testid="Visit Type"
                    value={formik.values?.visit_type ?? ""}
                    placeholder="Select Visit Type"
                    select
                    fullWidth
                    onChange={onVisitTypeChange}
                  >
                    {encounterReasons?.map((reason) => (
                      <MenuItem
                        key={reason.value}
                        value={reason.value}
                        data-testid={reason.label}
                      >
                        {reason.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Flexbox>
              )}

              <Flexbox flexBasis="48%" gap="8px" flexDirection="column">
                <FormLabel>
                  <Typography variant="h6" color="text.primary">
                    <Flexbox alignItems="center" gap="2px">
                      Total Time{" "}
                      <CustomTooltip
                        backgroundColor={gray[200]}
                        title={
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            maxWidth="225px"
                          >
                            This is the total time spent in service of members
                            including call attempts, async messaging, task
                            completion, charting, etc.
                          </Typography>
                        }
                      >
                        <InfoOutlined color="primary" fontSize="small" />
                      </CustomTooltip>
                    </Flexbox>
                  </Typography>
                </FormLabel>
                <NumberInput
                  sx={{ width: "100%" }}
                  placeholder="1-60 min"
                  id={"total_time"}
                  name={"total_time"}
                  data-testid="totalTimeInput"
                  value={formik.values?.total_time ?? ""}
                  errorString={formik.errors.total_time}
                  onValueChange={(value) => {
                    formik.setFieldValue("total_time", value);
                  }}
                  min={1}
                  max={60}
                />
              </Flexbox>
            </Flexbox>

            <Flexbox justifyContent="space-between" alignItems="start">
              <Flexbox flexBasis="48%" gap="8px" flexDirection="column">
                <Typography variant="h6" color="text.primary">
                  Modality
                </Typography>
                <Box
                  sx={{ borderRadius: "8px", border: `1px solid ${gray[300]}` }}
                >
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={formik.values?.modality ?? ""}
                    onChange={(event) => {
                      if (event.target.value === CommunicationTypeEnum.VIDEO) {
                        // reset decline reason
                        formik.setFieldValue("video_call_decline_reason", null);
                      }
                      formik.setFieldValue("modality", event.target.value);
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        width: "100%",
                        margin: 0,
                        borderBottom: `1px solid ${gray[300]}`
                      }}
                      value="PHONE"
                      control={<Radio />}
                      data-testid="phone"
                      label={
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px"
                          }}
                        >
                          Audio Call
                          <PhoneOutlined />
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      sx={{
                        width: "100%",
                        margin: 0
                      }}
                      value="VIDEO"
                      data-testid="video"
                      control={<Radio />}
                      label={
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px"
                          }}
                        >
                          Video Call
                          <VideocamOutlined />
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Box>
              </Flexbox>
              <Flexbox flexBasis="48%" gap="8px" flexDirection="column">
                <FormLabel>
                  <Typography variant="h6" color="text.primary">
                    Complexity
                  </Typography>
                </FormLabel>
                <TextField
                  value={formik.values?.complexity ?? ""}
                  placeholder="Select Complexity"
                  select
                  fullWidth
                  onChange={(event) => {
                    formik.setFieldValue("complexity", event.target.value);
                  }}
                >
                  {getComplexityTypes(formik.values.visit_type)?.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Flexbox>
            </Flexbox>
            {formik.values.modality === CommunicationTypeEnum.PHONE && (
              <Flexbox
                gap="8px"
                flexDirection="column"
                sx={{ transition: "all 0.66s ease-out" }}
              >
                <FormLabel>
                  <Typography variant="h6" color="text.primary">
                    Video Call Decline Reason
                  </Typography>
                </FormLabel>
                <TextField
                  value={formik.values?.video_call_decline_reason ?? ""}
                  placeholder="Select Video Call Decline Reason"
                  select
                  fullWidth
                  error={formik.errors.video_call_decline_reason !== undefined}
                  helperText={formik.errors.video_call_decline_reason}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "video_call_decline_reason",
                      event.target.value
                    );
                  }}
                >
                  {VideoCallDeclineReasons.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Flexbox>
            )}

            <Flexbox justifyContent="space-between" alignItems="start">
              <Flexbox flexBasis="48%" gap="8px" flexDirection="column">
                <FormLabel>
                  <Typography variant="h6" color="text.primary">
                    Patient Physical Location
                  </Typography>
                </FormLabel>
                <Box
                  sx={{
                    borderRadius: "8px",
                    border: `1px solid ${gray[300]}`
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={formik.values?.patient_location ?? ""}
                    onChange={(event) => {
                      if (
                        event.target.value ===
                        EncounterPatientLocationEnum.AT_HOME
                      ) {
                        // reset not_at_home reason
                        formik.setFieldValue("not_at_home_detail", null);
                      }
                      formik.setFieldValue(
                        "patient_location",
                        event.target.value
                      );
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        width: "100%",
                        margin: 0,
                        borderBottom: `1px solid ${gray[300]}`
                      }}
                      value={EncounterPatientLocationEnum.AT_HOME}
                      control={<Radio />}
                      data-testid="athome"
                      label={
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px"
                          }}
                        >
                          At Home
                          <DomainOutlined />
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      sx={{
                        width: "100%",
                        margin: 0
                      }}
                      value={EncounterPatientLocationEnum.NOT_AT_HOME}
                      data-testid="notathome"
                      control={<Radio />}
                      label={
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px"
                          }}
                        >
                          Not at Home
                          <DomainDisabledOutlined />
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Box>
              </Flexbox>
              {formik.values.patient_location ===
                EncounterPatientLocationEnum.NOT_AT_HOME && (
                <Flexbox flexBasis="48%" gap="8px" flexDirection="column">
                  <FormLabel>
                    <Typography variant="h6" color="text.primary">
                      Location (Not At Home)
                    </Typography>
                  </FormLabel>
                  <TextField
                    value={formik.values?.not_at_home_detail ?? ""}
                    placeholder="Select Location if they are not at home"
                    select
                    fullWidth
                    error={formik.errors.not_at_home_detail !== undefined}
                    helperText={formik.errors.not_at_home_detail}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "not_at_home_detail",
                        event.target.value
                      );
                    }}
                  >
                    {PatientNotAtHomeDropdownOptions.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Flexbox>
              )}
            </Flexbox>
          </Flexbox>
          {createEncounterError && (
            <ErrorComponent
              error={createEncounterError}
              showErrorResponseMessage={true}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons>
            <TurqoiseButton
              loading={isCreateLoading || isUpdateLoading}
              disabled={!formik.dirty || !formik.isValid}
              type="submit"
              data-testid="submitButton"
            >
              Submit
            </TurqoiseButton>
            <WhiteButton onClick={handleModalClose}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </Form>
    </StyledModal>
  );
};
