import replace from "lodash.replace";
import Table from "../../components/Table/Table";
import StaffLicensingResponse from "common/types/StaffLicensing/StaffLicensingResponse";
import Routes from "../../routes/Routes";
import {
  DateTimeColumn,
  TextColumn
} from "../../components/Table/helpers/TableColumns";
import { UserNameCell } from "../../components/Table/helpers/TableCells";
import { Column, CustomTooltip } from "../../styling/StyleComponents";
import { Button, Typography } from "@mui/material";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import { ThreeDotsIcon } from "../../assets/images/icons";
import StaffLicensingStatusEnum from "common/types/StaffLicensing/StaffLicensingStatusEnum";

const ProviderEnrollmentProviderNameColumn = {
  header: "Name",
  id: "providerEnrollmentProviderName",
  accessor: "staff.name",
  func: "accessor",
  enableColumnFilter: true,
  size: 200,
  cell: ({ getValue, row, table }: any) => {
    const staff_id = row?.original?.staff?.id;
    let displayValue = getValue();
    const { externalLink } = table.options.meta;
    const link = replace(externalLink, ":staffId", staff_id);

    return (
      <UserNameCell link={link}>
        {displayValue?.length > 0 ? displayValue : "No name found"}
      </UserNameCell>
    );
  }
};

const ProviderEnrollmentActionsColumn = {
  header: "",
  id: "providerEnrollmentActions",
  accessor: "id",
  func: "accessor",
  enableColumnFilter: true,
  size: 10,
  cell: ({ getValue, column: { id }, table }: any) => {
    const value = getValue();
    const { toggleEditProviderModal, toggleDeleteProviderModal } =
      table.options.meta;

    return (
      <div className="button">
        <CustomTooltip
          backgroundColor="white"
          title={
            <Column>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  toggleEditProviderModal(value);
                }}
              >
                <EditOutlined />
                <Typography>Edit Record</Typography>
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  toggleDeleteProviderModal(value);
                }}
              >
                <DeleteOutlineOutlined />
                <Typography>Delete Record</Typography>
              </Button>
            </Column>
          }
        >
          <ThreeDotsIcon id={id} />
        </CustomTooltip>
      </div>
    );
  }
};

const statusRecord: Record<StaffLicensingStatusEnum,string> = {
  EFFECTIVE: "Effective",
  REJECTED: "Rejected",
  READY_FOR_PROCESSING: "Read for processing",
  ON_HOLD: "On hold",
  MISSING_INFO: "Missing info",
  HEALTHPLAN_PROCESSING: "Health Plan processing"
}

interface IProps {
  providerEnrollmentData: StaffLicensingResponse[];
  toggleEditProviderModal: (insuranceId: string) => void;
  toggleDeleteProviderModal: (insuranceId: string) => void;
}

const ProviderEnrollmentTable = ({
  providerEnrollmentData,
  toggleEditProviderModal,
  toggleDeleteProviderModal
}: IProps) => {
  return (
    <Table
      data={providerEnrollmentData}
      tableContainerStyles={{ margin: "0", width: "100%", overflowX: "scroll" }}
      tableProps={{
        externalLink: Routes.STAFF_DETAILS(":staffId", "profile"),
        toggleEditProviderModal,
        toggleDeleteProviderModal
      }}
      tableColumns={[
        ProviderEnrollmentActionsColumn,
        ProviderEnrollmentProviderNameColumn,
        TextColumn({
          id: "providerCredentials",
          header: "Credentials",
          accessor: "staff.medical_credentials",
          size: 110
        }),
        TextColumn({
          id: "providerStatus",
          header: "Status",
          accessor: "status",
          getText: ({ getValue }) => statusRecord[getValue()],
          size: 110
        }),
        TextColumn({
          id: "insuranceName",
          header: "Health Plan",
          accessor: "insurance.name",
          size: 200
        }),
        TextColumn({
          id: "insuranceState",
          header: "State",
          accessor: "state",
          size: 100
        }),
        DateTimeColumn({
          header: "Date",
          accessor: "start_date",
          id: "date",
          format: "iso",
          showRelativeTime: false,
          size: 150
        })
      ]}
    />
  );
};

export default ProviderEnrollmentTable;
