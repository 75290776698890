import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled
} from "@mui/material";
import DateRangePicker from "./DateRangePicker";

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const SELECT_SMALL_STYLE = {
  backgroundColor: "transparent",
  boxShadow: "none",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0
  }
};

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
  onStartDateChange: (startDate: DateTime) => void;
  onEndDateChange: (startDate: DateTime) => void;
  maxDate?: DateTime;
  timezone?: string;
  dateRangeDefault?: string;
  small?: boolean;
}

/**
 *   This component should always be rendered. if not, 
  the state will get lost and it will be reset to dateRangeDefault.
  https://copilotiq.atlassian.net/browse/QA-355
 * @returns Renders a dropdown date range picker.
 */

const DropdownDateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  dateRangeDefault = "7_days",
  maxDate = DateTime.now(),
  timezone = "local",
  small = false
}: IProps) => {
  const [dateRangeSelector, setDateRangeSelector] =
    useState<string>(dateRangeDefault);

  useEffect(() => {
    let days = -1;
    switch (dateRangeSelector) {
      case "today":
        days = 0;
        break;
      case "7_days":
        days = 7;
        break;
      case "30_days":
        days = 30;
        break;
      case "90_days":
        days = 90;
        break;
      case "180_days":
        days = 180;
        break;
      case "month_to_date":
        // Overrides to month to date.
        days = -1;
        onStartDateChange(DateTime.now().setZone(timezone).startOf("month"));
        onEndDateChange(DateTime.now().setZone(timezone).endOf("day"));
        break;
      default:
        days = -1;
        break;
    }

    if (days >= 0) {
      onStartDateChange(
        DateTime.now().setZone(timezone).minus({ days }).startOf("day")
      );
      // the backend accepts this value as strictly a date instead of a date time so we want this value to be the next day
      onEndDateChange(
        DateTime.now().setZone(timezone).plus({ days: 1 }).startOf("day")
      );
    }
  }, [dateRangeSelector]);

  const handleToggleButtonChange = (event: SelectChangeEvent<string>) => {
    setDateRangeSelector(event.target.value);
  };

  const selectStyle = small ? SELECT_SMALL_STYLE : undefined;

  return (
    <Row>
      <FormControl sx={{ width: small ? 90 : 200 }}>
        {!small && (
          <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
        )}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dateRangeSelector}
          label={small ? undefined : "Date Range"}
          onChange={handleToggleButtonChange}
          sx={selectStyle}
        >
          <MenuItem value={"today"}>Today</MenuItem>
          <MenuItem value={"7_days"}>7d</MenuItem>
          <MenuItem value={"30_days"}>30d</MenuItem>
          <MenuItem value={"90_days"}>90d</MenuItem>
          <MenuItem value={"180_days"}>180d</MenuItem>
          <MenuItem value={"month_to_date"}>MTD</MenuItem>
          {!small && <MenuItem value={"custom"}>Custom</MenuItem>}
        </Select>
      </FormControl>
      {dateRangeSelector === "custom" && (
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={(date) => onStartDateChange(date.startOf("day"))}
          onEndDateChange={(date) => onEndDateChange(date.endOf("day"))}
          maxDate={maxDate}
        />
      )}
    </Row>
  );
};

export default DropdownDateRangePicker;
