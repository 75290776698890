import { useEffect } from "react";
import { checkIdValid } from "common/helpers/helpers";
import { Typography } from "@mui/material";
import useSanitizedParams from "../../../hooks/useSanitizedParams";

export default function StaffDetailsRedirect(props) {
  const params = useSanitizedParams();
  const { userId, nurseId } = params;

  const id = nurseId || userId;

  const isValidId = checkIdValid(id);

  useEffect(() => {
    if (userId) {
      window.location.href = `/staff/staffId/${userId}/schedule`;
    }
    if (nurseId) {
      window.location.href = `/staff/staffId/${nurseId}/schedule`;
    }
  }, [userId, nurseId]);

  if (!isValidId)
    return <Typography variant="body1">{`Invalid Staff ID ${id}`}</Typography>;
}
