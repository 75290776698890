import { useEffect, useRef, useState } from "react";
import { useCallback } from "react";
import {
  DestructiveButton,
  ErrorText,
  TurqoiseButton,
  WhiteButton
} from "../../../../styling";
import AssignNursePatientForm from "./AssignNursePatientForm";
import ErrorComponent from "../../../../components/ErrorComponent";
import { useGetSortedMembersWithActiveNursesQuery } from "common/services/ReportsService";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../../../styling/StyleModal";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import RolesEnum, { isDeleteNurseAllowed } from "common/enums/RolesEnum";
import { useDeleteCarerMemberNurseMutation } from "common/services/CarerMembersService";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import UserStatusEnum from "common/enums/UserStatusEnum";
import MemberType from "common/types/MemberType";

interface IProps {
  modalOpen: boolean;
  setModalStateCallback: (val: string, errorMessage?: string) => void;
  selectedPatientId: string | undefined;
  setAssignNursePatientResponseCallback?: (val: MemberType) => void;
}

const AssignNursePatientModal = ({
  modalOpen,
  setModalStateCallback,
  selectedPatientId,
  setAssignNursePatientResponseCallback
}: IProps) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const {
    data: nurses,
    error,
    isSuccess,
    isError,
    isLoading
  } = useGetSortedMembersWithActiveNursesQuery(
    {
      carer_roles: [RolesEnum.TH_NURSE, RolesEnum.THN_MANAGER],
      carer_status: [UserStatusEnum.ACTIVE],
      getNurseListForAppointments: true
    },
    {
      skip: modalOpen === false
    }
  );

  const { data: member } = useGetMemberWithUsernameQuery(
    {
      username: selectedPatientId,
      linked_entities: [MemberLinkedEntitiesEnum.NURSE]
    },
    { skip: selectedPatientId === undefined }
  );

  const assignedNurse = member?.assigned_nurse;

  const assignPatientFormRef = useRef(null);
  // @ts-ignore
  const handleAssignPatientSubmit = (...args) => {
    if (assignPatientFormRef.current) {
      // @ts-ignore
      assignPatientFormRef.current.handleSubmit(...args);
    }
  };

  const [formDirty, setFormDirty] = useState<boolean>(false);
  const [isAssignNurseLoading, setIsAssignNurseLoading] =
    useState<boolean>(false);

  const [
    deleteCarerPatientNurseMutation,
    {
      isLoading: deleteNurseLoading,
      isSuccess: deleteNurseSuccess,
      error: deleteCarerPatientNurseError
    }
  ] = useDeleteCarerMemberNurseMutation();

  useEffect(() => {
    if (deleteNurseSuccess) {
      setModalStateCallback("close");
    }
  }, [deleteNurseSuccess]);

  const updateFormDirty = useCallback(
    (val: boolean) => {
      setFormDirty(val);
    },
    [setFormDirty]
  );
  return (
    <StyledModal
      isOpen={modalOpen}
      contentLabel="Assign Nurse to Member Modal"
      modalHeight="70vh"
      onRequestClose={() => setModalStateCallback("close")}
    >
      <ModalHeader onRequestClose={() => setModalStateCallback("close")}>
        Assign Nurse to Member
      </ModalHeader>
      <ModalBody>
        {isError && <ErrorComponent error={error} />}
        {isLoading && <LoadingFallback count={5} />}
        {isSuccess && nurses?.length === 0 && (
          <ErrorText>Error: no nurses found.</ErrorText>
        )}

        {isSuccess && nurses && nurses.length > 0 && (
          <AssignNursePatientForm
            ref={assignPatientFormRef}
            setModalStateCallback={setModalStateCallback}
            updateFormDirty={updateFormDirty}
            nursesList={nurses}
            selectedPatientId={selectedPatientId}
            // @ts-ignore
            setAssignNursePatientResponseCallback={
              setAssignNursePatientResponseCallback
            }
            setIsAssignNurseLoading={setIsAssignNurseLoading}
          />
        )}
        <ErrorComponent error={deleteCarerPatientNurseError} />
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          {isDeleteNurseAllowed(currentRole) && (
            <DestructiveButton
              loading={deleteNurseLoading}
              onClick={() =>
                deleteCarerPatientNurseMutation({
                  patient_id: selectedPatientId
                })
              }
              disabled={assignedNurse === undefined}
            >
              Unassign
            </DestructiveButton>
          )}
          <TurqoiseButton
            loading={isAssignNurseLoading}
            onClick={() => {
              handleAssignPatientSubmit();
            }}
            disabled={!formDirty || isAssignNurseLoading}
            type="submit"
          >
            Assign
          </TurqoiseButton>
          <WhiteButton
            onClick={() => {
              setModalStateCallback("close");
            }}
          >
            Cancel
          </WhiteButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export { AssignNursePatientModal };
