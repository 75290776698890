enum StaffLicensingStatusEnum {
  EFFECTIVE = "EFFECTIVE",
  REJECTED = "REJECTED",
  READY_FOR_PROCESSING = "READY_FOR_PROCESSING",
  ON_HOLD = "ON_HOLD",
  MISSING_INFO = "MISSING_INFO",
  HEALTHPLAN_PROCESSING = "HEALTHPLAN_PROCESSING"
}

export default StaffLicensingStatusEnum;
