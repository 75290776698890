import { Box, Button, Typography } from "@mui/material";

import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import { LoadingButton } from "@mui/lab";
import {
  DELAY_AFTER_REQUEST_COMPLETED,
  useDeleteStaffEnrollmentMutation
} from "common/services/StaffLicensingService";
import { useCallback, useEffect } from "react";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import ErrorComponent from "../../components/ErrorComponent";

interface IProps {
  isVisible: boolean;
  onRequestClose: () => void;
  selectedInsuranceId: string;
}
const DeleteProviderEnrollmentModal = ({
  isVisible,
  onRequestClose,
  selectedInsuranceId
}: IProps) => {
  const [
    deleteStaffEnrollmentMutation,
    {
      isSuccess: deleteStaffEnrollmentIsSuccess,
      isLoading: deleteStaffEnrollmentIsLoading,
      fulfilledTimeStamp: deleteStaffEnrollmentFulfilledTimestamp,
      error: deleteStaffEnrollmentError,
      reset: resetDeleteStaffEnrollment
    }
  ] = useDeleteStaffEnrollmentMutation();

  useEffect(() => {
    if (deleteStaffEnrollmentIsSuccess) {
      setTimeout(() => {
        onRequestClose();
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }
  }, [deleteStaffEnrollmentIsSuccess]);

  useEffect(() => {
    return () => {
      resetDeleteStaffEnrollment();
    };
  }, []);

  const isLoading = useGetDelayedLoadingBoolean(
    deleteStaffEnrollmentIsLoading,
    deleteStaffEnrollmentFulfilledTimestamp,
    deleteStaffEnrollmentIsSuccess,
    DELAY_AFTER_REQUEST_COMPLETED
  );

  const onSubmit = useCallback(() => {
    deleteStaffEnrollmentMutation({ licensing_id: selectedInsuranceId });
  }, [selectedInsuranceId]);

  if (!isVisible || !selectedInsuranceId) {
    return null;
  }

  return (
    <StyledModal
      key="create-provider-enrollment-modal"
      isOpen={isVisible}
      onRequestClose={onRequestClose}
      modalHeight="50vh"
      contentLabel="Change Role"
    >
      <ModalHeader onRequestClose={onRequestClose}>Please Confirm</ModalHeader>
      <ModalBody>
        <Box>
          <Typography variant="body1">
            Are you sure you want to delete this record? This action cannot be
            undone.
          </Typography>
          {deleteStaffEnrollmentError && (
            <ErrorComponent error={deleteStaffEnrollmentError} />
          )}
        </Box>
      </ModalBody>

      <ModalFooter>
        <ModalFooterButtons>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            type="submit"
            color="error"
            onClick={() => {
              onSubmit();
            }}
          >
            Delete
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={() => {
              onRequestClose();
            }}
          >
            Cancel
          </Button>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export default DeleteProviderEnrollmentModal;
