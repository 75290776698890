import RolesEnum from "common/enums/RolesEnum";
import {
  AllNursesIcon,
  AllProvidersIcon,
  CalendarIcon
} from "../../assets/images/icons";

import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  CANCELED_MEMBERS,
  RETENTION,
  UNASSIGNED_MEMBERS,
  NPN_LIST,
  ALL_TEAMS,
  CREATE_TEAM,
  NPN_DETAILS,
  TEAM_DETAILS,
  MEMBER_CHART,
  MEMBER_CHART_CALENDARING,
  STAFF_DETAILS,
  NURSE_SCHEDULE,
  MY_TODOS,
  COMPLETED_TODOS,
  NURSES_SCHEDULES,
  PROVIDER_SCHEDULES,
  VISITS,
  COMMON_ROUTES,
  PROVIDER_LIST
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_CLINICAL_TEAMS,
  SIDEBAR_CREATE_TEAM,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_NPNS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SUPPORT,
  SIDEBAR_INACTIVE_CANCELED_MEMBERS,
  SIDEBAR_RETENTION,
  SIDEBAR_UNASSIGNED_MEMBERS,
  SIDEBAR_SECTIONS,
  PROVIDER_SCHEDULES_SIDEBAR
} from "../RouteSidebar";

import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import StaffDetailsRedirect from "../../pages/AllUserList/StaffDetails/StaffDetailsRedirect";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";
import Routes from "../Routes";

const sidebar = () => {
  const sidebarItems = [
    SIDEBAR_DASHBOARD(RolesEnum.NURSE_DIRECTOR),
    {
      sectionName: SIDEBAR_SECTIONS.users,
      items: [SIDEBAR_CREATE_TEAM]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "All Nurses",
          itemLink: Routes.NURSES,
          ItemIcon: AllNursesIcon
        },
        {
          itemName: "Nurses Schedules",
          itemLink: Routes.NURSE_SCHEDULE_DETAILS("schedules"),
          ItemIcon: CalendarIcon
        },
        SIDEBAR_NPNS,
        SIDEBAR_CLINICAL_TEAMS
      ]
    }
  ];

  sidebarItems.push({
    sectionName: SIDEBAR_SECTIONS.providers,
    items: [
      {
        itemName: "All Providers",
        itemLink: Routes.PROVIDERS,
        ItemIcon: AllProvidersIcon
      },
      PROVIDER_SCHEDULES_SIDEBAR
    ]
  });

  sidebarItems.push({
    sectionName: SIDEBAR_SECTIONS.members,
    items: [
      SIDEBAR_ALL_MEMBERS,
      SIDEBAR_UNASSIGNED_MEMBERS,
      SIDEBAR_INACTIVE_MEMBERS,

      SIDEBAR_ORDERS
    ]
  });
  sidebarItems.push({
    sectionName: SIDEBAR_SECTIONS.inactives,
    items: [SIDEBAR_RETENTION, SIDEBAR_INACTIVE_CANCELED_MEMBERS]
  });
  sidebarItems.push(SIDEBAR_REPORTS);
  sidebarItems.push(SIDEBAR_SUPPORT);
  return sidebarItems;
};

const NURSE_DIRECTOR = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: "/",
      components: NURSE_LIST({})
    },
    ALL_TEAMS({
      types: [
        TeamTypeEnum.NP_NURSES,
        TeamTypeEnum.PROVIDERS,
        TeamTypeEnum.TH_NURSES,
        TeamTypeEnum.MEMBER_CARE_SPECIALISTS,
        TeamTypeEnum.NURSE_MANAGERS
      ]
    }),
    TEAM_DETAILS,
    {
      path: Routes.NURSES,
      components: NURSE_LIST({})
    },
    NPN_LIST({}),
    NPN_DETAILS,
    STAFF_DETAILS,
    {
      path: Routes.NURSE_REDIRECT,
      components: <StaffDetailsRedirect />
    },
    STAFF_DETAILS,
    {
      path: Routes.ALL_MEMBERS,
      components: ALL_MEMBERS({})
    },
    {
      path: Routes.AWAITING_PROVIDER,
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" }
        ]
      })
    },
    { path: Routes.INACTIVE_MEMBERS, components: INACTIVE_MEMBERS(true) },
    {
      path: Routes.UNASSIGNED_MEMBERS,
      components: UNASSIGNED_MEMBERS
    },
    ...MEMBER_CHART(RolesEnum.NURSE_DIRECTOR),
    ...MEMBER_CHART_CALENDARING,
    {
      path: Routes.ASSIGNED_MEMBERS(":nurseId"),
      components: [MEMBERS_WITH_NO_ACTIVITY({}), ASSIGNED_MEMBERS({})]
    },
    {
      path: Routes.ORDERS,
      components: <OrderDevices />
    },
    {
      path: Routes.DEVICES_BY_MEMBER,
      components: <OrderDevices />
    },
    ORDER_DETAILS(),

    REPORT_LIST,
    REPORT_DETAILS,
    CANCELED_MEMBERS,
    RETENTION({}),
    REPORT_DETAILS_BY_MEMBER,
    CREATE_TEAM,
    ...NURSES_SCHEDULES,
    NURSE_SCHEDULE,
    MY_TODOS,
    COMPLETED_TODOS,
    {
      path: Routes.PROVIDER_SCHEDULES,
      components: PROVIDER_SCHEDULES({})
    },
    {
      path: Routes.PROVIDERS,
      components: [
        PROVIDER_LIST({
          filterByAuthenticatedUser: false
        })
      ]
    },
    VISITS
  ],
  sidebar: sidebar()
};

export default NURSE_DIRECTOR;
