enum ReduxTagEnum {
  User = "User",
  Member = "Member",
  Encounter = "Encounter",
  Team = "Team",
  Order = "Order",
  CarerMemberType = "CarerMemberType",
  Report = "Report",
  ProviderMetadata = "ProviderMetadata",
  Survey = "Survey",
  Device = "Device",
  Consents = "Consents",
  LicensedStates = "LicensedStates",
  Goals = "Goals",
  CarePlan = "CarePlan",
  RelationshipNotes = "RelationshipNotes",
  Video = "Video",
  Voice = "Voice",
  Tasks = "Tasks",
  Appointments = "Appointments",
  Messaging = "Messaging",
  Calendar = "Calendar",
  ManagerPto = "ManagerPto",
  Availability = "Availability",
  MemberEngagement = "Member Engagement",
  Visits = "Visits",
  EncounterVisits = "EncounterVisits",
  StaffLicensing = "StaffLicensing",
  PatientInsurance = "PatientInsurance",
}

export default ReduxTagEnum;
