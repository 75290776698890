import { useCallback, useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, Button, MenuItem } from "@mui/material";

import { StyledTextField } from "../../helpers/components/Forms/FormHelpers";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import {
  checkIdValid,
  formatName,
  getNameOrUsername,
  getStates,
  isFalsy,
  isTruthy,
  isValidNPI,
  NUMBER_REGEXP
} from "common/helpers/helpers";
import { DateTime } from "luxon";
import ErrorComponent from "../../components/ErrorComponent";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import DatePicker from "../../components/DatePicker";
import { LoadingButton } from "@mui/lab";
import StaffLicensingStatusEnum from "common/types/StaffLicensing/StaffLicensingStatusEnum";
import { useGetUserWithQueryParamsQuery } from "common/services/UserService";
import RolesEnum from "common/enums/RolesEnum";
import DropdownType from "../../types/DropdownType";
import {
  useCreateStaffEnrollmentMutation,
  useGetInsuranceLicensingsQuery,
  DELAY_AFTER_REQUEST_COMPLETED
} from "common/services/StaffLicensingService";
import LoadingFallback from "common/helpers/components/LoadingFallback";

interface IProps {
  isVisible: boolean;
  onRequestClose: (refetch: boolean) => void;
}

const statesList = getStates();

interface IPropsRender extends IProps {
  event?: any;
  eventId?: string;
  carerTimezone?: string;
  providers?: DropdownType[];
  insurancesList?: DropdownType[];
  isFetching: boolean;
  providersError?: unknown;
  insurancesError?: unknown;
}

const CreateProviderEnrollmentModalRender = ({
  isVisible,
  onRequestClose,
  providers,
  insurancesList,
  isFetching,
  providersError,
  insurancesError
}: IPropsRender) => {
  const now = useMemo(() => DateTime.now().startOf("minute"), []);

  const [selectedProvider, setSelectedProvider] = useState<DropdownType>(null);
  const [selectedProviderId, setSelectedProviderId] = useState<string>(null);
  const [selectedHealthPlan, setSelectedHealthPlan] =
    useState<DropdownType>(null);
  const [selectedHealthPlanId, setSelectedHealthPlanId] =
    useState<string>(null);
  const [selectedNPI, setSelectedNPI] = useState<string>(null);
  const [selectedStateObject, setSelectedStateObject] =
    useState<DropdownType>(null);
  const [selectedState, setSelectedState] = useState<string>(null);
  const [selectedEffectiveDate, setSelectedEffectiveDate] =
    useState<DateTime>(null);
  const [selectedRequestDate, setSelectedRequestDate] = useState<DateTime>(now);
  const [selectedStatus, setSelectedStatus] =
    useState<StaffLicensingStatusEnum>(null);

  const handleCreateProviderEnrollmentModalClose = (
    refetch: boolean = false
  ) => {
    onRequestClose(refetch);
    resetCreateStaffEnrollment();
  };

  const [
    createStaffEnrollmentMutation,
    {
      data: createStaffEnrollmentData,
      isLoading: createStaffEnrollmentIsLoading,
      fulfilledTimeStamp: createStaffEnrollmentFulfilledTimestamp,
      error: createStaffEnrollmentError,
      reset: resetCreateStaffEnrollment
    }
  ] = useCreateStaffEnrollmentMutation();

  useEffect(() => {
    if (createStaffEnrollmentData) {
      setTimeout(() => {
        handleCreateProviderEnrollmentModalClose(true);
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }
  }, [createStaffEnrollmentData]);

  const onSubmit = useCallback(() => {
    const effectiveDate = selectedEffectiveDate?.toUTC()?.toISO();
    const requestDate = selectedRequestDate?.toUTC()?.toISO();

    const requestBody = {
      staff_id: selectedProviderId,
      insurance_licensing_id: selectedHealthPlanId,
      state: selectedState,
      npi: selectedNPI,
      start_date: effectiveDate,
      request_date: requestDate,
      status: selectedStatus
    };

    createStaffEnrollmentMutation({ body: requestBody });
  }, [
    selectedProviderId,
    selectedHealthPlanId,
    selectedState,
    selectedNPI,
    selectedEffectiveDate,
    selectedRequestDate,
    selectedStatus
  ]);

  const isLoading = useGetDelayedLoadingBoolean(
    createStaffEnrollmentIsLoading,
    createStaffEnrollmentFulfilledTimestamp,
    createStaffEnrollmentData,
    DELAY_AFTER_REQUEST_COMPLETED
  );

  const submitButtonDisabled = useMemo(() => {
    return (
      isFalsy(selectedProviderId) ||
      !checkIdValid(selectedProviderId) ||
      isFalsy(selectedHealthPlanId) ||
      isFalsy(selectedState) ||
      isFalsy(selectedNPI) ||
      !isValidNPI(selectedNPI) ||
      isFalsy(selectedStatus) ||
      !selectedEffectiveDate?.isValid ||
      !selectedRequestDate?.isValid
    );
  }, [
    selectedProviderId,
    selectedHealthPlanId,
    selectedState,
    selectedNPI,
    selectedEffectiveDate,
    selectedRequestDate,
    selectedStatus
  ]);

  const closeModal = useCallback(
    () => handleCreateProviderEnrollmentModalClose(false),
    [handleCreateProviderEnrollmentModalClose]
  );

  return (
    <StyledModal
      key="create-provider-enrollment-modal"
      isOpen={isVisible}
      onRequestClose={closeModal}
      modalHeight="70vh"
      modalWidth="75%"
      contentLabel="Change Role"
    >
      <ModalHeader onRequestClose={closeModal}>
        Create New Provider Enrollment Record
      </ModalHeader>
      <ModalBody>
        {isFetching && <LoadingFallback count={5} />}
        {!isFetching && !providersError && !insurancesError && (
          <>
            <Box
              display="flex"
              alignItems="start"
              justifyContent="space-between"
            >
              {insurancesList?.length >= 0 && (
                <Autocomplete
                  sx={{ width: "48%" }}
                  options={insurancesList}
                  value={selectedHealthPlan}
                  getOptionLabel={(plan) => {
                    return plan?.label;
                  }}
                  onChange={(e, value) => {
                    setSelectedHealthPlan(value);
                    setSelectedHealthPlanId(value?.value);
                  }}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      aria-label="Select Health Plan"
                      label="Select Health Plan"
                      variant="outlined"
                    />
                  )}
                />
              )}
              <Autocomplete
                sx={{ width: "48%" }}
                options={statesList}
                value={selectedStateObject}
                getOptionLabel={(state) => {
                  return state?.label;
                }}
                onChange={(e, value) => {
                  setSelectedStateObject(value);
                  setSelectedState(value?.value);
                }}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    aria-label="Select State"
                    label="Select State"
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <br />
            <Box
              display="flex"
              alignItems="start"
              justifyContent="space-between"
            >
              <Box sx={{ width: "48%" }}>
                <DatePicker
                  label={"Effective Date"}
                  name="effectiveDate"
                  value={selectedEffectiveDate}
                  onChange={(dateTime: DateTime) => {
                    if (dateTime?.isValid) {
                      setSelectedEffectiveDate(dateTime.startOf("minute"));
                    }
                  }}
                  slotProps={{
                    field: {
                      readOnly: true
                    },
                    textField: {
                      error:
                        selectedEffectiveDate &&
                        !selectedEffectiveDate?.isValid,
                      helperText:
                        selectedEffectiveDate &&
                        !selectedEffectiveDate?.isValid &&
                        "Please select a valid date",
                      // prevent user from typing in the date as this can lead to bugs
                      // see ENG-3757
                      // the below code needs to be here instead of in DateTimePicker.tsx
                      // until this PR is merged https://github.com/mui/material-ui/pull/35088
                      onKeyDown: (e) => {
                        e.preventDefault();
                      }
                    }
                  }}
                />
              </Box>
              <Box sx={{ width: "48%" }}>
                <DatePicker
                  label={"Request Date"}
                  name="requestDate"
                  value={selectedRequestDate}
                  onChange={(dateTime: DateTime) => {
                    if (dateTime?.isValid) {
                      setSelectedRequestDate(dateTime.startOf("minute"));
                    }
                  }}
                  slotProps={{
                    field: {
                      readOnly: true
                    },
                    textField: {
                      error:
                        selectedRequestDate && !selectedRequestDate?.isValid,
                      helperText:
                        selectedRequestDate &&
                        !selectedRequestDate?.isValid &&
                        "Please select a valid date",
                      // prevent user from typing in the date as this can lead to bugs
                      // see ENG-3757
                      // the below code needs to be here instead of in DateTimePicker.tsx
                      // until this PR is merged https://github.com/mui/material-ui/pull/35088
                      onKeyDown: (e) => {
                        e.preventDefault();
                      }
                    }
                  }}
                />
              </Box>
            </Box>
            <br />
            <StyledTextField
              select
              aria-label="Select Status"
              label="Select Status"
              placeholder="Select Status"
              slotProps={{
                select: {
                  variant: "outlined",
                  value: selectedStatus,
                  renderValue: (selected) => {
                    return formatName(selected);
                  },
                  defaultValue: "",
                  onChange: (event) => {
                    setSelectedStatus(event.target.value);
                  },
                  MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
                }
              }}
            >
              {Object.keys(StaffLicensingStatusEnum)?.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {formatName(item)}
                  </MenuItem>
                );
              })}
            </StyledTextField>
            <br />
            <Box
              display="flex"
              alignItems="start"
              justifyContent="space-between"
            >
              {providers?.length >= 0 && (
                <Autocomplete
                  sx={{ width: "48%" }}
                  options={providers}
                  value={selectedProvider}
                  getOptionLabel={(provider) => {
                    return provider?.label;
                  }}
                  onChange={(e, value) => {
                    setSelectedProvider(value);
                    setSelectedProviderId(value?.value);
                  }}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      aria-label="Select Provider"
                      label="Select Provider"
                      variant="outlined"
                      error={
                        !checkIdValid(selectedProviderId) &&
                        isTruthy(selectedProviderId)
                      }
                      helperText={
                        !checkIdValid(selectedProviderId) &&
                        isTruthy(selectedProviderId) && (
                          <>Please select a provider with a valid ID</>
                        )
                      }
                    />
                  )}
                />
              )}
              <StyledTextField
                style={{ width: "48%" }}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  inputProps: {
                    maxlength: 10
                  }
                }}
                label="NPI"
                placeholder="Enter NPI"
                value={selectedNPI}
                onChange={(event) => {
                  if (
                    NUMBER_REGEXP.test(event.target.value) ||
                    event.target.value === ""
                  ) {
                    setSelectedNPI(event.target.value);
                  }
                }}
                error={!isValidNPI(selectedNPI) && isTruthy(selectedNPI)}
                helperText={
                  !isValidNPI(selectedNPI) && isTruthy(selectedNPI) ? (
                    <>Enter a valid NPI</>
                  ) : (
                    ""
                  )
                  // an example valid NPI is 1993999998
                }
              />
            </Box>
          </>
        )}
        {providersError && <ErrorComponent error={providersError} />}
        {insurancesError && <ErrorComponent error={insurancesError} />}
        {createStaffEnrollmentError && (
          <ErrorComponent
            style={{ marginTop: "12px" }}
            error={createStaffEnrollmentError}
          />
        )}
      </ModalBody>

      <ModalFooter>
        <ModalFooterButtons>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            disabled={submitButtonDisabled}
            type="submit"
            onClick={() => {
              onSubmit();
            }}
          >
            Save
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

const CreateProviderEnrollmentModal = ({
  isVisible,
  onRequestClose
}: IProps) => {
  const {
    data: providers,
    isFetching: providersIsFetching,
    error: providersError
  } = useGetUserWithQueryParamsQuery({
    roles: [RolesEnum.NURSE_PROVIDER, RolesEnum.MD_PROVIDER]
  });

  const {
    data: insurances,
    isFetching: insurancesIsFetching,
    error: insurancesError
  } = useGetInsuranceLicensingsQuery({});

  const providersList = useMemo(() => {
    if (providers?.length) {
      return providers.map((provider) => ({
        label: getNameOrUsername(provider?.user),
        value: provider?.user?.user_id
      }));
    }
  }, [providers]);

  const insurancesList = useMemo(() => {
    if (insurances?.length) {
      return insurances.map((insurance) => ({
        label: insurance?.insurance_name,
        value: insurance?.id
      }));
    }
  }, [insurances]);

  const isFetching = useMemo(
    () => providersIsFetching || insurancesIsFetching,
    [providersIsFetching, insurancesIsFetching]
  );

  if (!isVisible) {
    return null;
  }

  return (
    <CreateProviderEnrollmentModalRender
      isVisible={isVisible}
      onRequestClose={onRequestClose}
      providers={providersList}
      insurancesList={insurancesList}
      isFetching={isFetching}
      providersError={providersError}
      insurancesError={insurancesError}
    />
  );
};

export default CreateProviderEnrollmentModal;
