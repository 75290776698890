import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  RECENT_ORDERS,
  UNASSIGNED_MEMBERS,
  REPORT_LIST,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  MEMBER_CHART,
  COMPLETED_TODOS,
  MEMBER_CHART_CALENDARING,
  ALL_TEAMS,
  TEAM_DETAILS,
  STAFF_DETAILS,
  NURSE_SCHEDULE,
  MY_TODOS,
  NURSES_SCHEDULES,
  NURSE_SCHEDULES,
  VISITS,
  START_INTAKE,
  COMMON_ROUTES
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_MY_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SUPPORT,
  SIDEBAR_UNASSIGNED_MEMBERS,
  SIDEBAR_SECTIONS,
  SIDEBAR_CLINICAL_TEAMS
} from "../RouteSidebar";
import { CalendarIcon, MyNursesIcon } from "../../assets/images/icons";

import RolesEnum from "common/enums/RolesEnum";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import StaffDetailsRedirect from "../../pages/AllUserList/StaffDetails/StaffDetailsRedirect";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";
import MyTeam from "../../pages/Teams/MyTeam";
import Routes from "../Routes";

const THN_MANAGER = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: Routes.ROOT,
      components: NURSE_LIST({})
    },
    {
      path: Routes.NURSE_REDIRECT,
      components: <StaffDetailsRedirect />
    },
    STAFF_DETAILS,
    {
      path: Routes.ALL_MEMBERS,
      components: ALL_MEMBERS({
        tableColumns: [
          { name: "name" },
          { name: "status" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" },
          { name: "patientActions", size: 75 }
        ]
      })
    },
    START_INTAKE,
    {
      path: Routes.AWAITING_PROVIDER,
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" },
          { name: "patientActions", size: 75 }
        ]
      })
    },
    {
      path: Routes.UNASSIGNED_MEMBERS,
      components: UNASSIGNED_MEMBERS
    },
    ...MEMBER_CHART(RolesEnum.THN_MANAGER),
    ...MEMBER_CHART_CALENDARING,
    {
      path: Routes.ASSIGNED_MEMBERS(":nurseId"),
      components: [
        MEMBERS_WITH_NO_ACTIVITY({}),
        ASSIGNED_MEMBERS({}),
        RECENT_ORDERS()
      ]
    },
    {
      path: Routes.ORDERS,
      components: <OrderDevices />
    },
    {
      path: Routes.DEVICES_BY_MEMBER,
      components: <OrderDevices />
    },
    ORDER_DETAILS(),
    {
      path: Routes.MY_TEAM,
      components: (
        <MyTeam
          externalLink={Routes.STAFF_DETAILS(":userId", "schedule")}
          tableColumns={[
            { name: "teamMemberName" },
            { name: "patientsAssigned" },
            // TBD - fix this MTD time entered field on the backend before commenting this back in
            // { name: "myTeamTimeEntered", size: 200 },
            { name: "patientCount", size: 150 },
            { name: "deleteTeamMember" }
          ]}
        />
      )
    },

    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    COMPLETED_TODOS,
    MY_TODOS,
    ...NURSES_SCHEDULES,
    NURSE_SCHEDULES,
    NURSE_SCHEDULE,
    ALL_TEAMS({
      types: [
        TeamTypeEnum.NP_NURSES,
        TeamTypeEnum.PROVIDERS,
        TeamTypeEnum.TH_NURSES,
        TeamTypeEnum.MEMBER_CARE_SPECIALISTS
      ]
    }),
    TEAM_DETAILS,
    VISITS
  ],
  sidebar: [
    SIDEBAR_DASHBOARD(RolesEnum.THN_MANAGER),
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "My Nurses",
          itemLink: Routes.MY_TEAM,
          ItemIcon: MyNursesIcon
        },
        {
          itemName: "Nurses Schedules",
          itemLink: Routes.NURSE_SCHEDULE_DETAILS("schedules"),

          ItemIcon: CalendarIcon
        },
        SIDEBAR_CLINICAL_TEAMS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [
        SIDEBAR_MY_MEMBERS,
        SIDEBAR_ALL_MEMBERS,
        SIDEBAR_UNASSIGNED_MEMBERS,

        SIDEBAR_ORDERS
      ]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default THN_MANAGER;
