import RolesEnum, { canSeeProviderEnrollment } from "common/enums/RolesEnum";
import {
  CancellationsHistoryIcon,
  ClinicalTeamsIcon,
  DashboardIcon,
  HelpIcon,
  InactiveMembersIcon,
  IntakeNursesIcon,
  MigrateMemberIcon,
  MyConversionsIcon,
  MyRegistrationsIcon,
  OrdersIcon,
  PersonIcon,
  PlusIcon,
  ProviderSchedulesIcon,
  RegisterMemberIcon,
  ReportsIcon,
  RequestConsentsIcon,
  RetentionIcon,
  UnassignedMembersIcon,
  UsersIcon
} from "../assets/images/icons";
import Routes from "./Routes";

export const SIDEBAR_SECTIONS = {
  users: "USERS",
  nurses: "NURSES",
  providers: "PROVIDERS",
  members: "MEMBERS",
  retention: "RETENTION",
  inactives: "INACTIVES",
  reports: "REPORTS",
  support: "SUPPORT",
  myDashboard: "DASHBOARD",
  migrate: "MIGRATE"
};

export const SIDEBAR_DASHBOARD = (currentRole: RolesEnum) => {
  if (canSeeProviderEnrollment(currentRole)) {
    return {
      sectionName: SIDEBAR_SECTIONS.myDashboard,
      items: [
        {
          itemName: "Dashboard",
          itemLink: Routes.ROOT,
          ItemIcon: DashboardIcon
        },
        {
          itemName: "Provider Enrollment",
          itemLink: Routes.PROVIDER_ENROLLMENT,
          ItemIcon: PersonIcon
        }
      ]
    };
  }
  return {
    sectionName: SIDEBAR_SECTIONS.myDashboard,
    items: [
      {
        itemName: "Dashboard",
        itemLink: Routes.ROOT,
        ItemIcon: DashboardIcon
      }
    ]
  };
};

export const SIDEBAR_CLINICAL_TEAMS = {
  itemName: "Clinical Teams",
  itemLink: Routes.ALL_TEAMS,
  ItemIcon: ClinicalTeamsIcon
};

export const SIDEBAR_MY_MEMBERS = {
  itemName: "My Members",
  itemLink: Routes.ASSIGNED_MEMBERS("{{userId}}"),
  ItemIcon: PersonIcon
};

export const SIDEBAR_UNASSIGNED_MEMBERS = {
  itemName: "Unassigned",
  itemLink: Routes.UNASSIGNED_MEMBERS,
  ItemIcon: UnassignedMembersIcon
};

export const SIDEBAR_ALL_MEMBERS = {
  itemName: "All Members",
  itemLink: Routes.ALL_MEMBERS,
  ItemIcon: UsersIcon
};

export const SIDEBAR_AWAITING_PROVIDER = {
  itemName: "Awaiting Provider",
  itemLink: Routes.AWAITING_PROVIDER,
  ItemIcon: PersonIcon
};

export const SIDEBAR_INACTIVE_MEMBERS = {
  itemName: "Inactive",
  itemLink: Routes.INACTIVE_MEMBERS,
  ItemIcon: InactiveMembersIcon
};

export const SIDEBAR_ORDERS = {
  itemName: "Orders",
  itemLink: Routes.ORDERS,
  ItemIcon: OrdersIcon
};

export const SIDEBAR_SUPPORT = {
  sectionName: SIDEBAR_SECTIONS.support,
  items: [
    {
      itemName: "Help",
      itemLink: Routes.HELP,
      ItemIcon: HelpIcon
    }
  ]
};

export const SIDEBAR_REPORTS = {
  sectionName: SIDEBAR_SECTIONS.reports,
  items: [
    {
      itemName: "Reports",
      itemLink: Routes.REPORT,
      ItemIcon: ReportsIcon
    }
  ]
};

export const SIDEBAR_NPNS = {
  itemName: "Intake Nurses",
  itemLink: Routes.NMNS,
  ItemIcon: IntakeNursesIcon
};

export const SIDEBAR_MEMBER_MANAGEMENT = (isNPS: boolean) => {
  const items = [
    {
      itemName: "Register Member",
      itemLink: Routes.REGISTER_MEMBER,
      ItemIcon: RegisterMemberIcon
    },
    {
      itemName: "Request Consents",
      itemLink: Routes.REQUEST_CONSENTS,
      ItemIcon: RequestConsentsIcon
    }
  ];

  if (isNPS) {
    items.push({
      itemName: "My Conversions",
      itemLink: Routes.MY_CONVERSIONS,
      ItemIcon: MyConversionsIcon
    });
    items.push({
      itemName: "My Registrations",
      itemLink: Routes.MY_REGISTRATIONS,
      ItemIcon: MyRegistrationsIcon
    });
  }
  return {
    sectionName: SIDEBAR_SECTIONS.members,
    items
  };
};

export const SIDEBAR_INACTIVE_CANCELED_MEMBERS = {
  itemName: "Cancellations History",
  itemLink: Routes.CANCELLED_MEMBERS,
  ItemIcon: CancellationsHistoryIcon
};

export const SIDEBAR_RETENTION = {
  itemName: "Retention",
  itemLink: "/retention/notset",
  ItemIcon: RetentionIcon
};

export const SIDEBAR_MIGRATE_MEMBER = {
  sectionName: SIDEBAR_SECTIONS.migrate,
  items: [
    {
      itemName: "Migrate Member",
      itemLink: Routes.MIGRATE_MEMBER,
      ItemIcon: MigrateMemberIcon
    }
  ]
};

export const SIDEBAR_CREATE_TEAM = {
  itemName: "Create Team",
  itemLink: Routes.CREATE_TEAM,
  ItemIcon: PlusIcon
};

export const PROVIDER_SCHEDULES_SIDEBAR = {
  itemName: "Provider Schedules",
  itemLink: Routes.PROVIDER_SCHEDULES,
  ItemIcon: ProviderSchedulesIcon
};
