import { Platform } from "react-native";
import { v4 as uuidv4 } from "uuid";
import reactNativeUUID from "react-native-uuid";

import StorageEnum from "../../enums/StorageEnum";
import StorageHelper from "../StorageHelper";

const generateUUID = () => {
  if (Platform.OS === "web") return uuidv4();
  else return reactNativeUUID.v4();
};

export const AnalyticsHelper_getSessionID = () => {
  return new Promise<string>((resolve) => {
    StorageHelper.getItem(StorageEnum.SESSION_ID).then((value) => {
      if (value) resolve(value);
      else {
        const uuid = generateUUID().toString();
        StorageHelper.setItem(StorageEnum.SESSION_ID, uuid);
        resolve(uuid);
      }
    });
  });
};

// https://copilotiq.atlassian.net/browse/ENG-7904
export const convertToSegmentEventName = (screenName: string) => {
  switch (screenName) {
    case "Dashboard":
      return "home_viewed";
    case "MyReadings":
      return "readings_viewed";
    case "MyAccount":
      return "account_viewed";
    case "MyAppointments":
      return "my_appointments_viewed";
    case "YourCareTeam":
      return "care_team_viewed";

    default:
      return null;
  }
};
